import { TaskCard } from "@/components/environments/TaskCard";
import { ErrorMessage } from "@/components/ErrorMessage";
import { Button } from "@/components/flexkit/Button";
import { Heading2 } from "@/components/podkit/typography/Headings";
import {
    useListTaskExecutions,
    useListTasks,
    type PlainTask,
    type PlainTaskExecution,
} from "@/queries/automations-queries";
import { useEnvironment } from "@/queries/environment-queries";
import { canOpen } from "@/routes/environments/phase";
import { useCallback, useMemo, type FC } from "react";
import { useNavigate } from "react-router-dom";

export const EnvironmentTasks: FC<{ environmentId: string }> = ({ environmentId }) => {
    const navigate = useNavigate();

    const { data: environment } = useEnvironment(environmentId);
    const { data: tasks, error: listTasksError } = useListTasks(environmentId);
    const { data: taskRuns, error: listTaskExecutionsError } = useListTaskExecutions(environmentId);

    const disabled = !environment || !canOpen(environment);
    const hasTasks = (tasks?.length ?? 0) > 0;

    const tasksWithTriggers = useMemo(() => {
        return (tasks || []).filter((t) => t.metadata?.triggeredBy.some((t) => t.trigger));
    }, [tasks]);

    const onClick = useCallback(() => {
        navigate(`/details/${environmentId}/tasks`);
    }, [environmentId, navigate]);

    /** Empty state is handled in {@link EnvironmentAutomations} */
    if (!hasTasks && !listTasksError && !listTaskExecutionsError) {
        return null;
    }

    return (
        <div className="duration-700 animate-in fade-in">
            <div className="flex items-center justify-between pb-4" translate="no">
                <Heading2 className="select-none text-xl font-bold text-content-primary">Tasks</Heading2>
                <Button
                    disabled={disabled || !tasks?.length}
                    className={tasks?.length ? "" : "opacity-0"}
                    onClick={onClick}
                    variant="secondary"
                    data-track-label="true"
                >
                    View Task Runs
                </Button>
            </div>
            <ErrorMessage error={listTasksError} />
            <ErrorMessage error={listTaskExecutionsError} />
            <TaskCards disabled={disabled} tasks={tasksWithTriggers} runs={taskRuns || []} />
        </div>
    );
};

const TaskCards: FC<{ tasks: PlainTask[]; runs: PlainTaskExecution[]; disabled?: boolean }> = ({
    tasks,
    runs,
    disabled,
}) => {
    return (
        <div className="mb-4 grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
            {tasks.map((task) => (
                <TaskCard
                    key={task.id}
                    task={task}
                    runs={runs.filter((r) => r.metadata?.taskId === task.id)}
                    disabled={disabled}
                />
            ))}
        </div>
    );
};
