import { cn } from "@/components/podkit/lib/cn";
import type { FC, HTMLProps } from "react";

export const ExternalLink: FC<HTMLProps<HTMLAnchorElement>> = ({ className, children, ...props }) => {
    return (
        <a className={cn("text-base text-content-link", className)} target="_blank" rel="noreferrer" {...props}>
            {children}
        </a>
    );
};
