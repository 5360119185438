// @generated by protoc-gen-es v1.8.0 with parameter "target=ts"
// @generated from file gitpod/v1/environment.proto (package gitpod.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Duration, Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { PaginationRequest, PaginationResponse } from "./pagination_pb.js";
import { Subject } from "./identity_pb.js";

/**
 * Admission level describes who can access an environment instance and its ports.
 *
 * @generated from enum gitpod.v1.AdmissionLevel
 */
export enum AdmissionLevel {
  /**
   * @generated from enum value: ADMISSION_LEVEL_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * ADMISSION_LEVEL_OWNER_ONLY means the environment can only be accessed using
   * the owner token
   *
   * @generated from enum value: ADMISSION_LEVEL_OWNER_ONLY = 1;
   */
  OWNER_ONLY = 1,

  /**
   * ADMISSION_LEVEL_EVERYONE means the environment (including ports) can be
   * accessed by everyone.
   *
   * @generated from enum value: ADMISSION_LEVEL_EVERYONE = 2;
   */
  EVERYONE = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(AdmissionLevel)
proto3.util.setEnumType(AdmissionLevel, "gitpod.v1.AdmissionLevel", [
  { no: 0, name: "ADMISSION_LEVEL_UNSPECIFIED" },
  { no: 1, name: "ADMISSION_LEVEL_OWNER_ONLY" },
  { no: 2, name: "ADMISSION_LEVEL_EVERYONE" },
]);

/**
 * @generated from enum gitpod.v1.EnvironmentPhase
 */
export enum EnvironmentPhase {
  /**
   * Unknown indicates an issue within the environment manager in that it cannot
   * determine the actual phase of an environment. This phase is usually
   * accompanied by an error.
   *
   * @generated from enum value: ENVIRONMENT_PHASE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * Creating means that the environment is first created. We organise the SCM credentials,
   * parse context URL if need be, and allocate the unit of compute.
   *
   * @generated from enum value: ENVIRONMENT_PHASE_CREATING = 10;
   */
  CREATING = 10,

  /**
   * Starting means that the environment is currently being started. This includes
   * starting the unit of compute (machine), resolving secrets, setting up the Git config,
   * initiaing the content, and starting the devcontainer.
   *
   * @generated from enum value: ENVIRONMENT_PHASE_STARTING = 20;
   */
  STARTING = 20,

  /**
   * Running means the environment is able to actively perform work, either by
   * serving a user through Theia, or as a headless environment.
   *
   * @generated from enum value: ENVIRONMENT_PHASE_RUNNING = 40;
   */
  RUNNING = 40,

  /**
   * Updating means the environment is currently being updated. This includes
   * content updates, devcontainer updates, secret updates and SSH public key updates.
   * This phase implies that the environment is running.
   *
   * @generated from enum value: ENVIRONMENT_PHASE_UPDATING = 45;
   */
  UPDATING = 45,

  /**
   * Stopping means that the environment is currently shutting down. It could go
   * to stopped every moment.
   *
   * @generated from enum value: ENVIRONMENT_PHASE_STOPPING = 50;
   */
  STOPPING = 50,

  /**
   * Stopped means the environment ended regularly because it was shut down.
   *
   * @generated from enum value: ENVIRONMENT_PHASE_STOPPED = 60;
   */
  STOPPED = 60,

  /**
   * Deleting means the environment is currently being deleted. It could go to
   * deleted any moment. This phase implies that the environment is stopped.
   *
   * @generated from enum value: ENVIRONMENT_PHASE_DELETING = 70;
   */
  DELETING = 70,

  /**
   * Deleted means the environment was deleted and cannot be started again.
   * This phase implies that the environment is stopped.
   *
   * @generated from enum value: ENVIRONMENT_PHASE_DELETED = 80;
   */
  DELETED = 80,
}
// Retrieve enum metadata with: proto3.getEnumType(EnvironmentPhase)
proto3.util.setEnumType(EnvironmentPhase, "gitpod.v1.EnvironmentPhase", [
  { no: 0, name: "ENVIRONMENT_PHASE_UNSPECIFIED" },
  { no: 10, name: "ENVIRONMENT_PHASE_CREATING" },
  { no: 20, name: "ENVIRONMENT_PHASE_STARTING" },
  { no: 40, name: "ENVIRONMENT_PHASE_RUNNING" },
  { no: 45, name: "ENVIRONMENT_PHASE_UPDATING" },
  { no: 50, name: "ENVIRONMENT_PHASE_STOPPING" },
  { no: 60, name: "ENVIRONMENT_PHASE_STOPPED" },
  { no: 70, name: "ENVIRONMENT_PHASE_DELETING" },
  { no: 80, name: "ENVIRONMENT_PHASE_DELETED" },
]);

/**
 * @generated from message gitpod.v1.GetEnvironmentRequest
 */
export class GetEnvironmentRequest extends Message<GetEnvironmentRequest> {
  /**
   * environment_id specifies the environment to get
   *
   * @generated from field: string environment_id = 1;
   */
  environmentId = "";

  constructor(data?: PartialMessage<GetEnvironmentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.GetEnvironmentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "environment_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetEnvironmentRequest {
    return new GetEnvironmentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetEnvironmentRequest {
    return new GetEnvironmentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetEnvironmentRequest {
    return new GetEnvironmentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetEnvironmentRequest | PlainMessage<GetEnvironmentRequest> | undefined, b: GetEnvironmentRequest | PlainMessage<GetEnvironmentRequest> | undefined): boolean {
    return proto3.util.equals(GetEnvironmentRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.GetEnvironmentResponse
 */
export class GetEnvironmentResponse extends Message<GetEnvironmentResponse> {
  /**
   * @generated from field: gitpod.v1.Environment environment = 1;
   */
  environment?: Environment;

  constructor(data?: PartialMessage<GetEnvironmentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.GetEnvironmentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "environment", kind: "message", T: Environment },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetEnvironmentResponse {
    return new GetEnvironmentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetEnvironmentResponse {
    return new GetEnvironmentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetEnvironmentResponse {
    return new GetEnvironmentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetEnvironmentResponse | PlainMessage<GetEnvironmentResponse> | undefined, b: GetEnvironmentResponse | PlainMessage<GetEnvironmentResponse> | undefined): boolean {
    return proto3.util.equals(GetEnvironmentResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ListEnvironmentsRequest
 */
export class ListEnvironmentsRequest extends Message<ListEnvironmentsRequest> {
  /**
   * pagination contains the pagination options for listing environments
   *
   * @generated from field: gitpod.v1.PaginationRequest pagination = 1;
   */
  pagination?: PaginationRequest;

  /**
   * organization_id is the ID of the organization that contains the environments
   *
   * @generated from field: string organization_id = 2;
   */
  organizationId = "";

  /**
   * @generated from field: gitpod.v1.ListEnvironmentsRequest.Filter filter = 3;
   */
  filter?: ListEnvironmentsRequest_Filter;

  constructor(data?: PartialMessage<ListEnvironmentsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListEnvironmentsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pagination", kind: "message", T: PaginationRequest },
    { no: 2, name: "organization_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "filter", kind: "message", T: ListEnvironmentsRequest_Filter },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListEnvironmentsRequest {
    return new ListEnvironmentsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListEnvironmentsRequest {
    return new ListEnvironmentsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListEnvironmentsRequest {
    return new ListEnvironmentsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListEnvironmentsRequest | PlainMessage<ListEnvironmentsRequest> | undefined, b: ListEnvironmentsRequest | PlainMessage<ListEnvironmentsRequest> | undefined): boolean {
    return proto3.util.equals(ListEnvironmentsRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ListEnvironmentsRequest.Filter
 */
export class ListEnvironmentsRequest_Filter extends Message<ListEnvironmentsRequest_Filter> {
  /**
   * runner_ids filters the response to only Environments running on these Runner IDs
   *
   * @generated from field: repeated string runner_ids = 1;
   */
  runnerIds: string[] = [];

  /**
   * actual_phases is a list of phases the environment must be in for it to be returned in the API call
   *
   * @generated from field: repeated gitpod.v1.EnvironmentPhase status_phases = 2;
   */
  statusPhases: EnvironmentPhase[] = [];

  /**
   * creator_ids filters the response to only Environments created by specified members
   *
   * @generated from field: repeated string creator_ids = 3;
   */
  creatorIds: string[] = [];

  /**
   * project_ids filters the response to only Environments associated with the specified projects
   *
   * @generated from field: repeated string project_ids = 4;
   */
  projectIds: string[] = [];

  constructor(data?: PartialMessage<ListEnvironmentsRequest_Filter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListEnvironmentsRequest.Filter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "runner_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "status_phases", kind: "enum", T: proto3.getEnumType(EnvironmentPhase), repeated: true },
    { no: 3, name: "creator_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "project_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListEnvironmentsRequest_Filter {
    return new ListEnvironmentsRequest_Filter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListEnvironmentsRequest_Filter {
    return new ListEnvironmentsRequest_Filter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListEnvironmentsRequest_Filter {
    return new ListEnvironmentsRequest_Filter().fromJsonString(jsonString, options);
  }

  static equals(a: ListEnvironmentsRequest_Filter | PlainMessage<ListEnvironmentsRequest_Filter> | undefined, b: ListEnvironmentsRequest_Filter | PlainMessage<ListEnvironmentsRequest_Filter> | undefined): boolean {
    return proto3.util.equals(ListEnvironmentsRequest_Filter, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ListEnvironmentsResponse
 */
export class ListEnvironmentsResponse extends Message<ListEnvironmentsResponse> {
  /**
   * pagination contains the pagination options for listing environments
   *
   * @generated from field: gitpod.v1.PaginationResponse pagination = 1;
   */
  pagination?: PaginationResponse;

  /**
   * environments are the environments that matched the query
   *
   * @generated from field: repeated gitpod.v1.Environment environments = 2;
   */
  environments: Environment[] = [];

  constructor(data?: PartialMessage<ListEnvironmentsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListEnvironmentsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pagination", kind: "message", T: PaginationResponse },
    { no: 2, name: "environments", kind: "message", T: Environment, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListEnvironmentsResponse {
    return new ListEnvironmentsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListEnvironmentsResponse {
    return new ListEnvironmentsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListEnvironmentsResponse {
    return new ListEnvironmentsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListEnvironmentsResponse | PlainMessage<ListEnvironmentsResponse> | undefined, b: ListEnvironmentsResponse | PlainMessage<ListEnvironmentsResponse> | undefined): boolean {
    return proto3.util.equals(ListEnvironmentsResponse, a, b);
  }
}

/**
 * Required fields:
 * - metadata.organization_id
 * - metadata.configuration_id
 *
 * @generated from message gitpod.v1.CreateEnvironmentRequest
 */
export class CreateEnvironmentRequest extends Message<CreateEnvironmentRequest> {
  /**
   * spec is the configuration of the environment that's required for the to
   * start the environment
   *
   * @generated from field: gitpod.v1.EnvironmentSpec spec = 1;
   */
  spec?: EnvironmentSpec;

  constructor(data?: PartialMessage<CreateEnvironmentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.CreateEnvironmentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "spec", kind: "message", T: EnvironmentSpec },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateEnvironmentRequest {
    return new CreateEnvironmentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateEnvironmentRequest {
    return new CreateEnvironmentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateEnvironmentRequest {
    return new CreateEnvironmentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateEnvironmentRequest | PlainMessage<CreateEnvironmentRequest> | undefined, b: CreateEnvironmentRequest | PlainMessage<CreateEnvironmentRequest> | undefined): boolean {
    return proto3.util.equals(CreateEnvironmentRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.CreateEnvironmentResponse
 */
export class CreateEnvironmentResponse extends Message<CreateEnvironmentResponse> {
  /**
   * @generated from field: gitpod.v1.Environment environment = 1;
   */
  environment?: Environment;

  constructor(data?: PartialMessage<CreateEnvironmentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.CreateEnvironmentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "environment", kind: "message", T: Environment },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateEnvironmentResponse {
    return new CreateEnvironmentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateEnvironmentResponse {
    return new CreateEnvironmentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateEnvironmentResponse {
    return new CreateEnvironmentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateEnvironmentResponse | PlainMessage<CreateEnvironmentResponse> | undefined, b: CreateEnvironmentResponse | PlainMessage<CreateEnvironmentResponse> | undefined): boolean {
    return proto3.util.equals(CreateEnvironmentResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.CreateEnvironmentFromProjectRequest
 */
export class CreateEnvironmentFromProjectRequest extends Message<CreateEnvironmentFromProjectRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * Spec is the configuration of the environment that's required for the
   * runner to start the environment
   * Configuration already defined in the Project will override parts of the spec, if set
   *
   * @generated from field: gitpod.v1.EnvironmentSpec spec = 3;
   */
  spec?: EnvironmentSpec;

  constructor(data?: PartialMessage<CreateEnvironmentFromProjectRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.CreateEnvironmentFromProjectRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "spec", kind: "message", T: EnvironmentSpec },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateEnvironmentFromProjectRequest {
    return new CreateEnvironmentFromProjectRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateEnvironmentFromProjectRequest {
    return new CreateEnvironmentFromProjectRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateEnvironmentFromProjectRequest {
    return new CreateEnvironmentFromProjectRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateEnvironmentFromProjectRequest | PlainMessage<CreateEnvironmentFromProjectRequest> | undefined, b: CreateEnvironmentFromProjectRequest | PlainMessage<CreateEnvironmentFromProjectRequest> | undefined): boolean {
    return proto3.util.equals(CreateEnvironmentFromProjectRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.CreateEnvironmentFromProjectResponse
 */
export class CreateEnvironmentFromProjectResponse extends Message<CreateEnvironmentFromProjectResponse> {
  /**
   * @generated from field: gitpod.v1.Environment environment = 1;
   */
  environment?: Environment;

  constructor(data?: PartialMessage<CreateEnvironmentFromProjectResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.CreateEnvironmentFromProjectResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "environment", kind: "message", T: Environment },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateEnvironmentFromProjectResponse {
    return new CreateEnvironmentFromProjectResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateEnvironmentFromProjectResponse {
    return new CreateEnvironmentFromProjectResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateEnvironmentFromProjectResponse {
    return new CreateEnvironmentFromProjectResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateEnvironmentFromProjectResponse | PlainMessage<CreateEnvironmentFromProjectResponse> | undefined, b: CreateEnvironmentFromProjectResponse | PlainMessage<CreateEnvironmentFromProjectResponse> | undefined): boolean {
    return proto3.util.equals(CreateEnvironmentFromProjectResponse, a, b);
  }
}

/**
 * `CreateEnvironmentPreconditionFailureDetails` contains details about why a
 * `CreateEnvironment` or `CreateEnvironmentFromProject` request failed with failed_precondition.
 * The `missing_authentication_tokens_for_hosts` field lists the hosts for which the required authentication tokens were missing.
 *
 * @generated from message gitpod.v1.CreateEnvironmentPreconditionFailureDetails
 */
export class CreateEnvironmentPreconditionFailureDetails extends Message<CreateEnvironmentPreconditionFailureDetails> {
  /**
   * @generated from field: repeated string missing_authentication_tokens_for_hosts = 1;
   */
  missingAuthenticationTokensForHosts: string[] = [];

  constructor(data?: PartialMessage<CreateEnvironmentPreconditionFailureDetails>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.CreateEnvironmentPreconditionFailureDetails";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "missing_authentication_tokens_for_hosts", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateEnvironmentPreconditionFailureDetails {
    return new CreateEnvironmentPreconditionFailureDetails().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateEnvironmentPreconditionFailureDetails {
    return new CreateEnvironmentPreconditionFailureDetails().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateEnvironmentPreconditionFailureDetails {
    return new CreateEnvironmentPreconditionFailureDetails().fromJsonString(jsonString, options);
  }

  static equals(a: CreateEnvironmentPreconditionFailureDetails | PlainMessage<CreateEnvironmentPreconditionFailureDetails> | undefined, b: CreateEnvironmentPreconditionFailureDetails | PlainMessage<CreateEnvironmentPreconditionFailureDetails> | undefined): boolean {
    return proto3.util.equals(CreateEnvironmentPreconditionFailureDetails, a, b);
  }
}

/**
 * +resource get environment
 *
 * @generated from message gitpod.v1.Environment
 */
export class Environment extends Message<Environment> {
  /**
   * ID is a unique identifier of this environment. No other environment with the
   * same name must be managed by this environment manager
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * Metadata is data associated with this environment that's required for other
   * parts of Gitpod to function
   *
   * @generated from field: gitpod.v1.EnvironmentMetadata metadata = 2;
   */
  metadata?: EnvironmentMetadata;

  /**
   * Spec is the configuration of the environment that's required for the
   * runner to start the environment
   *
   * @generated from field: gitpod.v1.EnvironmentSpec spec = 3;
   */
  spec?: EnvironmentSpec;

  /**
   * Status is the current status of the environment
   *
   * @generated from field: gitpod.v1.EnvironmentStatus status = 4;
   */
  status?: EnvironmentStatus;

  constructor(data?: PartialMessage<Environment>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.Environment";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "metadata", kind: "message", T: EnvironmentMetadata },
    { no: 3, name: "spec", kind: "message", T: EnvironmentSpec },
    { no: 4, name: "status", kind: "message", T: EnvironmentStatus },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Environment {
    return new Environment().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Environment {
    return new Environment().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Environment {
    return new Environment().fromJsonString(jsonString, options);
  }

  static equals(a: Environment | PlainMessage<Environment> | undefined, b: Environment | PlainMessage<Environment> | undefined): boolean {
    return proto3.util.equals(Environment, a, b);
  }
}

/**
 * EnvironmentMetadata is data associated with an environment that's required for
 * other parts of the system to function
 *
 * @generated from message gitpod.v1.EnvironmentMetadata
 */
export class EnvironmentMetadata extends Message<EnvironmentMetadata> {
  /**
   * organization_id is the ID of the organization that contains the environment
   *
   * @generated from field: string organization_id = 1;
   */
  organizationId = "";

  /**
   * annotations are key/value pairs that gets attached to the environment.
   * +internal - not yet implemented
   *
   * @generated from field: map<string, string> annotations = 2;
   */
  annotations: { [key: string]: string } = {};

  /**
   * name is the name of the environment as specified by the user
   *
   * @generated from field: string name = 3;
   */
  name = "";

  /**
   * creator is the identity of the creator of the environment
   *
   * @generated from field: gitpod.v1.Subject creator = 4;
   */
  creator?: Subject;

  /**
   * original_context_url is the normalized URL from which the environment was
   * created
   *
   * @generated from field: string original_context_url = 5;
   */
  originalContextUrl = "";

  /**
   * Time when the Environment was created.
   *
   * @generated from field: google.protobuf.Timestamp created_at = 6;
   */
  createdAt?: Timestamp;

  /**
   * If the Environment was started from a project, the project_id will reference the project.
   *
   * @generated from field: string project_id = 7;
   */
  projectId = "";

  /**
   * Runner is the ID of the runner that runs this environment.
   *
   * @generated from field: string runner_id = 9;
   */
  runnerId = "";

  /**
   * Time when the Environment was last started (i.e. CreateEnvironment or StartEnvironment were called).
   *
   * @generated from field: google.protobuf.Timestamp last_started_at = 10;
   */
  lastStartedAt?: Timestamp;

  constructor(data?: PartialMessage<EnvironmentMetadata>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.EnvironmentMetadata";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "organization_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "annotations", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "creator", kind: "message", T: Subject },
    { no: 5, name: "original_context_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "created_at", kind: "message", T: Timestamp },
    { no: 7, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "runner_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "last_started_at", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EnvironmentMetadata {
    return new EnvironmentMetadata().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EnvironmentMetadata {
    return new EnvironmentMetadata().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EnvironmentMetadata {
    return new EnvironmentMetadata().fromJsonString(jsonString, options);
  }

  static equals(a: EnvironmentMetadata | PlainMessage<EnvironmentMetadata> | undefined, b: EnvironmentMetadata | PlainMessage<EnvironmentMetadata> | undefined): boolean {
    return proto3.util.equals(EnvironmentMetadata, a, b);
  }
}

/**
 * EnvironmentSpec specifies the configuration of an environment for an environment
 * start
 *
 * @generated from message gitpod.v1.EnvironmentSpec
 */
export class EnvironmentSpec extends Message<EnvironmentSpec> {
  /**
   * version of the spec. The value of this field has no semantic
   * meaning (e.g. don't interpret it as as a timestamp),
   * but it can be used to impose a partial order. If a.spec_version <
   * b.spec_version then a was the spec before b.
   *
   * @generated from field: uint64 spec_version = 1;
   */
  specVersion = protoInt64.zero;

  /**
   * Phase is the desired phase of the environment
   *
   * @generated from field: gitpod.v1.EnvironmentPhase desired_phase = 2;
   */
  desiredPhase = EnvironmentPhase.UNSPECIFIED;

  /**
   * machine is the machine spec of the environment
   *
   * @generated from field: gitpod.v1.EnvironmentSpec.Machine machine = 3;
   */
  machine?: EnvironmentSpec_Machine;

  /**
   * content is the content spec of the environment
   *
   * @generated from field: gitpod.v1.EnvironmentSpec.Content content = 4;
   */
  content?: EnvironmentSpec_Content;

  /**
   * secrets are confidential data that is mounted into the environment
   *
   * @generated from field: repeated gitpod.v1.EnvironmentSpec.Secret secrets = 5;
   */
  secrets: EnvironmentSpec_Secret[] = [];

  /**
   * ports is the set of ports which ought to be exposed to the internet
   *
   * @generated from field: repeated gitpod.v1.EnvironmentSpec.EnvironmentPort ports = 6;
   */
  ports: EnvironmentSpec_EnvironmentPort[] = [];

  /**
   * Timeout configures the environment timeout
   *
   * @generated from field: gitpod.v1.EnvironmentSpec.Timeout timeout = 7;
   */
  timeout?: EnvironmentSpec_Timeout;

  /**
   * admission controlls who can access the environment and its ports.
   *
   * @generated from field: gitpod.v1.AdmissionLevel admission = 8;
   */
  admission = AdmissionLevel.UNSPECIFIED;

  /**
   * devcontainer is the devcontainer spec of the environment
   *
   * @generated from field: gitpod.v1.EnvironmentSpec.DevContainer devcontainer = 9;
   */
  devcontainer?: EnvironmentSpec_DevContainer;

  /**
   * ssh_public_keys are the public keys used to ssh into the environment
   *
   * @generated from field: repeated gitpod.v1.EnvironmentSpec.SSHPublicKey ssh_public_keys = 10;
   */
  sshPublicKeys: EnvironmentSpec_SSHPublicKey[] = [];

  /**
   * automations_file is the automations file spec of the environment
   *
   * @generated from field: gitpod.v1.EnvironmentSpec.AutomationsFile automations_file = 11;
   */
  automationsFile?: EnvironmentSpec_AutomationsFile;

  constructor(data?: PartialMessage<EnvironmentSpec>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.EnvironmentSpec";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "spec_version", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "desired_phase", kind: "enum", T: proto3.getEnumType(EnvironmentPhase) },
    { no: 3, name: "machine", kind: "message", T: EnvironmentSpec_Machine },
    { no: 4, name: "content", kind: "message", T: EnvironmentSpec_Content },
    { no: 5, name: "secrets", kind: "message", T: EnvironmentSpec_Secret, repeated: true },
    { no: 6, name: "ports", kind: "message", T: EnvironmentSpec_EnvironmentPort, repeated: true },
    { no: 7, name: "timeout", kind: "message", T: EnvironmentSpec_Timeout },
    { no: 8, name: "admission", kind: "enum", T: proto3.getEnumType(AdmissionLevel) },
    { no: 9, name: "devcontainer", kind: "message", T: EnvironmentSpec_DevContainer },
    { no: 10, name: "ssh_public_keys", kind: "message", T: EnvironmentSpec_SSHPublicKey, repeated: true },
    { no: 11, name: "automations_file", kind: "message", T: EnvironmentSpec_AutomationsFile },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EnvironmentSpec {
    return new EnvironmentSpec().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EnvironmentSpec {
    return new EnvironmentSpec().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EnvironmentSpec {
    return new EnvironmentSpec().fromJsonString(jsonString, options);
  }

  static equals(a: EnvironmentSpec | PlainMessage<EnvironmentSpec> | undefined, b: EnvironmentSpec | PlainMessage<EnvironmentSpec> | undefined): boolean {
    return proto3.util.equals(EnvironmentSpec, a, b);
  }
}

/**
 * Timeout configures the environment timeout
 *
 * @generated from message gitpod.v1.EnvironmentSpec.Timeout
 */
export class EnvironmentSpec_Timeout extends Message<EnvironmentSpec_Timeout> {
  /**
   * inacitivity is the maximum time of disconnection before the environment is
   * stopped or paused. Minimum duration is 30 minutes. Set to 0 to disable.
   *
   * @generated from field: google.protobuf.Duration disconnected = 2;
   */
  disconnected?: Duration;

  constructor(data?: PartialMessage<EnvironmentSpec_Timeout>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.EnvironmentSpec.Timeout";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "disconnected", kind: "message", T: Duration },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EnvironmentSpec_Timeout {
    return new EnvironmentSpec_Timeout().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EnvironmentSpec_Timeout {
    return new EnvironmentSpec_Timeout().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EnvironmentSpec_Timeout {
    return new EnvironmentSpec_Timeout().fromJsonString(jsonString, options);
  }

  static equals(a: EnvironmentSpec_Timeout | PlainMessage<EnvironmentSpec_Timeout> | undefined, b: EnvironmentSpec_Timeout | PlainMessage<EnvironmentSpec_Timeout> | undefined): boolean {
    return proto3.util.equals(EnvironmentSpec_Timeout, a, b);
  }
}

/**
 * @generated from message gitpod.v1.EnvironmentSpec.Machine
 */
export class EnvironmentSpec_Machine extends Message<EnvironmentSpec_Machine> {
  /**
   * @generated from field: string session = 1;
   */
  session = "";

  /**
   * Class denotes the class of the environment we ought to start
   *
   * @generated from field: string class = 2;
   */
  class = "";

  constructor(data?: PartialMessage<EnvironmentSpec_Machine>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.EnvironmentSpec.Machine";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "session", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "class", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EnvironmentSpec_Machine {
    return new EnvironmentSpec_Machine().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EnvironmentSpec_Machine {
    return new EnvironmentSpec_Machine().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EnvironmentSpec_Machine {
    return new EnvironmentSpec_Machine().fromJsonString(jsonString, options);
  }

  static equals(a: EnvironmentSpec_Machine | PlainMessage<EnvironmentSpec_Machine> | undefined, b: EnvironmentSpec_Machine | PlainMessage<EnvironmentSpec_Machine> | undefined): boolean {
    return proto3.util.equals(EnvironmentSpec_Machine, a, b);
  }
}

/**
 * @generated from message gitpod.v1.EnvironmentSpec.Content
 */
export class EnvironmentSpec_Content extends Message<EnvironmentSpec_Content> {
  /**
   * @generated from field: string session = 1;
   */
  session = "";

  /**
   * initializer configures how the environment is to be initialized
   *
   * @generated from field: gitpod.v1.EnvironmentInitializer initializer = 2;
   */
  initializer?: EnvironmentInitializer;

  /**
   * The Git username
   *
   * @generated from field: string git_username = 3;
   */
  gitUsername = "";

  /**
   * The Git email address
   *
   * @generated from field: string git_email = 4;
   */
  gitEmail = "";

  constructor(data?: PartialMessage<EnvironmentSpec_Content>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.EnvironmentSpec.Content";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "session", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "initializer", kind: "message", T: EnvironmentInitializer },
    { no: 3, name: "git_username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "git_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EnvironmentSpec_Content {
    return new EnvironmentSpec_Content().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EnvironmentSpec_Content {
    return new EnvironmentSpec_Content().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EnvironmentSpec_Content {
    return new EnvironmentSpec_Content().fromJsonString(jsonString, options);
  }

  static equals(a: EnvironmentSpec_Content | PlainMessage<EnvironmentSpec_Content> | undefined, b: EnvironmentSpec_Content | PlainMessage<EnvironmentSpec_Content> | undefined): boolean {
    return proto3.util.equals(EnvironmentSpec_Content, a, b);
  }
}

/**
 * @generated from message gitpod.v1.EnvironmentSpec.DevContainer
 */
export class EnvironmentSpec_DevContainer extends Message<EnvironmentSpec_DevContainer> {
  /**
   * @generated from field: string session = 1;
   */
  session = "";

  /**
   * devcontainer_file_path is the path to the devcontainer file relative to the repo root
   *
   * @generated from field: string devcontainer_file_path = 2;
   */
  devcontainerFilePath = "";

  constructor(data?: PartialMessage<EnvironmentSpec_DevContainer>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.EnvironmentSpec.DevContainer";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "session", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "devcontainer_file_path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EnvironmentSpec_DevContainer {
    return new EnvironmentSpec_DevContainer().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EnvironmentSpec_DevContainer {
    return new EnvironmentSpec_DevContainer().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EnvironmentSpec_DevContainer {
    return new EnvironmentSpec_DevContainer().fromJsonString(jsonString, options);
  }

  static equals(a: EnvironmentSpec_DevContainer | PlainMessage<EnvironmentSpec_DevContainer> | undefined, b: EnvironmentSpec_DevContainer | PlainMessage<EnvironmentSpec_DevContainer> | undefined): boolean {
    return proto3.util.equals(EnvironmentSpec_DevContainer, a, b);
  }
}

/**
 * @generated from message gitpod.v1.EnvironmentSpec.Secret
 */
export class EnvironmentSpec_Secret extends Message<EnvironmentSpec_Secret> {
  /**
   * name is the human readable description of the secret
   *
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * source is the source of the secret, for now control-plane or runner
   *
   * @generated from field: string source = 2;
   */
  source = "";

  /**
   * source_ref into the source, in case of control-plane this is uuid of the secret
   *
   * @generated from field: string source_ref = 3;
   */
  sourceRef = "";

  /**
   * mount specifies how the secret is materiaized in the environment
   *
   * @generated from oneof gitpod.v1.EnvironmentSpec.Secret.mount
   */
  mount: {
    /**
     * file_path is the path inside the devcontainer where the secret is mounted
     *
     * @generated from field: string file_path = 10;
     */
    value: string;
    case: "filePath";
  } | {
    /**
     * @generated from field: string environment_variable = 11;
     */
    value: string;
    case: "environmentVariable";
  } | {
    /**
     * @generated from field: string git_credential_host = 12;
     */
    value: string;
    case: "gitCredentialHost";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<EnvironmentSpec_Secret>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.EnvironmentSpec.Secret";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "source", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "source_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "file_path", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "mount" },
    { no: 11, name: "environment_variable", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "mount" },
    { no: 12, name: "git_credential_host", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "mount" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EnvironmentSpec_Secret {
    return new EnvironmentSpec_Secret().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EnvironmentSpec_Secret {
    return new EnvironmentSpec_Secret().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EnvironmentSpec_Secret {
    return new EnvironmentSpec_Secret().fromJsonString(jsonString, options);
  }

  static equals(a: EnvironmentSpec_Secret | PlainMessage<EnvironmentSpec_Secret> | undefined, b: EnvironmentSpec_Secret | PlainMessage<EnvironmentSpec_Secret> | undefined): boolean {
    return proto3.util.equals(EnvironmentSpec_Secret, a, b);
  }
}

/**
 * @generated from message gitpod.v1.EnvironmentSpec.EnvironmentPort
 */
export class EnvironmentSpec_EnvironmentPort extends Message<EnvironmentSpec_EnvironmentPort> {
  /**
   * port number
   *
   * @generated from field: int32 port = 1;
   */
  port = 0;

  /**
   * policy of this port
   *
   * @generated from field: gitpod.v1.AdmissionLevel admission = 2;
   */
  admission = AdmissionLevel.UNSPECIFIED;

  /**
   * name of this port
   *
   * @generated from field: string name = 3;
   */
  name = "";

  constructor(data?: PartialMessage<EnvironmentSpec_EnvironmentPort>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.EnvironmentSpec.EnvironmentPort";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "port", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "admission", kind: "enum", T: proto3.getEnumType(AdmissionLevel) },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EnvironmentSpec_EnvironmentPort {
    return new EnvironmentSpec_EnvironmentPort().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EnvironmentSpec_EnvironmentPort {
    return new EnvironmentSpec_EnvironmentPort().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EnvironmentSpec_EnvironmentPort {
    return new EnvironmentSpec_EnvironmentPort().fromJsonString(jsonString, options);
  }

  static equals(a: EnvironmentSpec_EnvironmentPort | PlainMessage<EnvironmentSpec_EnvironmentPort> | undefined, b: EnvironmentSpec_EnvironmentPort | PlainMessage<EnvironmentSpec_EnvironmentPort> | undefined): boolean {
    return proto3.util.equals(EnvironmentSpec_EnvironmentPort, a, b);
  }
}

/**
 * @generated from message gitpod.v1.EnvironmentSpec.SSHPublicKey
 */
export class EnvironmentSpec_SSHPublicKey extends Message<EnvironmentSpec_SSHPublicKey> {
  /**
   * id is the unique identifier of the public key
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * value is the actual public key in the public key file format
   *
   * @generated from field: string value = 2;
   */
  value = "";

  constructor(data?: PartialMessage<EnvironmentSpec_SSHPublicKey>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.EnvironmentSpec.SSHPublicKey";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EnvironmentSpec_SSHPublicKey {
    return new EnvironmentSpec_SSHPublicKey().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EnvironmentSpec_SSHPublicKey {
    return new EnvironmentSpec_SSHPublicKey().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EnvironmentSpec_SSHPublicKey {
    return new EnvironmentSpec_SSHPublicKey().fromJsonString(jsonString, options);
  }

  static equals(a: EnvironmentSpec_SSHPublicKey | PlainMessage<EnvironmentSpec_SSHPublicKey> | undefined, b: EnvironmentSpec_SSHPublicKey | PlainMessage<EnvironmentSpec_SSHPublicKey> | undefined): boolean {
    return proto3.util.equals(EnvironmentSpec_SSHPublicKey, a, b);
  }
}

/**
 * @generated from message gitpod.v1.EnvironmentSpec.AutomationsFile
 */
export class EnvironmentSpec_AutomationsFile extends Message<EnvironmentSpec_AutomationsFile> {
  /**
   * @generated from field: string session = 1;
   */
  session = "";

  /**
   * automations_file_path is the path to the automations file that is applied in the environment,
   * relative to the repo root.
   *
   * @generated from field: string automations_file_path = 2;
   */
  automationsFilePath = "";

  constructor(data?: PartialMessage<EnvironmentSpec_AutomationsFile>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.EnvironmentSpec.AutomationsFile";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "session", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "automations_file_path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EnvironmentSpec_AutomationsFile {
    return new EnvironmentSpec_AutomationsFile().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EnvironmentSpec_AutomationsFile {
    return new EnvironmentSpec_AutomationsFile().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EnvironmentSpec_AutomationsFile {
    return new EnvironmentSpec_AutomationsFile().fromJsonString(jsonString, options);
  }

  static equals(a: EnvironmentSpec_AutomationsFile | PlainMessage<EnvironmentSpec_AutomationsFile> | undefined, b: EnvironmentSpec_AutomationsFile | PlainMessage<EnvironmentSpec_AutomationsFile> | undefined): boolean {
    return proto3.util.equals(EnvironmentSpec_AutomationsFile, a, b);
  }
}

/**
 * EnvironmentStatus describes an environment status
 *
 * @generated from message gitpod.v1.EnvironmentStatus
 */
export class EnvironmentStatus extends Message<EnvironmentStatus> {
  /**
   * version of the status update. Environment instances themselves are
   * unversioned, but their status has different versions. The value of this
   * field has no semantic meaning (e.g. don't interpret it as as a timestamp),
   * but it can be used to impose a partial order. If a.status_version <
   * b.status_version then a was the status before b.
   *
   * @generated from field: uint64 status_version = 1;
   */
  statusVersion = protoInt64.zero;

  /**
   * runner_ack contains the acknowledgement from the runner that is has
   * received the environment spec.
   *
   * @generated from field: gitpod.v1.EnvironmentStatus.RunnerACK runner_ack = 2;
   */
  runnerAck?: EnvironmentStatus_RunnerACK;

  /**
   * the phase of an environment is a simple, high-level summary of where the
   * environment is in its lifecycle
   *
   * @generated from field: gitpod.v1.EnvironmentPhase phase = 3;
   */
  phase = EnvironmentPhase.UNSPECIFIED;

  /**
   * failure_message summarises why the environment failed to operate. If this is non-empty
   * the environment has failed to operate and will likely transition to a stopped state.
   *
   * @generated from field: repeated string failure_message = 4;
   */
  failureMessage: string[] = [];

  /**
   * environment_url contains the URL at which the environment can be accessed.
   * This field is only set if the environment is running.
   *
   * @generated from field: gitpod.v1.EnvironmentStatus.EnvironmentURLs environment_urls = 5;
   */
  environmentUrls?: EnvironmentStatus_EnvironmentURLs;

  /**
   * machine contains the status of the environment machine
   *
   * @generated from field: gitpod.v1.EnvironmentStatus.Machine machine = 6;
   */
  machine?: EnvironmentStatus_Machine;

  /**
   * secrets contains the status of the environment secrets
   *
   * @generated from field: repeated gitpod.v1.EnvironmentStatus.Secret secrets = 7;
   */
  secrets: EnvironmentStatus_Secret[] = [];

  /**
   * content contains the status of the environment content.
   *
   * @generated from field: gitpod.v1.EnvironmentStatus.Content content = 8;
   */
  content?: EnvironmentStatus_Content;

  /**
   * devcontainer contains the status of the devcontainer.
   *
   * @generated from field: gitpod.v1.EnvironmentStatus.DevContainer devcontainer = 9;
   */
  devcontainer?: EnvironmentStatus_DevContainer;

  /**
   * ssh_public_keys contains the status of the environment ssh public keys
   *
   * @generated from field: repeated gitpod.v1.EnvironmentStatus.SSHPublicKey ssh_public_keys = 10;
   */
  sshPublicKeys: EnvironmentStatus_SSHPublicKey[] = [];

  /**
   * warning_message contains warnings, e.g. when the environment is present but not in the expected state.
   *
   * @generated from field: repeated string warning_message = 11;
   */
  warningMessage: string[] = [];

  /**
   * automations_file contains the status of the automations file.
   *
   * @generated from field: gitpod.v1.EnvironmentStatus.AutomationsFile automations_file = 12;
   */
  automationsFile?: EnvironmentStatus_AutomationsFile;

  constructor(data?: PartialMessage<EnvironmentStatus>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.EnvironmentStatus";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status_version", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "runner_ack", kind: "message", T: EnvironmentStatus_RunnerACK },
    { no: 3, name: "phase", kind: "enum", T: proto3.getEnumType(EnvironmentPhase) },
    { no: 4, name: "failure_message", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "environment_urls", kind: "message", T: EnvironmentStatus_EnvironmentURLs },
    { no: 6, name: "machine", kind: "message", T: EnvironmentStatus_Machine },
    { no: 7, name: "secrets", kind: "message", T: EnvironmentStatus_Secret, repeated: true },
    { no: 8, name: "content", kind: "message", T: EnvironmentStatus_Content },
    { no: 9, name: "devcontainer", kind: "message", T: EnvironmentStatus_DevContainer },
    { no: 10, name: "ssh_public_keys", kind: "message", T: EnvironmentStatus_SSHPublicKey, repeated: true },
    { no: 11, name: "warning_message", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 12, name: "automations_file", kind: "message", T: EnvironmentStatus_AutomationsFile },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EnvironmentStatus {
    return new EnvironmentStatus().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EnvironmentStatus {
    return new EnvironmentStatus().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EnvironmentStatus {
    return new EnvironmentStatus().fromJsonString(jsonString, options);
  }

  static equals(a: EnvironmentStatus | PlainMessage<EnvironmentStatus> | undefined, b: EnvironmentStatus | PlainMessage<EnvironmentStatus> | undefined): boolean {
    return proto3.util.equals(EnvironmentStatus, a, b);
  }
}

/**
 * @generated from enum gitpod.v1.EnvironmentStatus.ContentPhase
 */
export enum EnvironmentStatus_ContentPhase {
  /**
   * @generated from enum value: CONTENT_PHASE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: CONTENT_PHASE_CREATING = 10;
   */
  CREATING = 10,

  /**
   * @generated from enum value: CONTENT_PHASE_INITIALIZING = 20;
   */
  INITIALIZING = 20,

  /**
   * @generated from enum value: CONTENT_PHASE_READY = 30;
   */
  READY = 30,

  /**
   * @generated from enum value: CONTENT_PHASE_UPDATING = 35;
   */
  UPDATING = 35,

  /**
   * @generated from enum value: CONTENT_PHASE_FAILED = 40;
   */
  FAILED = 40,
}
// Retrieve enum metadata with: proto3.getEnumType(EnvironmentStatus_ContentPhase)
proto3.util.setEnumType(EnvironmentStatus_ContentPhase, "gitpod.v1.EnvironmentStatus.ContentPhase", [
  { no: 0, name: "CONTENT_PHASE_UNSPECIFIED" },
  { no: 10, name: "CONTENT_PHASE_CREATING" },
  { no: 20, name: "CONTENT_PHASE_INITIALIZING" },
  { no: 30, name: "CONTENT_PHASE_READY" },
  { no: 35, name: "CONTENT_PHASE_UPDATING" },
  { no: 40, name: "CONTENT_PHASE_FAILED" },
]);

/**
 * @generated from message gitpod.v1.EnvironmentStatus.EnvironmentPortURL
 */
export class EnvironmentStatus_EnvironmentPortURL extends Message<EnvironmentStatus_EnvironmentPortURL> {
  /**
   * port is the port number of the environment port
   *
   * @generated from field: int32 port = 1;
   */
  port = 0;

  /**
   * url is the URL at which the environment port can be accessed
   *
   * @generated from field: string url = 2;
   */
  url = "";

  constructor(data?: PartialMessage<EnvironmentStatus_EnvironmentPortURL>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.EnvironmentStatus.EnvironmentPortURL";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "port", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EnvironmentStatus_EnvironmentPortURL {
    return new EnvironmentStatus_EnvironmentPortURL().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EnvironmentStatus_EnvironmentPortURL {
    return new EnvironmentStatus_EnvironmentPortURL().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EnvironmentStatus_EnvironmentPortURL {
    return new EnvironmentStatus_EnvironmentPortURL().fromJsonString(jsonString, options);
  }

  static equals(a: EnvironmentStatus_EnvironmentPortURL | PlainMessage<EnvironmentStatus_EnvironmentPortURL> | undefined, b: EnvironmentStatus_EnvironmentPortURL | PlainMessage<EnvironmentStatus_EnvironmentPortURL> | undefined): boolean {
    return proto3.util.equals(EnvironmentStatus_EnvironmentPortURL, a, b);
  }
}

/**
 * @generated from message gitpod.v1.EnvironmentStatus.EnvironmentSSHURL
 */
export class EnvironmentStatus_EnvironmentSSHURL extends Message<EnvironmentStatus_EnvironmentSSHURL> {
  /**
   * @generated from field: string url = 1;
   */
  url = "";

  constructor(data?: PartialMessage<EnvironmentStatus_EnvironmentSSHURL>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.EnvironmentStatus.EnvironmentSSHURL";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EnvironmentStatus_EnvironmentSSHURL {
    return new EnvironmentStatus_EnvironmentSSHURL().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EnvironmentStatus_EnvironmentSSHURL {
    return new EnvironmentStatus_EnvironmentSSHURL().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EnvironmentStatus_EnvironmentSSHURL {
    return new EnvironmentStatus_EnvironmentSSHURL().fromJsonString(jsonString, options);
  }

  static equals(a: EnvironmentStatus_EnvironmentSSHURL | PlainMessage<EnvironmentStatus_EnvironmentSSHURL> | undefined, b: EnvironmentStatus_EnvironmentSSHURL | PlainMessage<EnvironmentStatus_EnvironmentSSHURL> | undefined): boolean {
    return proto3.util.equals(EnvironmentStatus_EnvironmentSSHURL, a, b);
  }
}

/**
 * @generated from message gitpod.v1.EnvironmentStatus.EnvironmentURLs
 */
export class EnvironmentStatus_EnvironmentURLs extends Message<EnvironmentStatus_EnvironmentURLs> {
  /**
   * SSH is the URL at which the environment can be accessed via SSH.
   *
   * @generated from field: gitpod.v1.EnvironmentStatus.EnvironmentSSHURL ssh = 1;
   */
  ssh?: EnvironmentStatus_EnvironmentSSHURL;

  /**
   * @generated from field: repeated gitpod.v1.EnvironmentStatus.EnvironmentPortURL ports = 2;
   */
  ports: EnvironmentStatus_EnvironmentPortURL[] = [];

  /**
   * logs is the URL at which the environment logs can be accessed.
   *
   * @generated from field: string logs = 3;
   */
  logs = "";

  constructor(data?: PartialMessage<EnvironmentStatus_EnvironmentURLs>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.EnvironmentStatus.EnvironmentURLs";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ssh", kind: "message", T: EnvironmentStatus_EnvironmentSSHURL },
    { no: 2, name: "ports", kind: "message", T: EnvironmentStatus_EnvironmentPortURL, repeated: true },
    { no: 3, name: "logs", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EnvironmentStatus_EnvironmentURLs {
    return new EnvironmentStatus_EnvironmentURLs().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EnvironmentStatus_EnvironmentURLs {
    return new EnvironmentStatus_EnvironmentURLs().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EnvironmentStatus_EnvironmentURLs {
    return new EnvironmentStatus_EnvironmentURLs().fromJsonString(jsonString, options);
  }

  static equals(a: EnvironmentStatus_EnvironmentURLs | PlainMessage<EnvironmentStatus_EnvironmentURLs> | undefined, b: EnvironmentStatus_EnvironmentURLs | PlainMessage<EnvironmentStatus_EnvironmentURLs> | undefined): boolean {
    return proto3.util.equals(EnvironmentStatus_EnvironmentURLs, a, b);
  }
}

/**
 * @generated from message gitpod.v1.EnvironmentStatus.Machine
 */
export class EnvironmentStatus_Machine extends Message<EnvironmentStatus_Machine> {
  /**
   * session is the session that is currently active in the machine.
   *
   * @generated from field: string session = 1;
   */
  session = "";

  /**
   * phase is the current phase of the environment machine
   *
   * @generated from field: gitpod.v1.EnvironmentStatus.Machine.Phase phase = 2;
   */
  phase = EnvironmentStatus_Machine_Phase.UNSPECIFIED;

  /**
   * failure_message contains the reason the machine failed to operate.
   *
   * @generated from field: string failure_message = 3;
   */
  failureMessage = "";

  /**
   * timeout contains the reason the environment has timed out. If this field is
   * empty, the environment has not timed out.
   *
   * @generated from field: string timeout = 4;
   */
  timeout = "";

  /**
   * versions contains the versions of components in the machine.
   *
   * @generated from field: gitpod.v1.EnvironmentStatus.Machine.Versions versions = 5;
   */
  versions?: EnvironmentStatus_Machine_Versions;

  /**
   * warning_message contains warnings, e.g. when the machine is present but not in the expected state.
   *
   * @generated from field: string warning_message = 6;
   */
  warningMessage = "";

  constructor(data?: PartialMessage<EnvironmentStatus_Machine>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.EnvironmentStatus.Machine";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "session", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "phase", kind: "enum", T: proto3.getEnumType(EnvironmentStatus_Machine_Phase) },
    { no: 3, name: "failure_message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "timeout", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "versions", kind: "message", T: EnvironmentStatus_Machine_Versions },
    { no: 6, name: "warning_message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EnvironmentStatus_Machine {
    return new EnvironmentStatus_Machine().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EnvironmentStatus_Machine {
    return new EnvironmentStatus_Machine().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EnvironmentStatus_Machine {
    return new EnvironmentStatus_Machine().fromJsonString(jsonString, options);
  }

  static equals(a: EnvironmentStatus_Machine | PlainMessage<EnvironmentStatus_Machine> | undefined, b: EnvironmentStatus_Machine | PlainMessage<EnvironmentStatus_Machine> | undefined): boolean {
    return proto3.util.equals(EnvironmentStatus_Machine, a, b);
  }
}

/**
 * @generated from enum gitpod.v1.EnvironmentStatus.Machine.Phase
 */
export enum EnvironmentStatus_Machine_Phase {
  /**
   * @generated from enum value: PHASE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: PHASE_CREATING = 10;
   */
  CREATING = 10,

  /**
   * @generated from enum value: PHASE_STARTING = 20;
   */
  STARTING = 20,

  /**
   * @generated from enum value: PHASE_RUNNING = 30;
   */
  RUNNING = 30,

  /**
   * @generated from enum value: PHASE_STOPPING = 40;
   */
  STOPPING = 40,

  /**
   * @generated from enum value: PHASE_STOPPED = 50;
   */
  STOPPED = 50,

  /**
   * @generated from enum value: PHASE_DELETING = 60;
   */
  DELETING = 60,

  /**
   * @generated from enum value: PHASE_DELETED = 70;
   */
  DELETED = 70,
}
// Retrieve enum metadata with: proto3.getEnumType(EnvironmentStatus_Machine_Phase)
proto3.util.setEnumType(EnvironmentStatus_Machine_Phase, "gitpod.v1.EnvironmentStatus.Machine.Phase", [
  { no: 0, name: "PHASE_UNSPECIFIED" },
  { no: 10, name: "PHASE_CREATING" },
  { no: 20, name: "PHASE_STARTING" },
  { no: 30, name: "PHASE_RUNNING" },
  { no: 40, name: "PHASE_STOPPING" },
  { no: 50, name: "PHASE_STOPPED" },
  { no: 60, name: "PHASE_DELETING" },
  { no: 70, name: "PHASE_DELETED" },
]);

/**
 * @generated from message gitpod.v1.EnvironmentStatus.Machine.Versions
 */
export class EnvironmentStatus_Machine_Versions extends Message<EnvironmentStatus_Machine_Versions> {
  /**
   * @generated from field: string supervisor_version = 1;
   */
  supervisorVersion = "";

  /**
   * @generated from field: string supervisor_commit = 2;
   */
  supervisorCommit = "";

  constructor(data?: PartialMessage<EnvironmentStatus_Machine_Versions>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.EnvironmentStatus.Machine.Versions";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "supervisor_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "supervisor_commit", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EnvironmentStatus_Machine_Versions {
    return new EnvironmentStatus_Machine_Versions().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EnvironmentStatus_Machine_Versions {
    return new EnvironmentStatus_Machine_Versions().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EnvironmentStatus_Machine_Versions {
    return new EnvironmentStatus_Machine_Versions().fromJsonString(jsonString, options);
  }

  static equals(a: EnvironmentStatus_Machine_Versions | PlainMessage<EnvironmentStatus_Machine_Versions> | undefined, b: EnvironmentStatus_Machine_Versions | PlainMessage<EnvironmentStatus_Machine_Versions> | undefined): boolean {
    return proto3.util.equals(EnvironmentStatus_Machine_Versions, a, b);
  }
}

/**
 * @generated from message gitpod.v1.EnvironmentStatus.Content
 */
export class EnvironmentStatus_Content extends Message<EnvironmentStatus_Content> {
  /**
   * session is the session that is currently active in the environment.
   *
   * @generated from field: string session = 1;
   */
  session = "";

  /**
   * phase is the current phase of the environment content
   *
   * @generated from field: gitpod.v1.EnvironmentStatus.ContentPhase phase = 2;
   */
  phase = EnvironmentStatus_ContentPhase.UNSPECIFIED;

  /**
   * git is the Git working copy status of the environment.
   * Note: this is a best-effort field and more often than not will not be
   * present. Its absence does not indicate the absence of a working copy.
   *
   * @generated from field: gitpod.v1.EnvironmentGitStatus git = 3;
   */
  git?: EnvironmentGitStatus;

  /**
   * content_location_in_machine is the location of the content in the machine
   *
   * @generated from field: string content_location_in_machine = 4;
   */
  contentLocationInMachine = "";

  /**
   * failure_message contains the reason the content initialization failed.
   *
   * @generated from field: string failure_message = 5;
   */
  failureMessage = "";

  /**
   * warning_message contains warnings, e.g. when the content is present but not in the expected state.
   *
   * @generated from field: string warning_message = 6;
   */
  warningMessage = "";

  constructor(data?: PartialMessage<EnvironmentStatus_Content>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.EnvironmentStatus.Content";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "session", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "phase", kind: "enum", T: proto3.getEnumType(EnvironmentStatus_ContentPhase) },
    { no: 3, name: "git", kind: "message", T: EnvironmentGitStatus },
    { no: 4, name: "content_location_in_machine", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "failure_message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "warning_message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EnvironmentStatus_Content {
    return new EnvironmentStatus_Content().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EnvironmentStatus_Content {
    return new EnvironmentStatus_Content().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EnvironmentStatus_Content {
    return new EnvironmentStatus_Content().fromJsonString(jsonString, options);
  }

  static equals(a: EnvironmentStatus_Content | PlainMessage<EnvironmentStatus_Content> | undefined, b: EnvironmentStatus_Content | PlainMessage<EnvironmentStatus_Content> | undefined): boolean {
    return proto3.util.equals(EnvironmentStatus_Content, a, b);
  }
}

/**
 * @generated from message gitpod.v1.EnvironmentStatus.Secret
 */
export class EnvironmentStatus_Secret extends Message<EnvironmentStatus_Secret> {
  /**
   * @generated from field: gitpod.v1.EnvironmentStatus.ContentPhase phase = 2;
   */
  phase = EnvironmentStatus_ContentPhase.UNSPECIFIED;

  /**
   * @generated from field: string secret_name = 3;
   */
  secretName = "";

  /**
   * failure_message contains the reason the secret failed to be materialize.
   *
   * @generated from field: string failure_message = 4;
   */
  failureMessage = "";

  /**
   * warning_message contains warnings, e.g. when the secret is present but not in the expected state.
   *
   * @generated from field: string warning_message = 5;
   */
  warningMessage = "";

  constructor(data?: PartialMessage<EnvironmentStatus_Secret>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.EnvironmentStatus.Secret";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "phase", kind: "enum", T: proto3.getEnumType(EnvironmentStatus_ContentPhase) },
    { no: 3, name: "secret_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "failure_message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "warning_message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EnvironmentStatus_Secret {
    return new EnvironmentStatus_Secret().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EnvironmentStatus_Secret {
    return new EnvironmentStatus_Secret().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EnvironmentStatus_Secret {
    return new EnvironmentStatus_Secret().fromJsonString(jsonString, options);
  }

  static equals(a: EnvironmentStatus_Secret | PlainMessage<EnvironmentStatus_Secret> | undefined, b: EnvironmentStatus_Secret | PlainMessage<EnvironmentStatus_Secret> | undefined): boolean {
    return proto3.util.equals(EnvironmentStatus_Secret, a, b);
  }
}

/**
 * @generated from message gitpod.v1.EnvironmentStatus.DevContainer
 */
export class EnvironmentStatus_DevContainer extends Message<EnvironmentStatus_DevContainer> {
  /**
   * session is the session that is currently active in the devcontainer.
   *
   * @generated from field: string session = 1;
   */
  session = "";

  /**
   * phase is the current phase of the devcontainer
   *
   * @generated from field: gitpod.v1.EnvironmentStatus.DevContainer.Phase phase = 2;
   */
  phase = EnvironmentStatus_DevContainer_Phase.UNSPECIFIED;

  /**
   * devcontainer_file_path is the path to the devcontainer file relative to the repo root
   *
   * @generated from field: string devcontainer_file_path = 3;
   */
  devcontainerFilePath = "";

  /**
   * devcontainerconfig_in_sync indicates if the devcontainer is up to date w.r.t. the devcontainer config file.
   *
   * @generated from field: bool devcontainerconfig_in_sync = 4;
   */
  devcontainerconfigInSync = false;

  /**
   * secrets_in_sync indicates if the secrets are up to date w.r.t. the running devcontainer.
   *
   * @generated from field: bool secrets_in_sync = 5;
   */
  secretsInSync = false;

  /**
   * failure_message contains the reason the devcontainer failed to operate.
   *
   * @generated from field: string failure_message = 6;
   */
  failureMessage = "";

  /**
   * remote_user is the user that is used to connect to the devcontainer
   *
   * @generated from field: string remote_user = 7;
   */
  remoteUser = "";

  /**
   * remote_workspace_folder is the folder that is used to connect to the devcontainer
   *
   * @generated from field: string remote_workspace_folder = 8;
   */
  remoteWorkspaceFolder = "";

  /**
   * container_name is the name of the container that is used to connect to the devcontainer
   *
   * @generated from field: string container_name = 9;
   */
  containerName = "";

  /**
   * container_id is the ID of the container.
   *
   * @generated from field: string container_id = 10;
   */
  containerId = "";

  /**
   * warning_message contains warnings, e.g. when the devcontainer is present but not in the expected state.
   *
   * @generated from field: string warning_message = 12;
   */
  warningMessage = "";

  /**
   * devcontainer_file_presence indicates how the devcontainer file is present in the repo.
   *
   * @generated from field: gitpod.v1.EnvironmentStatus.DevContainer.Presence devcontainer_file_presence = 13;
   */
  devcontainerFilePresence = EnvironmentStatus_DevContainer_Presence.UNSPECIFIED;

  constructor(data?: PartialMessage<EnvironmentStatus_DevContainer>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.EnvironmentStatus.DevContainer";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "session", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "phase", kind: "enum", T: proto3.getEnumType(EnvironmentStatus_DevContainer_Phase) },
    { no: 3, name: "devcontainer_file_path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "devcontainerconfig_in_sync", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "secrets_in_sync", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "failure_message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "remote_user", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "remote_workspace_folder", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "container_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "container_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "warning_message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "devcontainer_file_presence", kind: "enum", T: proto3.getEnumType(EnvironmentStatus_DevContainer_Presence) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EnvironmentStatus_DevContainer {
    return new EnvironmentStatus_DevContainer().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EnvironmentStatus_DevContainer {
    return new EnvironmentStatus_DevContainer().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EnvironmentStatus_DevContainer {
    return new EnvironmentStatus_DevContainer().fromJsonString(jsonString, options);
  }

  static equals(a: EnvironmentStatus_DevContainer | PlainMessage<EnvironmentStatus_DevContainer> | undefined, b: EnvironmentStatus_DevContainer | PlainMessage<EnvironmentStatus_DevContainer> | undefined): boolean {
    return proto3.util.equals(EnvironmentStatus_DevContainer, a, b);
  }
}

/**
 * @generated from enum gitpod.v1.EnvironmentStatus.DevContainer.Phase
 */
export enum EnvironmentStatus_DevContainer_Phase {
  /**
   * @generated from enum value: PHASE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: PHASE_CREATING = 10;
   */
  CREATING = 10,

  /**
   * @generated from enum value: PHASE_RUNNING = 20;
   */
  RUNNING = 20,

  /**
   * @generated from enum value: PHASE_STOPPED = 30;
   */
  STOPPED = 30,

  /**
   * @generated from enum value: PHASE_FAILED = 40;
   */
  FAILED = 40,
}
// Retrieve enum metadata with: proto3.getEnumType(EnvironmentStatus_DevContainer_Phase)
proto3.util.setEnumType(EnvironmentStatus_DevContainer_Phase, "gitpod.v1.EnvironmentStatus.DevContainer.Phase", [
  { no: 0, name: "PHASE_UNSPECIFIED" },
  { no: 10, name: "PHASE_CREATING" },
  { no: 20, name: "PHASE_RUNNING" },
  { no: 30, name: "PHASE_STOPPED" },
  { no: 40, name: "PHASE_FAILED" },
]);

/**
 * @generated from enum gitpod.v1.EnvironmentStatus.DevContainer.Presence
 */
export enum EnvironmentStatus_DevContainer_Presence {
  /**
   * @generated from enum value: PRESENCE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: PRESENCE_GENERATED = 1;
   */
  GENERATED = 1,

  /**
   * @generated from enum value: PRESENCE_DISCOVERED = 2;
   */
  DISCOVERED = 2,

  /**
   * @generated from enum value: PRESENCE_SPECIFIED = 3;
   */
  SPECIFIED = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(EnvironmentStatus_DevContainer_Presence)
proto3.util.setEnumType(EnvironmentStatus_DevContainer_Presence, "gitpod.v1.EnvironmentStatus.DevContainer.Presence", [
  { no: 0, name: "PRESENCE_UNSPECIFIED" },
  { no: 1, name: "PRESENCE_GENERATED" },
  { no: 2, name: "PRESENCE_DISCOVERED" },
  { no: 3, name: "PRESENCE_SPECIFIED" },
]);

/**
 * RunnerACK is the acknowledgement from the runner that is has received the
 * environment spec.
 *
 * @generated from message gitpod.v1.EnvironmentStatus.RunnerACK
 */
export class EnvironmentStatus_RunnerACK extends Message<EnvironmentStatus_RunnerACK> {
  /**
   * @generated from field: uint64 spec_version = 1;
   */
  specVersion = protoInt64.zero;

  /**
   * @generated from field: gitpod.v1.EnvironmentStatus.RunnerACK.StatusCode status_code = 2;
   */
  statusCode = EnvironmentStatus_RunnerACK_StatusCode.UNSPECIFIED;

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  constructor(data?: PartialMessage<EnvironmentStatus_RunnerACK>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.EnvironmentStatus.RunnerACK";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "spec_version", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "status_code", kind: "enum", T: proto3.getEnumType(EnvironmentStatus_RunnerACK_StatusCode) },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EnvironmentStatus_RunnerACK {
    return new EnvironmentStatus_RunnerACK().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EnvironmentStatus_RunnerACK {
    return new EnvironmentStatus_RunnerACK().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EnvironmentStatus_RunnerACK {
    return new EnvironmentStatus_RunnerACK().fromJsonString(jsonString, options);
  }

  static equals(a: EnvironmentStatus_RunnerACK | PlainMessage<EnvironmentStatus_RunnerACK> | undefined, b: EnvironmentStatus_RunnerACK | PlainMessage<EnvironmentStatus_RunnerACK> | undefined): boolean {
    return proto3.util.equals(EnvironmentStatus_RunnerACK, a, b);
  }
}

/**
 * @generated from enum gitpod.v1.EnvironmentStatus.RunnerACK.StatusCode
 */
export enum EnvironmentStatus_RunnerACK_StatusCode {
  /**
   * @generated from enum value: STATUS_CODE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: STATUS_CODE_OK = 1;
   */
  OK = 1,

  /**
   * @generated from enum value: STATUS_CODE_INVALID_RESOURCE = 2;
   */
  INVALID_RESOURCE = 2,

  /**
   * @generated from enum value: STATUS_CODE_FAILED_PRECONDITION = 3;
   */
  FAILED_PRECONDITION = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(EnvironmentStatus_RunnerACK_StatusCode)
proto3.util.setEnumType(EnvironmentStatus_RunnerACK_StatusCode, "gitpod.v1.EnvironmentStatus.RunnerACK.StatusCode", [
  { no: 0, name: "STATUS_CODE_UNSPECIFIED" },
  { no: 1, name: "STATUS_CODE_OK" },
  { no: 2, name: "STATUS_CODE_INVALID_RESOURCE" },
  { no: 3, name: "STATUS_CODE_FAILED_PRECONDITION" },
]);

/**
 * @generated from message gitpod.v1.EnvironmentStatus.SSHPublicKey
 */
export class EnvironmentStatus_SSHPublicKey extends Message<EnvironmentStatus_SSHPublicKey> {
  /**
   * id is the unique identifier of the public key
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * phase is the current phase of the public key
   *
   * @generated from field: gitpod.v1.EnvironmentStatus.ContentPhase phase = 2;
   */
  phase = EnvironmentStatus_ContentPhase.UNSPECIFIED;

  constructor(data?: PartialMessage<EnvironmentStatus_SSHPublicKey>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.EnvironmentStatus.SSHPublicKey";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "phase", kind: "enum", T: proto3.getEnumType(EnvironmentStatus_ContentPhase) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EnvironmentStatus_SSHPublicKey {
    return new EnvironmentStatus_SSHPublicKey().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EnvironmentStatus_SSHPublicKey {
    return new EnvironmentStatus_SSHPublicKey().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EnvironmentStatus_SSHPublicKey {
    return new EnvironmentStatus_SSHPublicKey().fromJsonString(jsonString, options);
  }

  static equals(a: EnvironmentStatus_SSHPublicKey | PlainMessage<EnvironmentStatus_SSHPublicKey> | undefined, b: EnvironmentStatus_SSHPublicKey | PlainMessage<EnvironmentStatus_SSHPublicKey> | undefined): boolean {
    return proto3.util.equals(EnvironmentStatus_SSHPublicKey, a, b);
  }
}

/**
 * @generated from message gitpod.v1.EnvironmentStatus.AutomationsFile
 */
export class EnvironmentStatus_AutomationsFile extends Message<EnvironmentStatus_AutomationsFile> {
  /**
   * session is the automations file session that is currently applied in the environment.
   *
   * @generated from field: string session = 1;
   */
  session = "";

  /**
   * automations_file_path is the path to the automations file relative to the repo root.
   *
   * @generated from field: string automations_file_path = 2;
   */
  automationsFilePath = "";

  /**
   * phase is the current phase of the automations file.
   *
   * @generated from field: gitpod.v1.EnvironmentStatus.ContentPhase phase = 3;
   */
  phase = EnvironmentStatus_ContentPhase.UNSPECIFIED;

  /**
   * failure_message contains the reason the automations file failed to be applied.
   * This is only set if the phase is FAILED.
   *
   * @generated from field: string failure_message = 4;
   */
  failureMessage = "";

  /**
   * automations_file_presence indicates how an automations file is present in the environment.
   *
   * @generated from field: gitpod.v1.EnvironmentStatus.AutomationsFile.Presence automations_file_presence = 5;
   */
  automationsFilePresence = EnvironmentStatus_AutomationsFile_Presence.UNSPECIFIED;

  constructor(data?: PartialMessage<EnvironmentStatus_AutomationsFile>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.EnvironmentStatus.AutomationsFile";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "session", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "automations_file_path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "phase", kind: "enum", T: proto3.getEnumType(EnvironmentStatus_ContentPhase) },
    { no: 4, name: "failure_message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "automations_file_presence", kind: "enum", T: proto3.getEnumType(EnvironmentStatus_AutomationsFile_Presence) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EnvironmentStatus_AutomationsFile {
    return new EnvironmentStatus_AutomationsFile().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EnvironmentStatus_AutomationsFile {
    return new EnvironmentStatus_AutomationsFile().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EnvironmentStatus_AutomationsFile {
    return new EnvironmentStatus_AutomationsFile().fromJsonString(jsonString, options);
  }

  static equals(a: EnvironmentStatus_AutomationsFile | PlainMessage<EnvironmentStatus_AutomationsFile> | undefined, b: EnvironmentStatus_AutomationsFile | PlainMessage<EnvironmentStatus_AutomationsFile> | undefined): boolean {
    return proto3.util.equals(EnvironmentStatus_AutomationsFile, a, b);
  }
}

/**
 * @generated from enum gitpod.v1.EnvironmentStatus.AutomationsFile.Presence
 */
export enum EnvironmentStatus_AutomationsFile_Presence {
  /**
   * @generated from enum value: PRESENCE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: PRESENCE_ABSENT = 1;
   */
  ABSENT = 1,

  /**
   * @generated from enum value: PRESENCE_DISCOVERED = 2;
   */
  DISCOVERED = 2,

  /**
   * @generated from enum value: PRESENCE_SPECIFIED = 3;
   */
  SPECIFIED = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(EnvironmentStatus_AutomationsFile_Presence)
proto3.util.setEnumType(EnvironmentStatus_AutomationsFile_Presence, "gitpod.v1.EnvironmentStatus.AutomationsFile.Presence", [
  { no: 0, name: "PRESENCE_UNSPECIFIED" },
  { no: 1, name: "PRESENCE_ABSENT" },
  { no: 2, name: "PRESENCE_DISCOVERED" },
  { no: 3, name: "PRESENCE_SPECIFIED" },
]);

/**
 * @generated from message gitpod.v1.EnvironmentGitStatus
 */
export class EnvironmentGitStatus extends Message<EnvironmentGitStatus> {
  /**
   * clone_url is the repository url as you would pass it to "git clone".
   * Only HTTPS clone URLs are supported.
   *
   * @generated from field: string clone_url = 1;
   */
  cloneUrl = "";

  /**
   * branch is branch we're currently on
   *
   * @generated from field: string branch = 2;
   */
  branch = "";

  /**
   * latest_commit is the most recent commit on the current branch
   *
   * @generated from field: string latest_commit = 3;
   */
  latestCommit = "";

  /**
   * unpushed_commits is an array of unpushed changes in the environment, possibly
   * truncated
   *
   * @generated from field: repeated string unpushed_commits = 4;
   */
  unpushedCommits: string[] = [];

  /**
   * the total number of unpushed changes
   *
   * @generated from field: int32 total_unpushed_commits = 5;
   */
  totalUnpushedCommits = 0;

  /**
   * changed_files is an array of changed files in the environment, possibly
   * truncated
   *
   * @generated from field: repeated gitpod.v1.FileChange changed_files = 6;
   */
  changedFiles: FileChange[] = [];

  /**
   * @generated from field: int32 total_changed_files = 7;
   */
  totalChangedFiles = 0;

  constructor(data?: PartialMessage<EnvironmentGitStatus>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.EnvironmentGitStatus";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "clone_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "branch", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "latest_commit", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "unpushed_commits", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "total_unpushed_commits", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "changed_files", kind: "message", T: FileChange, repeated: true },
    { no: 7, name: "total_changed_files", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EnvironmentGitStatus {
    return new EnvironmentGitStatus().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EnvironmentGitStatus {
    return new EnvironmentGitStatus().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EnvironmentGitStatus {
    return new EnvironmentGitStatus().fromJsonString(jsonString, options);
  }

  static equals(a: EnvironmentGitStatus | PlainMessage<EnvironmentGitStatus> | undefined, b: EnvironmentGitStatus | PlainMessage<EnvironmentGitStatus> | undefined): boolean {
    return proto3.util.equals(EnvironmentGitStatus, a, b);
  }
}

/**
 * @generated from message gitpod.v1.FileChange
 */
export class FileChange extends Message<FileChange> {
  /**
   * path is the path of the file
   *
   * @generated from field: string path = 1;
   */
  path = "";

  /**
   * @generated from field: gitpod.v1.FileChange.ChangeType change_type = 2;
   */
  changeType = FileChange_ChangeType.UNSPECIFIED;

  constructor(data?: PartialMessage<FileChange>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.FileChange";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "change_type", kind: "enum", T: proto3.getEnumType(FileChange_ChangeType) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FileChange {
    return new FileChange().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FileChange {
    return new FileChange().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FileChange {
    return new FileChange().fromJsonString(jsonString, options);
  }

  static equals(a: FileChange | PlainMessage<FileChange> | undefined, b: FileChange | PlainMessage<FileChange> | undefined): boolean {
    return proto3.util.equals(FileChange, a, b);
  }
}

/**
 * ChangeType is the type of change that happened to the file
 *
 * @generated from enum gitpod.v1.FileChange.ChangeType
 */
export enum FileChange_ChangeType {
  /**
   * @generated from enum value: CHANGE_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: CHANGE_TYPE_ADDED = 1;
   */
  ADDED = 1,

  /**
   * @generated from enum value: CHANGE_TYPE_MODIFIED = 2;
   */
  MODIFIED = 2,

  /**
   * @generated from enum value: CHANGE_TYPE_DELETED = 3;
   */
  DELETED = 3,

  /**
   * @generated from enum value: CHANGE_TYPE_RENAMED = 4;
   */
  RENAMED = 4,

  /**
   * @generated from enum value: CHANGE_TYPE_COPIED = 5;
   */
  COPIED = 5,

  /**
   * @generated from enum value: CHANGE_TYPE_UPDATED_BUT_UNMERGED = 6;
   */
  UPDATED_BUT_UNMERGED = 6,

  /**
   * @generated from enum value: CHANGE_TYPE_UNTRACKED = 7;
   */
  UNTRACKED = 7,
}
// Retrieve enum metadata with: proto3.getEnumType(FileChange_ChangeType)
proto3.util.setEnumType(FileChange_ChangeType, "gitpod.v1.FileChange.ChangeType", [
  { no: 0, name: "CHANGE_TYPE_UNSPECIFIED" },
  { no: 1, name: "CHANGE_TYPE_ADDED" },
  { no: 2, name: "CHANGE_TYPE_MODIFIED" },
  { no: 3, name: "CHANGE_TYPE_DELETED" },
  { no: 4, name: "CHANGE_TYPE_RENAMED" },
  { no: 5, name: "CHANGE_TYPE_COPIED" },
  { no: 6, name: "CHANGE_TYPE_UPDATED_BUT_UNMERGED" },
  { no: 7, name: "CHANGE_TYPE_UNTRACKED" },
]);

/**
 * EnvironmentInitializer specifies how an environment is to be initialized
 *
 * @generated from message gitpod.v1.EnvironmentInitializer
 */
export class EnvironmentInitializer extends Message<EnvironmentInitializer> {
  /**
   * @generated from field: repeated gitpod.v1.EnvironmentInitializer.Spec specs = 1;
   */
  specs: EnvironmentInitializer_Spec[] = [];

  constructor(data?: PartialMessage<EnvironmentInitializer>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.EnvironmentInitializer";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "specs", kind: "message", T: EnvironmentInitializer_Spec, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EnvironmentInitializer {
    return new EnvironmentInitializer().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EnvironmentInitializer {
    return new EnvironmentInitializer().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EnvironmentInitializer {
    return new EnvironmentInitializer().fromJsonString(jsonString, options);
  }

  static equals(a: EnvironmentInitializer | PlainMessage<EnvironmentInitializer> | undefined, b: EnvironmentInitializer | PlainMessage<EnvironmentInitializer> | undefined): boolean {
    return proto3.util.equals(EnvironmentInitializer, a, b);
  }
}

/**
 * @generated from message gitpod.v1.EnvironmentInitializer.Spec
 */
export class EnvironmentInitializer_Spec extends Message<EnvironmentInitializer_Spec> {
  /**
   * @generated from oneof gitpod.v1.EnvironmentInitializer.Spec.spec
   */
  spec: {
    /**
     * @generated from field: gitpod.v1.GitInitializer git = 1;
     */
    value: GitInitializer;
    case: "git";
  } | {
    /**
     * @generated from field: gitpod.v1.ContextURLInitializer context_url = 2;
     */
    value: ContextURLInitializer;
    case: "contextUrl";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<EnvironmentInitializer_Spec>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.EnvironmentInitializer.Spec";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "git", kind: "message", T: GitInitializer, oneof: "spec" },
    { no: 2, name: "context_url", kind: "message", T: ContextURLInitializer, oneof: "spec" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EnvironmentInitializer_Spec {
    return new EnvironmentInitializer_Spec().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EnvironmentInitializer_Spec {
    return new EnvironmentInitializer_Spec().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EnvironmentInitializer_Spec {
    return new EnvironmentInitializer_Spec().fromJsonString(jsonString, options);
  }

  static equals(a: EnvironmentInitializer_Spec | PlainMessage<EnvironmentInitializer_Spec> | undefined, b: EnvironmentInitializer_Spec | PlainMessage<EnvironmentInitializer_Spec> | undefined): boolean {
    return proto3.util.equals(EnvironmentInitializer_Spec, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ContextURLInitializer
 */
export class ContextURLInitializer extends Message<ContextURLInitializer> {
  /**
   * url is the URL from which the environment is created
   *
   * @generated from field: string url = 1;
   */
  url = "";

  constructor(data?: PartialMessage<ContextURLInitializer>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ContextURLInitializer";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ContextURLInitializer {
    return new ContextURLInitializer().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ContextURLInitializer {
    return new ContextURLInitializer().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ContextURLInitializer {
    return new ContextURLInitializer().fromJsonString(jsonString, options);
  }

  static equals(a: ContextURLInitializer | PlainMessage<ContextURLInitializer> | undefined, b: ContextURLInitializer | PlainMessage<ContextURLInitializer> | undefined): boolean {
    return proto3.util.equals(ContextURLInitializer, a, b);
  }
}

/**
 * @generated from message gitpod.v1.GitInitializer
 */
export class GitInitializer extends Message<GitInitializer> {
  /**
   * remote_uri is the Git remote origin
   *
   * @generated from field: string remote_uri = 1;
   */
  remoteUri = "";

  /**
   * upstream_Remote_uri is the fork upstream of a repository
   *
   * @generated from field: string upstream_remote_uri = 2;
   */
  upstreamRemoteUri = "";

  /**
   * the target mode determines what gets checked out
   *
   * @generated from field: gitpod.v1.GitInitializer.CloneTargetMode target_mode = 3;
   */
  targetMode = GitInitializer_CloneTargetMode.UNSPECIFIED;

  /**
   * the value for the clone target mode - use depends on the target mode
   *
   * @generated from field: string clone_target = 4;
   */
  cloneTarget = "";

  /**
   * a path relative to the environment root in which the code will be checked out
   * to
   *
   * @generated from field: string checkout_location = 5;
   */
  checkoutLocation = "";

  constructor(data?: PartialMessage<GitInitializer>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.GitInitializer";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "remote_uri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "upstream_remote_uri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "target_mode", kind: "enum", T: proto3.getEnumType(GitInitializer_CloneTargetMode) },
    { no: 4, name: "clone_target", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "checkout_location", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GitInitializer {
    return new GitInitializer().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GitInitializer {
    return new GitInitializer().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GitInitializer {
    return new GitInitializer().fromJsonString(jsonString, options);
  }

  static equals(a: GitInitializer | PlainMessage<GitInitializer> | undefined, b: GitInitializer | PlainMessage<GitInitializer> | undefined): boolean {
    return proto3.util.equals(GitInitializer, a, b);
  }
}

/**
 * CloneTargetMode is the target state in which we want to leave a
 * GitEnvironment
 *
 * @generated from enum gitpod.v1.GitInitializer.CloneTargetMode
 */
export enum GitInitializer_CloneTargetMode {
  /**
   * @generated from enum value: CLONE_TARGET_MODE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * REMOTE_HEAD has the local WS point at the remote branch head
   *
   * @generated from enum value: CLONE_TARGET_MODE_REMOTE_HEAD = 1;
   */
  REMOTE_HEAD = 1,

  /**
   * REMOTE_COMMIT has the local WS point at a specific commit
   *
   * @generated from enum value: CLONE_TARGET_MODE_REMOTE_COMMIT = 2;
   */
  REMOTE_COMMIT = 2,

  /**
   * REMOTE_BRANCH has the local WS point at a remote branch
   *
   * @generated from enum value: CLONE_TARGET_MODE_REMOTE_BRANCH = 3;
   */
  REMOTE_BRANCH = 3,

  /**
   * LOCAL_BRANCH creates a local branch in the environment
   *
   * @generated from enum value: CLONE_TARGET_MODE_LOCAL_BRANCH = 4;
   */
  LOCAL_BRANCH = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(GitInitializer_CloneTargetMode)
proto3.util.setEnumType(GitInitializer_CloneTargetMode, "gitpod.v1.GitInitializer.CloneTargetMode", [
  { no: 0, name: "CLONE_TARGET_MODE_UNSPECIFIED" },
  { no: 1, name: "CLONE_TARGET_MODE_REMOTE_HEAD" },
  { no: 2, name: "CLONE_TARGET_MODE_REMOTE_COMMIT" },
  { no: 3, name: "CLONE_TARGET_MODE_REMOTE_BRANCH" },
  { no: 4, name: "CLONE_TARGET_MODE_LOCAL_BRANCH" },
]);

/**
 * @generated from message gitpod.v1.StartEnvironmentRequest
 */
export class StartEnvironmentRequest extends Message<StartEnvironmentRequest> {
  /**
   * environment_id specifies which environment should be started.
   *
   * @generated from field: string environment_id = 1;
   */
  environmentId = "";

  constructor(data?: PartialMessage<StartEnvironmentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.StartEnvironmentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "environment_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StartEnvironmentRequest {
    return new StartEnvironmentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StartEnvironmentRequest {
    return new StartEnvironmentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StartEnvironmentRequest {
    return new StartEnvironmentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: StartEnvironmentRequest | PlainMessage<StartEnvironmentRequest> | undefined, b: StartEnvironmentRequest | PlainMessage<StartEnvironmentRequest> | undefined): boolean {
    return proto3.util.equals(StartEnvironmentRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.StartEnvironmentResponse
 */
export class StartEnvironmentResponse extends Message<StartEnvironmentResponse> {
  constructor(data?: PartialMessage<StartEnvironmentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.StartEnvironmentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StartEnvironmentResponse {
    return new StartEnvironmentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StartEnvironmentResponse {
    return new StartEnvironmentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StartEnvironmentResponse {
    return new StartEnvironmentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: StartEnvironmentResponse | PlainMessage<StartEnvironmentResponse> | undefined, b: StartEnvironmentResponse | PlainMessage<StartEnvironmentResponse> | undefined): boolean {
    return proto3.util.equals(StartEnvironmentResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.StopEnvironmentRequest
 */
export class StopEnvironmentRequest extends Message<StopEnvironmentRequest> {
  /**
   * environment_id specifies which environment should be stopped.
   *
   * +required
   *
   * @generated from field: string environment_id = 1;
   */
  environmentId = "";

  constructor(data?: PartialMessage<StopEnvironmentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.StopEnvironmentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "environment_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StopEnvironmentRequest {
    return new StopEnvironmentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StopEnvironmentRequest {
    return new StopEnvironmentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StopEnvironmentRequest {
    return new StopEnvironmentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: StopEnvironmentRequest | PlainMessage<StopEnvironmentRequest> | undefined, b: StopEnvironmentRequest | PlainMessage<StopEnvironmentRequest> | undefined): boolean {
    return proto3.util.equals(StopEnvironmentRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.StopEnvironmentResponse
 */
export class StopEnvironmentResponse extends Message<StopEnvironmentResponse> {
  constructor(data?: PartialMessage<StopEnvironmentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.StopEnvironmentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StopEnvironmentResponse {
    return new StopEnvironmentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StopEnvironmentResponse {
    return new StopEnvironmentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StopEnvironmentResponse {
    return new StopEnvironmentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: StopEnvironmentResponse | PlainMessage<StopEnvironmentResponse> | undefined, b: StopEnvironmentResponse | PlainMessage<StopEnvironmentResponse> | undefined): boolean {
    return proto3.util.equals(StopEnvironmentResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.UpdateEnvironmentRequest
 */
export class UpdateEnvironmentRequest extends Message<UpdateEnvironmentRequest> {
  /**
   * environment_id specifies which environment should be updated.
   *
   * +required
   *
   * @generated from field: string environment_id = 1;
   */
  environmentId = "";

  /**
   * @generated from field: optional gitpod.v1.UpdateEnvironmentRequest.Metadata metadata = 2;
   */
  metadata?: UpdateEnvironmentRequest_Metadata;

  /**
   * @generated from field: optional gitpod.v1.UpdateEnvironmentRequest.Spec spec = 3;
   */
  spec?: UpdateEnvironmentRequest_Spec;

  constructor(data?: PartialMessage<UpdateEnvironmentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.UpdateEnvironmentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "environment_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "metadata", kind: "message", T: UpdateEnvironmentRequest_Metadata, opt: true },
    { no: 3, name: "spec", kind: "message", T: UpdateEnvironmentRequest_Spec, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateEnvironmentRequest {
    return new UpdateEnvironmentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateEnvironmentRequest {
    return new UpdateEnvironmentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateEnvironmentRequest {
    return new UpdateEnvironmentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateEnvironmentRequest | PlainMessage<UpdateEnvironmentRequest> | undefined, b: UpdateEnvironmentRequest | PlainMessage<UpdateEnvironmentRequest> | undefined): boolean {
    return proto3.util.equals(UpdateEnvironmentRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.UpdateEnvironmentRequest.Content
 */
export class UpdateEnvironmentRequest_Content extends Message<UpdateEnvironmentRequest_Content> {
  /**
   * session should be changed to trigger a content reinitialization
   *
   * @generated from field: optional string session = 1;
   */
  session?: string;

  /**
   * initializer configures how the environment is to be initialized
   *
   * @generated from field: optional gitpod.v1.EnvironmentInitializer initializer = 2;
   */
  initializer?: EnvironmentInitializer;

  /**
   * The Git username
   *
   * @generated from field: optional string git_username = 3;
   */
  gitUsername?: string;

  /**
   * The Git email address
   *
   * @generated from field: optional string git_email = 4;
   */
  gitEmail?: string;

  constructor(data?: PartialMessage<UpdateEnvironmentRequest_Content>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.UpdateEnvironmentRequest.Content";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "session", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "initializer", kind: "message", T: EnvironmentInitializer, opt: true },
    { no: 3, name: "git_username", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "git_email", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateEnvironmentRequest_Content {
    return new UpdateEnvironmentRequest_Content().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateEnvironmentRequest_Content {
    return new UpdateEnvironmentRequest_Content().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateEnvironmentRequest_Content {
    return new UpdateEnvironmentRequest_Content().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateEnvironmentRequest_Content | PlainMessage<UpdateEnvironmentRequest_Content> | undefined, b: UpdateEnvironmentRequest_Content | PlainMessage<UpdateEnvironmentRequest_Content> | undefined): boolean {
    return proto3.util.equals(UpdateEnvironmentRequest_Content, a, b);
  }
}

/**
 * @generated from message gitpod.v1.UpdateEnvironmentRequest.SSHPublicKey
 */
export class UpdateEnvironmentRequest_SSHPublicKey extends Message<UpdateEnvironmentRequest_SSHPublicKey> {
  /**
   * id is the unique identifier of the public key
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * value is the actual public key in the public key file format
   * if not provided, the public key will be removed
   *
   * @generated from field: optional string value = 2;
   */
  value?: string;

  constructor(data?: PartialMessage<UpdateEnvironmentRequest_SSHPublicKey>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.UpdateEnvironmentRequest.SSHPublicKey";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateEnvironmentRequest_SSHPublicKey {
    return new UpdateEnvironmentRequest_SSHPublicKey().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateEnvironmentRequest_SSHPublicKey {
    return new UpdateEnvironmentRequest_SSHPublicKey().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateEnvironmentRequest_SSHPublicKey {
    return new UpdateEnvironmentRequest_SSHPublicKey().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateEnvironmentRequest_SSHPublicKey | PlainMessage<UpdateEnvironmentRequest_SSHPublicKey> | undefined, b: UpdateEnvironmentRequest_SSHPublicKey | PlainMessage<UpdateEnvironmentRequest_SSHPublicKey> | undefined): boolean {
    return proto3.util.equals(UpdateEnvironmentRequest_SSHPublicKey, a, b);
  }
}

/**
 * @generated from message gitpod.v1.UpdateEnvironmentRequest.DevContainer
 */
export class UpdateEnvironmentRequest_DevContainer extends Message<UpdateEnvironmentRequest_DevContainer> {
  /**
   * session should be changed to trigger a devcontainer rebuild
   *
   * @generated from field: optional string session = 1;
   */
  session?: string;

  /**
   * devcontainer_file_path is the path to the devcontainer file relative to the repo root
   *
   * @generated from field: optional string devcontainer_file_path = 2;
   */
  devcontainerFilePath?: string;

  constructor(data?: PartialMessage<UpdateEnvironmentRequest_DevContainer>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.UpdateEnvironmentRequest.DevContainer";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "session", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "devcontainer_file_path", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateEnvironmentRequest_DevContainer {
    return new UpdateEnvironmentRequest_DevContainer().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateEnvironmentRequest_DevContainer {
    return new UpdateEnvironmentRequest_DevContainer().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateEnvironmentRequest_DevContainer {
    return new UpdateEnvironmentRequest_DevContainer().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateEnvironmentRequest_DevContainer | PlainMessage<UpdateEnvironmentRequest_DevContainer> | undefined, b: UpdateEnvironmentRequest_DevContainer | PlainMessage<UpdateEnvironmentRequest_DevContainer> | undefined): boolean {
    return proto3.util.equals(UpdateEnvironmentRequest_DevContainer, a, b);
  }
}

/**
 * @generated from message gitpod.v1.UpdateEnvironmentRequest.Timeout
 */
export class UpdateEnvironmentRequest_Timeout extends Message<UpdateEnvironmentRequest_Timeout> {
  /**
   * inacitivity is the maximum time of disconnection before the environment is
   * stopped or paused. Minimum duration is 30 minutes. Set to 0 to disable.
   *
   * @generated from field: optional google.protobuf.Duration disconnected = 1;
   */
  disconnected?: Duration;

  constructor(data?: PartialMessage<UpdateEnvironmentRequest_Timeout>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.UpdateEnvironmentRequest.Timeout";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "disconnected", kind: "message", T: Duration, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateEnvironmentRequest_Timeout {
    return new UpdateEnvironmentRequest_Timeout().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateEnvironmentRequest_Timeout {
    return new UpdateEnvironmentRequest_Timeout().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateEnvironmentRequest_Timeout {
    return new UpdateEnvironmentRequest_Timeout().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateEnvironmentRequest_Timeout | PlainMessage<UpdateEnvironmentRequest_Timeout> | undefined, b: UpdateEnvironmentRequest_Timeout | PlainMessage<UpdateEnvironmentRequest_Timeout> | undefined): boolean {
    return proto3.util.equals(UpdateEnvironmentRequest_Timeout, a, b);
  }
}

/**
 * @generated from message gitpod.v1.UpdateEnvironmentRequest.AutomationsFile
 */
export class UpdateEnvironmentRequest_AutomationsFile extends Message<UpdateEnvironmentRequest_AutomationsFile> {
  /**
   * @generated from field: optional string session = 1;
   */
  session?: string;

  /**
   * automations_file_path is the path to the automations file that is applied in the environment,
   * relative to the repo root.
   *
   * @generated from field: optional string automations_file_path = 2;
   */
  automationsFilePath?: string;

  constructor(data?: PartialMessage<UpdateEnvironmentRequest_AutomationsFile>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.UpdateEnvironmentRequest.AutomationsFile";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "session", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "automations_file_path", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateEnvironmentRequest_AutomationsFile {
    return new UpdateEnvironmentRequest_AutomationsFile().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateEnvironmentRequest_AutomationsFile {
    return new UpdateEnvironmentRequest_AutomationsFile().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateEnvironmentRequest_AutomationsFile {
    return new UpdateEnvironmentRequest_AutomationsFile().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateEnvironmentRequest_AutomationsFile | PlainMessage<UpdateEnvironmentRequest_AutomationsFile> | undefined, b: UpdateEnvironmentRequest_AutomationsFile | PlainMessage<UpdateEnvironmentRequest_AutomationsFile> | undefined): boolean {
    return proto3.util.equals(UpdateEnvironmentRequest_AutomationsFile, a, b);
  }
}

/**
 * @generated from message gitpod.v1.UpdateEnvironmentRequest.Metadata
 */
export class UpdateEnvironmentRequest_Metadata extends Message<UpdateEnvironmentRequest_Metadata> {
  constructor(data?: PartialMessage<UpdateEnvironmentRequest_Metadata>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.UpdateEnvironmentRequest.Metadata";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateEnvironmentRequest_Metadata {
    return new UpdateEnvironmentRequest_Metadata().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateEnvironmentRequest_Metadata {
    return new UpdateEnvironmentRequest_Metadata().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateEnvironmentRequest_Metadata {
    return new UpdateEnvironmentRequest_Metadata().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateEnvironmentRequest_Metadata | PlainMessage<UpdateEnvironmentRequest_Metadata> | undefined, b: UpdateEnvironmentRequest_Metadata | PlainMessage<UpdateEnvironmentRequest_Metadata> | undefined): boolean {
    return proto3.util.equals(UpdateEnvironmentRequest_Metadata, a, b);
  }
}

/**
 * @generated from message gitpod.v1.UpdateEnvironmentRequest.Spec
 */
export class UpdateEnvironmentRequest_Spec extends Message<UpdateEnvironmentRequest_Spec> {
  /**
   * @generated from field: optional gitpod.v1.UpdateEnvironmentRequest.Content content = 2;
   */
  content?: UpdateEnvironmentRequest_Content;

  /**
   * ssh_public_keys are the public keys to update
   * empty array means nothing to update
   *
   * @generated from field: repeated gitpod.v1.UpdateEnvironmentRequest.SSHPublicKey ssh_public_keys = 3;
   */
  sshPublicKeys: UpdateEnvironmentRequest_SSHPublicKey[] = [];

  /**
   * @generated from field: optional gitpod.v1.UpdateEnvironmentRequest.DevContainer devcontainer = 4;
   */
  devcontainer?: UpdateEnvironmentRequest_DevContainer;

  /**
   * ports controls port sharing
   *
   * @generated from field: repeated gitpod.v1.EnvironmentSpec.EnvironmentPort ports = 5;
   */
  ports: EnvironmentSpec_EnvironmentPort[] = [];

  /**
   * Timeout configures the environment timeout
   *
   * @generated from field: optional gitpod.v1.UpdateEnvironmentRequest.Timeout timeout = 6;
   */
  timeout?: UpdateEnvironmentRequest_Timeout;

  /**
   * automations_file is the automations file spec of the environment
   *
   * @generated from field: optional gitpod.v1.UpdateEnvironmentRequest.AutomationsFile automations_file = 7;
   */
  automationsFile?: UpdateEnvironmentRequest_AutomationsFile;

  constructor(data?: PartialMessage<UpdateEnvironmentRequest_Spec>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.UpdateEnvironmentRequest.Spec";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "content", kind: "message", T: UpdateEnvironmentRequest_Content, opt: true },
    { no: 3, name: "ssh_public_keys", kind: "message", T: UpdateEnvironmentRequest_SSHPublicKey, repeated: true },
    { no: 4, name: "devcontainer", kind: "message", T: UpdateEnvironmentRequest_DevContainer, opt: true },
    { no: 5, name: "ports", kind: "message", T: EnvironmentSpec_EnvironmentPort, repeated: true },
    { no: 6, name: "timeout", kind: "message", T: UpdateEnvironmentRequest_Timeout, opt: true },
    { no: 7, name: "automations_file", kind: "message", T: UpdateEnvironmentRequest_AutomationsFile, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateEnvironmentRequest_Spec {
    return new UpdateEnvironmentRequest_Spec().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateEnvironmentRequest_Spec {
    return new UpdateEnvironmentRequest_Spec().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateEnvironmentRequest_Spec {
    return new UpdateEnvironmentRequest_Spec().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateEnvironmentRequest_Spec | PlainMessage<UpdateEnvironmentRequest_Spec> | undefined, b: UpdateEnvironmentRequest_Spec | PlainMessage<UpdateEnvironmentRequest_Spec> | undefined): boolean {
    return proto3.util.equals(UpdateEnvironmentRequest_Spec, a, b);
  }
}

/**
 * @generated from message gitpod.v1.UpdateEnvironmentResponse
 */
export class UpdateEnvironmentResponse extends Message<UpdateEnvironmentResponse> {
  constructor(data?: PartialMessage<UpdateEnvironmentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.UpdateEnvironmentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateEnvironmentResponse {
    return new UpdateEnvironmentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateEnvironmentResponse {
    return new UpdateEnvironmentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateEnvironmentResponse {
    return new UpdateEnvironmentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateEnvironmentResponse | PlainMessage<UpdateEnvironmentResponse> | undefined, b: UpdateEnvironmentResponse | PlainMessage<UpdateEnvironmentResponse> | undefined): boolean {
    return proto3.util.equals(UpdateEnvironmentResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.DeleteEnvironmentRequest
 */
export class DeleteEnvironmentRequest extends Message<DeleteEnvironmentRequest> {
  /**
   * environment_id specifies the environment that is going to delete.
   *
   * +required
   *
   * @generated from field: string environment_id = 1;
   */
  environmentId = "";

  /**
   * force indicates whether the environment should be deleted forcefully
   * When force deleting an Environment, the Environment is removed immediately and
   * environment lifecycle is not respected.
   * Force deleting can result in data loss on the environment.
   *
   * @generated from field: bool force = 2;
   */
  force = false;

  constructor(data?: PartialMessage<DeleteEnvironmentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.DeleteEnvironmentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "environment_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "force", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteEnvironmentRequest {
    return new DeleteEnvironmentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteEnvironmentRequest {
    return new DeleteEnvironmentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteEnvironmentRequest {
    return new DeleteEnvironmentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteEnvironmentRequest | PlainMessage<DeleteEnvironmentRequest> | undefined, b: DeleteEnvironmentRequest | PlainMessage<DeleteEnvironmentRequest> | undefined): boolean {
    return proto3.util.equals(DeleteEnvironmentRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.DeleteEnvironmentResponse
 */
export class DeleteEnvironmentResponse extends Message<DeleteEnvironmentResponse> {
  constructor(data?: PartialMessage<DeleteEnvironmentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.DeleteEnvironmentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteEnvironmentResponse {
    return new DeleteEnvironmentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteEnvironmentResponse {
    return new DeleteEnvironmentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteEnvironmentResponse {
    return new DeleteEnvironmentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteEnvironmentResponse | PlainMessage<DeleteEnvironmentResponse> | undefined, b: DeleteEnvironmentResponse | PlainMessage<DeleteEnvironmentResponse> | undefined): boolean {
    return proto3.util.equals(DeleteEnvironmentResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.CreateEnvironmentLogsTokenRequest
 */
export class CreateEnvironmentLogsTokenRequest extends Message<CreateEnvironmentLogsTokenRequest> {
  /**
   * environment_id specifies the environment for which the logs token should be created.
   *
   * +required
   *
   * @generated from field: string environment_id = 1;
   */
  environmentId = "";

  constructor(data?: PartialMessage<CreateEnvironmentLogsTokenRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.CreateEnvironmentLogsTokenRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "environment_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateEnvironmentLogsTokenRequest {
    return new CreateEnvironmentLogsTokenRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateEnvironmentLogsTokenRequest {
    return new CreateEnvironmentLogsTokenRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateEnvironmentLogsTokenRequest {
    return new CreateEnvironmentLogsTokenRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateEnvironmentLogsTokenRequest | PlainMessage<CreateEnvironmentLogsTokenRequest> | undefined, b: CreateEnvironmentLogsTokenRequest | PlainMessage<CreateEnvironmentLogsTokenRequest> | undefined): boolean {
    return proto3.util.equals(CreateEnvironmentLogsTokenRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.CreateEnvironmentLogsTokenResponse
 */
export class CreateEnvironmentLogsTokenResponse extends Message<CreateEnvironmentLogsTokenResponse> {
  /**
   * access_token is the token that can be used to access the logs of the environment
   *
   * @generated from field: string access_token = 1;
   */
  accessToken = "";

  constructor(data?: PartialMessage<CreateEnvironmentLogsTokenResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.CreateEnvironmentLogsTokenResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "access_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateEnvironmentLogsTokenResponse {
    return new CreateEnvironmentLogsTokenResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateEnvironmentLogsTokenResponse {
    return new CreateEnvironmentLogsTokenResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateEnvironmentLogsTokenResponse {
    return new CreateEnvironmentLogsTokenResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateEnvironmentLogsTokenResponse | PlainMessage<CreateEnvironmentLogsTokenResponse> | undefined, b: CreateEnvironmentLogsTokenResponse | PlainMessage<CreateEnvironmentLogsTokenResponse> | undefined): boolean {
    return proto3.util.equals(CreateEnvironmentLogsTokenResponse, a, b);
  }
}

