import { EnvironmentDetailsHeader } from "@/components/environments/EnvironmentDetailsHeader";
import { EnvironmentOnboarding } from "@/components/environments/EnvironmentOnboarding";
import { EnvironmentPorts } from "@/components/environments/EnvironmentPorts";
import { EnvironmentStartSequence } from "@/components/environments/EnvironmentStartSequence";
import { TrackLocations } from "@/hooks/use-segment";
import { useEnvironment } from "@/queries/environment-queries";
import { EnvironmentAutomations } from "@/routes/environments/details/EnvironmentAutomations";
import { type FC, type PropsWithChildren } from "react";

export const EnvironmentDetails: FC<{ environmentId: string }> = ({ environmentId }) => {
    return (
        <div className="flex size-full flex-col gap-6">
            <EnvironmentDetailsHeader showActionBar={true} />
            <EnvironmentStartSequence environmentId={environmentId} />
            <WhenReady environmentId={environmentId}>
                <EnvironmentOnboarding
                    environmentId={environmentId}
                    data-track-location={TrackLocations.EnvironmentOnboarding}
                />
                <EnvironmentPorts environmentId={environmentId} />
                <EnvironmentAutomations environmentId={environmentId} />
            </WhenReady>
        </div>
    );
};

const WhenReady: FC<PropsWithChildren & { environmentId: string }> = ({ children, environmentId }) => {
    const { data: environment, isPending } = useEnvironment(environmentId);
    const ready = !isPending && !!environment;

    if (!ready) {
        return null;
    }

    return children;
};
