import type { PlainEnvironment } from "@/queries/environment-queries";
import { useGetEnvironmentClass } from "@/queries/runner-configuration-queries";
import { useRunner } from "@/queries/runner-queries";
import { RunnerKind } from "gitpod-next-api/gitpod/v1/runner_pb";

export function useEnvironmentComputeDescription(environment?: PlainEnvironment):
    | {
          runnerName?: string;
          clazz?: string;
      }
    | undefined {
    const { data: runner, isPending: isLoadingRunner } = useRunner(environment?.metadata?.runnerId);
    const { data: clazz } = useGetEnvironmentClass(environment?.spec?.machine?.class);

    if (isLoadingRunner || !environment) {
        return;
    }

    if (runner?.kind === RunnerKind.LOCAL) {
        return { runnerName: runner?.name };
    }

    if (!runner) {
        return;
    }

    return { runnerName: runner?.name, clazz: clazz?.displayName };
}
