import { LoadingState } from "@/components/podkit/loading/LoadingState";
import { useBootIntercom } from "@/hooks/use-intercom";
import { useLocalRunner } from "@/hooks/use-local-runner";
import { useSegmentIdentify } from "@/hooks/use-segment";
import { setPrincipal } from "@/principal";
import { useGetAccount } from "@/queries/account-queries";
import { useAuthenticatedUser } from "@/queries/user-queries";
import { LocalRunnerStatus } from "frontend-shared/local-runner";
import { type FC } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

export const AuthenticatedUserLayout: FC = () => {
    const localRunner = useLocalRunner();
    useBootIntercom();
    useSegmentIdentify();

    // TODO(at) this is a workaround to get the account loaded initially
    const { data: account, isPending: isLoadingAccount } = useGetAccount();

    const { data: user, isPending: isLoadingUser } = useAuthenticatedUser();
    const location = useLocation();

    // Wait for the account to be loaded
    if (!account && isLoadingAccount && localRunner.loading) {
        return <LoadingState />;
    }

    if (LocalRunnerStatus.needsRunnerScreen(localRunner.status)) {
        return <Navigate to="/local-runner" state={{ from: location }} replace />;
    }

    // Redirect to create organization if the account has no memberships
    const needsRedirectToJoinOrCreateOrg = !["/join-organization", "/create-organization"].includes(location.pathname);
    const isAccoundWithoutMemberships = account && (account.memberships || []).length === 0;
    if (isAccoundWithoutMemberships && needsRedirectToJoinOrCreateOrg) {
        setPrincipal("");
        // Redirect to join organization, if there are joinable orgs and no memberships
        if (account?.joinables && account.joinables.length > 0) {
            return <Navigate to="/join-organization" state={{ from: location }} replace />;
        }

        return <Navigate to="/create-organization" state={{ from: location }} replace />;
    }

    if (isLoadingAccount) {
        return <LoadingState />;
    }

    // Redirect to login if the user is not authenticated - maintain the current location to redirect to after login
    if (!account) {
        return (
            <Navigate
                to={"/login?redirect=" + encodeURIComponent(location.pathname + location.search + location.hash)}
                state={{ from: location }}
                replace
            />
        );
    }

    // Wait for the user to be loaded
    // but only if the account actually has memberships
    if (!isAccoundWithoutMemberships && !user && isLoadingUser) {
        return <LoadingState />;
    }

    // If we got here, there's a user and we can render the layout
    return (
        <>
            <Outlet />
        </>
    );
};
