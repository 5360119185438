import { type FC } from "react";
import { RunnerKind, RunnerPhase } from "gitpod-next-api/gitpod/v1/runner_pb";
import { Link } from "react-router-dom";
import type { PlainRunner } from "@/queries/runner-queries";
import type { PlainEnvironment } from "@/queries/environment-queries";
import { EnvironmentPhase } from "gitpod-next-api/gitpod/v1/environment_pb";
import { Banner } from "@/components/flexkit/Banner";

export const EnvironmentBanner: FC<{ environment?: PlainEnvironment; runner?: PlainRunner }> = ({
    runner,
    environment,
}) => {
    if (!runner || !environment) {
        return;
    }

    const isLocalRunner = runner.kind === RunnerKind.LOCAL;
    const isRemoteRunner = !isLocalRunner;
    const runnerIsOffline = runner.status?.phase === RunnerPhase.INACTIVE;
    const runnerIsDegraded = runner.status?.phase === RunnerPhase.DEGRADED;
    const environmentIsRunning = environment.status?.phase === EnvironmentPhase.RUNNING;
    const environmentIsStopped = environment.status?.phase === EnvironmentPhase.STOPPED;

    if (isLocalRunner && runnerIsOffline) {
        return (
            <Banner
                text="Gitpod Desktop is offline. Please open the desktop app to use this environment."
                variant="danger"
                data-testid="local-stopped-runner"
            />
        );
    }

    if (isRemoteRunner) {
        if (runnerIsOffline || runnerIsDegraded) {
            if (environmentIsStopped) {
                const text = (
                    <span>
                        This environment is experiencing technical issues. Please update the{" "}
                        <Link to={`/settings/runners/${runner.runnerId}`} className="underline">
                            runner settings
                        </Link>{" "}
                        and try again.
                    </span>
                );
                return <Banner text={text} variant="danger" data-testid="remote-stopped-environment" />;
            }
            if (environmentIsRunning) {
                const text = (
                    <span>
                        This environment is experiencing technical issues. Please update the{" "}
                        <Link to={`/settings/runners/${runner.runnerId}`} className="underline">
                            runner settings
                        </Link>
                        .
                    </span>
                );
                return <Banner text={text} variant="danger" data-testid="remote-running-environment" />;
            }
        }
    }

    return;
};
