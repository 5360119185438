import type { FC } from "react";
import { Text } from "@/components/podkit/typography/Text";
import { ExternalLink } from "@/components/podkit/typography/Link";
import { ExternalLinkIcon } from "lucide-react";
import { cn, type PropsWithClassName } from "@/components/podkit/lib/cn";

type Props = {
    icon?: React.ReactNode;
    title: string;
    text: string;
    docsUrl?: string;
    action?: React.ReactNode;
    "data-testid"?: string;
};
export const EnvironmentDetailsEmptySection: FC<PropsWithClassName<Props>> = ({
    className,
    title,
    text,
    icon,
    docsUrl,
    action,
    "data-testid": testId,
}) => {
    return (
        <div
            className={cn(
                "flex max-h-[120px] w-full flex-row items-center gap-6 rounded-xl border border-dashed border-border-base px-6 pb-8 pt-6",
                className,
            )}
            data-testid={testId}
        >
            {icon && <div className="min-w-[80px]">{icon}</div>}
            <div className="flex grow flex-col">
                <Text className="text-base font-bold text-content-primary">{title}</Text>
                <Text className="line-clamp-2 text-base text-content-tertiary">{text}</Text>
            </div>
            {docsUrl && (
                <div className="flex">
                    <ExternalLink
                        className="inline-flex gap-2 align-middle text-base text-content-yield"
                        href={docsUrl}
                    >
                        Docs <ExternalLinkIcon className="size-4 self-center" />
                    </ExternalLink>
                </div>
            )}
            {action}
        </div>
    );
};
