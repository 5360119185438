import type React from "react";
import { useCallback, useState, type FC } from "react";
import { DevContainerTemplateModal } from "@/components/environments/onboarding/DevContainerTemplateModal";
import { Heading3 } from "@/components/podkit/typography/Headings";
import { Text } from "@/components/podkit/typography/Text";
import { TemplateIllutrationPlaceholder } from "@/assets/icons/onboarding/TemplateIllustrationPlaceholder";
import { IconCheckFill } from "@/assets/icons/geist/IconCheckFill";
import { Button } from "@/components/flexkit/Button";
import { CopyableInput } from "@/components/podkit/forms/CopyableInput";
import type { PlainEnvironment } from "@/queries/environment-queries";
import { IconWarning } from "@/assets/icons/geist/IconWarning";
import {
    EnvironmentPhase,
    EnvironmentStatus_DevContainer_Phase,
    EnvironmentStatus_DevContainer_Presence,
} from "gitpod-next-api/gitpod/v1/environment_pb";
import { IconSpinner } from "@/assets/icons/geist/IconSpinner";
import { SkeletonBlock } from "@/components/podkit/loading/Skeleton";
import { IconExternalLink } from "@/assets/icons/geist/IconExternalLink";
import { ExternalLink } from "@/components/podkit/typography/Link";

export const DevContainerStep: FC<{ environment?: PlainEnvironment; completed: boolean; onNext: () => void }> = ({
    environment,
    completed,
    onNext,
}) => {
    const [showSelectTemplateModal, setShowSelectTemplateModal] = useState(false);

    const onShowModal = useCallback(() => {
        setShowSelectTemplateModal(true);
    }, []);

    const onHideModal = useCallback(() => {
        setShowSelectTemplateModal(false);
    }, []);

    const hasDevContainerdevcontainerFilePresence = !!environment?.status?.devcontainer?.devcontainerFilePresence;

    const isGenerated =
        environment?.status?.devcontainer?.devcontainerFilePresence ===
        EnvironmentStatus_DevContainer_Presence.GENERATED;

    const isStale =
        environment?.status?.phase === EnvironmentPhase.RUNNING &&
        !environment?.status?.devcontainer?.devcontainerconfigInSync;

    const isStopped = environment?.status?.phase === EnvironmentPhase.STOPPED;

    const isRebuilding =
        environment?.status?.phase === EnvironmentPhase.UPDATING &&
        environment?.status?.devcontainer?.phase === EnvironmentStatus_DevContainer_Phase.CREATING;

    let content: React.ReactNode;
    if (isStopped) {
        content = <ContentEnvironmentStopped />;
    } else if (completed) {
        content = <ContentCompleted />;
    } else if (isRebuilding) {
        content = <ContentEnvironmentRebuild />;
    } else if (isGenerated) {
        content = <ContentGenerating />;
    } else if (isStale) {
        content = <ContentOutOfSync />;
    }

    let buttonText = "Update Template";
    if (isStopped) {
        buttonText = "Show examples";
    } else if (isGenerated) {
        buttonText = "Start from template";
    }

    return (
        <>
            <div className="mt-8 flex flex-col items-center justify-between gap-4 overflow-hidden px-5 md:flex-row">
                <div className="flex grow flex-col items-center gap-4 md:flex-row">
                    <TemplateIllutrationPlaceholder />
                    <SkeletonBlock className="h-12 grow" ready={!!content}>
                        <div className="flex flex-col">{content}</div>
                    </SkeletonBlock>
                </div>
                <div className="flex flex-col gap-4 lg:flex-row">
                    <ExternalLink
                        href="https://www.gitpod.io/docs/flex/configuration/devcontainer"
                        className="flex items-center gap-2 text-content-orange"
                    >
                        Docs
                        <IconExternalLink size="sm" />
                    </ExternalLink>
                    <SkeletonBlock className="h-8 w-40" ready={isStopped || hasDevContainerdevcontainerFilePresence}>
                        <Button variant="secondary" onClick={onShowModal} data-track-label="true">
                            {buttonText}
                        </Button>
                        {completed && (
                            <Button variant="primary" onClick={onNext} data-track-label="true">
                                Next
                            </Button>
                        )}
                    </SkeletonBlock>
                </div>
            </div>
            {showSelectTemplateModal && <DevContainerTemplateModal onClose={onHideModal} />}
        </>
    );
};

const ContentEnvironmentStopped: FC = () => {
    return (
        <div className="flex flex-col gap-2">
            <Heading3 className="text-base font-bold">
                Start your environment to see the status of your dev container
            </Heading3>
            <Text className="text-sm text-content-secondary">
                Make sure to add a base image to your configuration as well as any editor extensions.
            </Text>
        </div>
    );
};

const ContentGenerating: FC = () => {
    return (
        <div className="flex flex-col gap-2">
            <Heading3 className="text-base font-bold">
                We are creating a dev container file for you to customize
            </Heading3>
            <Text className="text-sm text-content-secondary">
                Make sure to add a base image to your configuration as well as any editor extensions.
            </Text>
        </div>
    );
};

const ContentOutOfSync: FC = () => {
    return (
        <div className="flex flex-col gap-2">
            <Heading3 className="flex items-center gap-1 text-base font-bold">
                <IconWarning className="text-content-orange" size="base" />
                Dev container file is out of sync
            </Heading3>
            <div className="flex flex-col items-start gap-2">
                <Text className="text-sm text-content-secondary">
                    The dev container file is out of sync with the current environment. Run the following commands in
                    your environment to rebuild the dev container:
                </Text>
                <CopyableInput className="min-w-[260px]" value="gitpod env devcontainer rebuild" copyable />
            </div>
        </div>
    );
};

const ContentEnvironmentRebuild: FC = () => {
    return (
        <div className="flex flex-col gap-2">
            <Heading3 className="flex items-center gap-1 text-base font-bold">
                <IconSpinner size="base" className="animate-spin text-content-orange" />
                Rebuilding dev container
            </Heading3>
            <div className="flex flex-col items-start gap-2">
                <Text className="text-sm text-content-secondary">Dev container is rebuilding.</Text>
            </div>
        </div>
    );
};

const ContentCompleted: FC = () => {
    return (
        <div className="flex flex-col gap-2">
            <Heading3 className="flex items-center gap-1 text-base font-bold">
                <IconCheckFill className="text-content-green" size="lg" />
                Dev container file added
                <Text />
            </Heading3>
            <div className="flex items-center gap-1">
                <Text className="text-sm font-bold text-content-secondary">File path:</Text>
                <Text className="text-sm text-content-secondary">.devcontainer/devcontainer.json</Text>
            </div>
            <Text className="text-sm text-content-secondary">
                Make sure to add a base image to your configuration as well as any other tools or editor extensions.
            </Text>
        </div>
    );
};
