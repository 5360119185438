import type { PlainEnvironment } from "@/queries/environment-queries";
import { isSameSession } from "@/routes/environments/phase";
import {
    EnvironmentPhase,
    EnvironmentStatus_AutomationsFile_Presence,
    EnvironmentStatus_ContentPhase,
    EnvironmentStatus_DevContainer_Phase,
    EnvironmentStatus_Machine_Phase,
} from "gitpod-next-api/gitpod/v1/environment_pb";

export enum StepState {
    Waiting = "waiting",
    Running = "running",
    Success = "success",
    Failure = "failure",
    Empty = "empty",
}

export function stepStartState(environment: PlainEnvironment): StepState {
    if (isSameSession(environment) && environment.status?.machine?.failureMessage) {
        return StepState.Failure;
    }

    if (isEnvironmentWaiting(environment) || isStaleMachineSession(environment)) {
        return StepState.Waiting;
    }

    if (
        environment.status?.machine?.session &&
        environment.spec?.machine?.session &&
        environment.spec?.machine?.session !== environment.status?.machine?.session
    ) {
        return StepState.Waiting;
    }

    switch (environment.status?.machine?.phase) {
        case EnvironmentStatus_Machine_Phase.UNSPECIFIED:
        case EnvironmentStatus_Machine_Phase.STOPPING:
        case EnvironmentStatus_Machine_Phase.STOPPED:
        case EnvironmentStatus_Machine_Phase.DELETING:
        case EnvironmentStatus_Machine_Phase.DELETED:
            return StepState.Waiting;
        case EnvironmentStatus_Machine_Phase.CREATING:
        case EnvironmentStatus_Machine_Phase.STARTING:
            return StepState.Running;
        case EnvironmentStatus_Machine_Phase.RUNNING:
            return StepState.Success;
        default:
            return StepState.Waiting;
    }
}

export function stepCloneState(environment: PlainEnvironment): StepState {
    if (isSameSession(environment) && environment.status?.content?.failureMessage) {
        return StepState.Failure;
    }

    if (isEnvironmentWaiting(environment) || isStaleMachineSession(environment) || !isMachineRunning(environment)) {
        return StepState.Waiting;
    }

    const hasContentStatus = !!environment.status?.content;
    if (
        environment.spec?.content?.session &&
        hasContentStatus &&
        environment.spec.content.session !== environment.status?.content?.session
    ) {
        // spec.content.session is set if the environment is being re-used.
        // While status.content.session doesn't match spec.content.session the information in status.content is stale.
        // In this case we know the system will eventually update status.content so we optimistically show the step as running
        return StepState.Running;
    }

    // HACK(cw): we don't correctly propagate the status from the environment, and hence can hang in this intermediate state where
    // the machine is running but nothing else happens. Let's fake it 'till we make it.
    if (!hasContentStatus && isMachineRunning(environment)) {
        return StepState.Running;
    }

    switch (environment.status?.content?.phase) {
        case EnvironmentStatus_ContentPhase.CREATING:
        case EnvironmentStatus_ContentPhase.UPDATING:
        case EnvironmentStatus_ContentPhase.INITIALIZING:
            return StepState.Running;
        case EnvironmentStatus_ContentPhase.READY:
            return StepState.Success;
        case EnvironmentStatus_ContentPhase.FAILED:
            return StepState.Failure;
        default:
            return StepState.Waiting;
    }
}

export function stepDevcontainerState(environment: PlainEnvironment): { state: StepState; canRebuild: boolean } {
    if (isSameSession(environment) && environment.status?.devcontainer?.failureMessage) {
        return { state: StepState.Failure, canRebuild: false };
    }

    if (isEnvironmentWaiting(environment) || isStaleMachineSession(environment) || !isMachineRunning(environment)) {
        return { state: StepState.Waiting, canRebuild: false };
    }

    if (
        environment.spec?.devcontainer?.session &&
        environment.spec.devcontainer.session !== environment.status?.devcontainer?.session
    ) {
        // spec.devcontainer.session is set when a devcontainer rebuild is triggered.
        // While status.devcontainer.session doesn't match spec.devcontainer.session the information in status.devcontainer is stale.
        // In this case we know the system will eventually update status.devcontainer so we optimistically show the step as running
        return { state: StepState.Running, canRebuild: false };
    }

    const hasDevContainerStatus = !!environment.status?.devcontainer;
    const isContentReady = environment.status?.content?.phase === EnvironmentStatus_ContentPhase.READY;
    const isMachineReady = environment.status?.machine?.phase === EnvironmentStatus_Machine_Phase.RUNNING;
    if (!hasDevContainerStatus && isContentReady && isMachineReady) {
        // If the environment is running and the devcontainer status is missing we can assume the devcontainer is running.
        return { state: StepState.Running, canRebuild: false };
    }

    switch (environment.status?.devcontainer?.phase) {
        case EnvironmentStatus_DevContainer_Phase.CREATING:
            return { state: StepState.Running, canRebuild: false };
        case EnvironmentStatus_DevContainer_Phase.RUNNING:
            return {
                state: StepState.Success,
                canRebuild: !(environment?.status?.devcontainer?.devcontainerconfigInSync ?? true),
            };
        case EnvironmentStatus_DevContainer_Phase.FAILED:
            return { state: StepState.Failure, canRebuild: false };
        default:
            return { state: StepState.Waiting, canRebuild: false };
    }
}

export function stepAutomationsState(environment: PlainEnvironment): StepState {
    if (isSameSession(environment) && environment.status?.automationsFile?.failureMessage) {
        return StepState.Failure;
    }

    if (isEnvironmentWaiting(environment) || isStaleMachineSession(environment)) {
        return StepState.Waiting;
    }

    if (
        environment.spec?.automationsFile?.session &&
        environment.status?.automationsFile?.session &&
        environment.spec.automationsFile.session !== environment.status?.automationsFile?.session
    ) {
        return StepState.Waiting;
    }

    if (
        environment.status?.automationsFile?.automationsFilePresence ===
        EnvironmentStatus_AutomationsFile_Presence.ABSENT
    ) {
        return StepState.Empty;
    }

    const hasAutomationsFileStatus = !!environment.status?.automationsFile;
    const hasAutomationsFileSpec = !!environment.spec?.automationsFile;
    const isContentReady = environment.status?.content?.phase === EnvironmentStatus_ContentPhase.READY;
    const isMachineReady = environment.status?.machine?.phase === EnvironmentStatus_Machine_Phase.RUNNING;
    if (hasAutomationsFileSpec && !hasAutomationsFileStatus && isContentReady && isMachineReady) {
        // If the environment is running, the automationsFile status is missing, but spec is present
        // we can assume the automation to be running.
        return StepState.Running;
    }

    switch (environment.status?.automationsFile?.phase) {
        case EnvironmentStatus_ContentPhase.CREATING:
        case EnvironmentStatus_ContentPhase.UPDATING:
        case EnvironmentStatus_ContentPhase.INITIALIZING:
            return StepState.Running;
        case EnvironmentStatus_ContentPhase.READY:
            return StepState.Success;
        case EnvironmentStatus_ContentPhase.FAILED:
            return StepState.Failure;
        default:
            return StepState.Waiting;
    }
}

export function isStaleMachineSession(environment: PlainEnvironment): boolean {
    return (
        Boolean(environment.status?.machine?.session) &&
        Boolean(environment.spec?.machine?.session) &&
        environment.spec?.machine?.session !== environment.status?.machine?.session
    );
}

function isMachineRunning(environment: PlainEnvironment): boolean {
    return environment.status?.machine?.phase === EnvironmentStatus_Machine_Phase.RUNNING;
}

function isEnvironmentWaiting(environment: PlainEnvironment): boolean {
    if (!environment.status) {
        return true;
    }

    switch (environment.status.phase) {
        case EnvironmentPhase.UNSPECIFIED:
        case EnvironmentPhase.STOPPING:
        case EnvironmentPhase.STOPPED:
        case EnvironmentPhase.DELETING:
        case EnvironmentPhase.DELETED:
            return true;
    }

    return false;
}
