import { IconElipseHorizontal } from "@/assets/icons/geist/IconEllipseHorizontal";
import { IconPort } from "@/assets/icons/geist/IconPort";
import { IconSSH } from "@/assets/icons/geist/IconSSH";
import { IconVSCode } from "@/assets/icons/geist/IconVSCode";
import { OpenPortPopover } from "@/components/environments/OpenPortForm";
import { WithPopover } from "@/components/environments/WithPopover";
import { Button } from "@/components/flexkit/Button";
import { CopyableInput } from "@/components/podkit/forms/CopyableInput";
import { cn, type PropsWithClassName } from "@/components/podkit/lib/cn";
import { Heading2 } from "@/components/podkit/typography/Headings";
import { ExternalLink } from "@/components/podkit/typography/Link";
import { Text } from "@/components/podkit/typography/Text";
import { useOpenEditor } from "@/hooks/use-open-editor";
import { TrackLocations } from "@/hooks/use-segment";
import { useEnvironment, type PlainEnvironment } from "@/queries/environment-queries";
import { EnvironmentActionsDropdown } from "@/routes/environments/details/EnvironmentActionsDropdown";
import { canOpen } from "@/routes/environments/phase";
import {
    forwardRef,
    useEffect,
    useMemo,
    useRef,
    useState,
    type FC,
    type HTMLAttributes,
    type PropsWithChildren,
} from "react";

export const ActionBar: FC<{ environmentId: string }> = ({ environmentId }) => {
    const { data: environment } = useEnvironment(environmentId);

    return (
        <div className="items-center justify-center" data-testid="action-bar">
            {environment && (
                <div className="animate-in fade-in slide-in-from-top" translate="no">
                    <Island environment={environment} />
                </div>
            )}
        </div>
    );
};

const Island: FC<PropsWithClassName & { environment: PlainEnvironment }> = ({ environment, className }) => {
    const anchor = useRef<HTMLDivElement>(null);
    const handleOpenVsCode = useOpenEditor(environment);
    const [open, setOpen] = useState(false);
    const openable = useMemo(() => {
        return !!environment && canOpen(environment);
    }, [environment]);

    useEffect(() => {
        setOpen((current) => {
            if (current !== openable) {
                return openable;
            }
            return current;
        });
    }, [openable]);

    useEffect(() => {
        setOpen(!!environment && canOpen(environment));
    }, [environment]);

    return (
        <>
            <div
                ref={anchor}
                data-testid="action-bar-island"
                className={cn(
                    "inline-flex h-12 items-center justify-center gap-1 rounded-xl border-[0.5px] border-border-base bg-surface-pure px-1.5 py-4 shadow shadow-black/10",
                    className,
                )}
            >
                {open && !window.isWebview && (
                    <IslandButton
                        label="Open VS Code"
                        tooltip="Requires VS Code"
                        onClick={handleOpenVsCode}
                        data-testid="open-in-vs-code"
                    >
                        <IconVSCode size="sm" />
                    </IslandButton>
                )}
                {open && (
                    <WithPopover
                        anchor={anchor}
                        button={
                            <IslandButton label="SSH" data-testid="open-ssh">
                                <IconSSH size="sm" />
                            </IslandButton>
                        }
                        data-track-location={TrackLocations.EnvironmentSSHFormPopover}
                    >
                        <SSHForm environment={environment} />
                    </WithPopover>
                )}
                {open && (
                    <OpenPortPopover
                        anchor={anchor}
                        button={
                            <IslandButton label="Open Port" data-testid="open-public-ports">
                                <IconPort size="sm" />
                            </IslandButton>
                        }
                        environmentId={environment.id}
                    />
                )}
                <EnvironmentActionsDropdown
                    environment={environment}
                    button={
                        <Button
                            variant="ghost"
                            className="rounded-[8px] border-0 hover:bg-surface-invert hover:text-content-invert"
                            data-testid="open-more-actions"
                        >
                            <IconElipseHorizontal size="base" className={cn("size-6 p-0.5")} />
                        </Button>
                    }
                />
            </div>
        </>
    );
};

const IslandButton = forwardRef<
    HTMLButtonElement,
    HTMLAttributes<HTMLButtonElement> &
        PropsWithClassName &
        PropsWithChildren & {
            label: string;
            tooltip?: string;
        }
>(({ label, children, className, ...props }, ref) => {
    return (
        <button
            ref={ref}
            className={cn(
                "flex items-center justify-start gap-1 rounded-[8px] p-2 hover:bg-surface-invert hover:text-content-invert",
                className,
            )}
            {...props}
        >
            {children}
            <div className="text-sm font-medium leading-tight">{label}</div>
        </button>
    );
});
IslandButton.displayName = "IslandButton";

type SSHFormProps = {
    environment: PlainEnvironment;
};
const SSHForm: FC<SSHFormProps> = ({ environment }) => {
    return (
        <div className="flex w-[500px] flex-col gap-4 p-5">
            <div>
                <Heading2>Connect via SSH</Heading2>
                <Text muted className="text-base">
                    How to connect via SSH from your local machine using the CLI
                </Text>
            </div>
            <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-1">
                    <p className="font-bold">Setup SSH host config</p>
                    <CopyableInput value={"gitpod env ssh-config"} />
                </div>
                <div className="flex flex-col gap-1">
                    <p className="font-bold">Connect to devcontainer</p>
                    <CopyableInput value={`ssh ${environment.id}.gitpod.environment`} />
                </div>
            </div>
            <div className="-mx-1 my-1 h-px bg-content-tertiary bg-content-tertiary/20" />
            <p className="text-sm text-content-secondary">Documentation</p>
            <div className="flex flex-col gap-2">
                <div>
                    <ExternalLink className="" href="https://www.gitpod.io/docs/flex/reference/cli">
                        Install the CLI
                    </ExternalLink>
                </div>
                <div>
                    <ExternalLink href="https://www.gitpod.io/docs/flex/editors/jetbrains">
                        Connect via JetBrains Gateway
                    </ExternalLink>
                </div>
                <div>
                    <ExternalLink href="https://www.gitpod.io/docs/flex/reference/cli#ssh">
                        SSH Configuration
                    </ExternalLink>
                </div>
            </div>
        </div>
    );
};
