import { IconBitbucket } from "@/assets/icons/geist/IconBitbucket";
import { IconGit } from "@/assets/icons/geist/IconGit";
import { IconGitHub } from "@/assets/icons/geist/IconGitHub";
import { IconGitLab } from "@/assets/icons/geist/IconGitLab";
import type { Size } from "@/assets/icons/geist/Size";
import type { PropsWithClassName } from "@/components/podkit/lib/cn";
import type { FC } from "react";

export const SourceControlProviderIcon: FC<{ scmId?: string; size?: Size } & PropsWithClassName> = ({ scmId, className, size }) => {
    const iconSize = size || "lg";
    switch (scmId) {
        case "github": {
            return <IconGitHub size={iconSize} className={className} />;
        }
        case "gitlab": {
            return <IconGitLab size={iconSize} className={className} />;
        }
        case "bitbucket": {
            return <IconBitbucket size={iconSize} className={className} />;
        }
        default: {
            return <IconGit size={iconSize} className={className} />;
        }
    }
};
