import { IconCheck } from "@/assets/icons/geist/IconCheck";
import { IconPlay } from "@/assets/icons/geist/IconPlay";
import { IconSpinner } from "@/assets/icons/geist/IconSpinner";
import { IconWarning } from "@/assets/icons/geist/IconWarning";
import { Button } from "@/components/flexkit/Button";
import { cn } from "@/components/podkit/lib/cn";
import { useToast } from "@/components/podkit/toasts/use-toast";
import { Text } from "@/components/podkit/typography/Text";
import { timeAgo } from "@/format/time";
import { useCallbackPreventDefault } from "@/hooks/use-callback-prevent-default";
import { useStartTask, type PlainTask, type PlainTaskExecution } from "@/queries/automations-queries";
import { formatError } from "@/utils/errors";
import { Timestamp, type PlainMessage } from "@bufbuild/protobuf";
import { TaskExecutionPhase, type AutomationTrigger } from "gitpod-next-api/gitpod/v1/environment_automation_pb";
import { useMemo, type FC } from "react";
import { Link, useNavigate } from "react-router-dom";

export const TaskCard: FC<{ task: PlainTask; runs: PlainTaskExecution[]; disabled?: boolean }> = ({
    task,
    runs,
    disabled,
}) => {
    const navigate = useNavigate();

    const startTask = useStartTask();
    const { toast } = useToast();
    const lastRun = useMemo(() => {
        return runs
            .filter((r) => r.metadata?.taskId === task.id)
            .sort((a, b) => Number(a?.metadata?.createdAt?.seconds || 0) - Number(b?.metadata?.createdAt?.seconds || 0))
            .pop();
    }, [runs, task.id]);
    const lastRunTimestamp = lastRun?.metadata?.createdAt;
    const lastRunTime = lastRunTimestamp && timeAgo(new Timestamp(lastRunTimestamp).toDate());

    const onRunTask = useCallbackPreventDefault(async () => {
        try {
            const run = await startTask.mutateAsync(task.id);
            const runLog = `/details/${run.metadata?.environmentId}/task/${run.metadata?.taskId}/run/${run.id}`;
            const handle = toast({
                title: <>Task started</>,
                action: (
                    <Button
                        onClick={() => {
                            navigate(runLog);
                            handle.dismiss();
                        }}
                        data-track-label="true"
                    >
                        View Logs
                    </Button>
                ),
            });
        } catch (error) {
            toast({ title: "Failed to start task", description: formatError(error) });
        }
    }, [navigate, startTask, task.id, toast]);

    const content: React.ReactNode = (
        <div
            className={cn(
                "flex flex-col items-start gap-3 rounded-xl border-[0.5px] border-border-base bg-surface-glass px-5 py-4",
                "transition-shadow duration-200 hover:shadow",
            )}
            data-testid={`task-card-${task.id}`}
        >
            <div className="flex min-h-7 w-full items-center justify-between gap-2">
                {task.metadata?.triggeredBy.some((t) => t.trigger.case == "manual") && (
                    <Button
                        LeadingIcon={IconPlay}
                        disabled={disabled}
                        loading={startTask.isPending}
                        size="sm"
                        variant="secondary"
                        onClick={onRunTask}
                        data-track-label="true"
                    >
                        Run
                    </Button>
                )}
                <Triggers triggers={task.metadata?.triggeredBy || []} />
                <div className="flex grow flex-row items-center justify-end gap-2">
                    <div className="flex items-center justify-center">{phaseIcon(lastRun?.status?.phase)}</div>
                    <div className="text-end text-sm text-content-tertiary">{lastRunTime || ""}</div>
                </div>
            </div>

            <div className="flex w-full min-w-0 flex-col">
                <Text className="truncate text-base font-bold text-content-primary">{task.metadata?.name}</Text>
                <Text
                    className="line-clamp-2 min-h-[32px] text-sm text-content-secondary"
                    title={task.metadata?.description || "Automated task"}
                >
                    {task.metadata?.description || "Automated task"}
                </Text>
            </div>
        </div>
    );

    const clickable = lastRun !== undefined;
    return clickable ? (
        <Link
            to={`/details/${lastRun?.metadata?.environmentId}/task/${lastRun?.metadata?.taskId}/run/${lastRun?.id}`}
            draggable={false}
            data-testid={`task-card-${task.id}-link`}
        >
            {content}
        </Link>
    ) : (
        content
    );
};

const Triggers: FC<{ triggers: PlainMessage<AutomationTrigger>[] }> = ({ triggers }) => {
    const labels = triggers
        .filter((trigger) => trigger.trigger.case !== "manual")
        .map(triggerLabel)
        .join(", ");
    return (
        <Text className="truncate text-sm font-bold uppercase text-content-tertiary" title={labels}>
            {labels}
        </Text>
    );
};

function triggerLabel(trigger: PlainMessage<AutomationTrigger>) {
    switch (trigger.trigger.case) {
        case "manual":
            return "Manual";
        case "postDevcontainerStart":
            return "Post dev container start";
        case "postEnvironmentStart":
            return "Post start";
    }
}

function phaseIcon(phase?: TaskExecutionPhase) {
    switch (phase) {
        case TaskExecutionPhase.SUCCEEDED:
            return <IconCheck size="sm" className="text-content-green" />;
        case TaskExecutionPhase.FAILED:
            return <IconWarning size="sm" className="text-content-negative" />;
        case TaskExecutionPhase.RUNNING:
            return <IconSpinner size="sm" className="animate-spin text-content-yield" />;
        case TaskExecutionPhase.PENDING:
            return <IconSpinner size="sm" className="animate-spin" />;
        default:
            return;
    }
}
