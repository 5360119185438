import { useContext } from "react";
import { OnboardingContext } from "@/contexts/OnboardingContext";

export enum OnboardingStepId {
    HowGitpodWorks = "how-gitpod-works",
    SetUpARunner = "set-up-a-runner",
    AutomateYourDevEnvironment = "automate-your-dev-environment",
    ShareGoldenPathWithYourTeam = "share-golden-path-with-your-team",
}

export type OrganizationOnboardingStep = {
    id: OnboardingStepId;
    title: string;
    completed: boolean;
};

export type OrganizationOnboardingSteps = {
    isLoading: boolean;
    steps: OrganizationOnboardingStep[];
    defaultActive: OnboardingStepId;
};

export enum OnboardingSetupRunnerStepId {
    ChoseRunner = "choseRunner",
    SelectProvider = "selectProvider",
    ConfigureInfrastructure = "configureInfrastructure",
}

export const useOnboarding = () => {
    const ctx = useContext(OnboardingContext);

    if (!ctx) {
        throw new Error("useOnboarding must be used within a OnboardingProvider");
    }

    return ctx;
};
