import { Heading3 } from "@/components/podkit/typography/Headings";
import { Text } from "@/components/podkit/typography/Text";
import { AutomationsTemplateModal } from "@/components/environments/onboarding/AutomationsTemplateModal";
import { useCallback, useState, type FC } from "react";
import { TemplateIllutrationPlaceholder } from "@/assets/icons/onboarding/TemplateIllustrationPlaceholder";
import { IconCheckFill } from "@/assets/icons/geist/IconCheckFill";
import { Button } from "@/components/flexkit/Button";
import { IconExternalLink } from "@/assets/icons/geist/IconExternalLink";
import { ExternalLink } from "@/components/podkit/typography/Link";

export const AutomationsStep: FC<{ completed: boolean; onNext: () => void }> = ({ completed, onNext }) => {
    const [showSelectTemplateModal, setShowSelectTemplateModal] = useState(false);

    const onShowModal = useCallback(() => {
        setShowSelectTemplateModal(true);
    }, []);

    const onHideModal = useCallback(() => {
        setShowSelectTemplateModal(false);
    }, []);

    return (
        <>
            <div className="mt-8 flex flex-col items-center justify-between gap-4 overflow-hidden px-5 md:flex-row">
                <div className="flex flex-col items-center gap-4 md:flex-row">
                    <TemplateIllutrationPlaceholder />
                    <div className="flex shrink flex-col">{completed ? <ContentCompleted /> : <Content />}</div>
                </div>
                <div className="flex flex-col gap-4 lg:flex-row">
                    <ExternalLink
                        href="https://www.gitpod.io/docs/flex/configuration/automations"
                        className="flex items-center gap-2 text-content-orange"
                    >
                        Docs
                        <IconExternalLink size="sm" />
                    </ExternalLink>
                    <Button variant="secondary" onClick={onShowModal} data-track-label="true">
                        {completed ? "Update Template" : "Show example"}
                    </Button>
                    {completed && (
                        <Button variant="primary" onClick={onNext} data-track-label="true">
                            Next
                        </Button>
                    )}
                </div>
            </div>
            {showSelectTemplateModal && <AutomationsTemplateModal onClose={onHideModal} />}
        </>
    );
};

const Content: FC = () => {
    return (
        <div className="flex max-w-2xl flex-col gap-2">
            <Heading3 className="text-base font-bold">Create an automations.yaml to run tasks and services</Heading3>
            <Text className="text-sm text-content-secondary">
                Automations are reusable self-serve actions for automating common development workflows like seeding
                databases, provisioning infra, and configuring code assistants. Automations are defined in{" "}
                <span className="font-mono">.gitpod/automations.yaml</span>.
            </Text>
        </div>
    );
};

const ContentCompleted: FC = () => {
    return (
        <div className="flex flex-col gap-2">
            <Heading3 className="flex items-center gap-1 text-base font-bold">
                <IconCheckFill className="text-content-green" size="lg" />
                Automations file added
            </Heading3>
            <div className="flex items-center gap-1">
                <Text className="text-sm font-bold text-content-secondary">File path:</Text>
                <Text className="text-sm text-content-secondary">.gitpod/automations.yaml</Text>
            </div>
        </div>
    );
};
