import {
    templateInstructions,
    templates,
    type DevContainerTemplate,
} from "@/components/environments/onboarding/dev-container-template";
import {
    Dialog,
    DialogBody,
    DialogClose,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogDescription,
} from "@/components/podkit/modal/Modal";
import { Text } from "@/components/podkit/typography/Text";
import { ExternalLink } from "@/components/podkit/typography/Link";
import { useCallback, useState, type FC } from "react";
import { CopyableTextArea } from "@/components/podkit/forms/CopyableTextArea";
import { Button } from "@/components/flexkit/Button";
import { TrackLocations } from "@/hooks/use-segment";

export const DevContainerTemplateModal: FC<{ onClose: () => void }> = ({ onClose }) => {
    const [selectedTemplate, setSelectedTemplate] = useState<DevContainerTemplate | null>(null);

    const handleOpenChange = useCallback(
        (nextOpen: boolean) => {
            if (!nextOpen) {
                onClose();
            }
        },
        [onClose],
    );

    const onSelectTemplate = useCallback((template: DevContainerTemplate) => {
        setSelectedTemplate(template);
    }, []);

    const onBack = useCallback(() => {
        setSelectedTemplate(null);
    }, []);

    return (
        <Dialog open onOpenChange={handleOpenChange}>
            <DialogContent
                className="max-w-[600px]"
                data-track-location={TrackLocations.EnvironmentOnboardingDevContainerTemplateModal}
            >
                <DialogHeader>
                    <DialogTitle>
                        {selectedTemplate ? selectedTemplate.title : "Choose your dev container template"}
                    </DialogTitle>
                    <DialogDescription>Install your tools by adding a dev container file</DialogDescription>
                </DialogHeader>

                <DialogBody className="max-w-full overflow-hidden">
                    {selectedTemplate ? (
                        <TemplateContent template={selectedTemplate} />
                    ) : (
                        <TemplateList onSelectTemplate={onSelectTemplate} />
                    )}
                </DialogBody>

                <DialogFooter className="sm:justify-end">
                    {selectedTemplate && (
                        <Button type="button" variant="secondary" onClick={onBack} data-track-label="true">
                            Back
                        </Button>
                    )}
                    <DialogClose asChild>
                        <Button type="button" variant="secondary" data-track-label="true">
                            Close
                        </Button>
                    </DialogClose>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

const TemplateList: FC<{ onSelectTemplate: (template: DevContainerTemplate) => void }> = ({ onSelectTemplate }) => {
    return (
        <div className="flex flex-col gap-2">
            {Object.values(templates).map((template) => (
                <Button
                    key={template.id}
                    variant="secondary"
                    onClick={() => onSelectTemplate(template)}
                    data-track-label="true"
                >
                    {template.title}
                </Button>
            ))}
        </div>
    );
};

const TemplateContent: FC<{ template: DevContainerTemplate }> = ({ template }) => {
    const inst = templateInstructions(template);
    return (
        <div className="flex flex-col gap-4">
            <Text>
                To get started with <span className="font-bold">{template.title}</span> you can use the{" "}
                <span className="font-mono">Dockerfile</span> and <span className="font-mono">devcontainer.json</span>{" "}
                files from{" "}
                <ExternalLink href="https://github.com/devcontainers/images">
                    github.com/devcontainers/images
                </ExternalLink>{" "}
                using the instrucitons below.
            </Text>
            <Text>The instructions should be executed in your Gitpod environment.</Text>
            <CopyableTextArea value={inst} className="min-h-[240px]" copyable />
        </div>
    );
};
