import { runnerMainService } from "@/ipc";
import { type LocalRunnerStatus } from "frontend-shared/local-runner";
import { useEffect, useState } from "react";

export const useLocalRunner = () => {
    const [status, setStatus] = useState<LocalRunnerStatus | undefined>(undefined);
    useEffect(() => runnerMainService?.onRunnerStatus(setStatus), []);

    if (!window.ipcRenderer) {
        return {
            loading: false,
        };
    }

    return {
        loading: !status,
        status,
    };
};
