// @generated by protoc-gen-es v1.8.0 with parameter "target=ts"
// @generated from file gitpod/v1/environment_automation.proto (package gitpod.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { Subject } from "./identity_pb.js";
import { PaginationRequest, PaginationResponse } from "./pagination_pb.js";

/**
 * @generated from enum gitpod.v1.ServicePhase
 */
export enum ServicePhase {
  /**
   * @generated from enum value: SERVICE_PHASE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: SERVICE_PHASE_STARTING = 1;
   */
  STARTING = 1,

  /**
   * @generated from enum value: SERVICE_PHASE_RUNNING = 2;
   */
  RUNNING = 2,

  /**
   * @generated from enum value: SERVICE_PHASE_STOPPING = 3;
   */
  STOPPING = 3,

  /**
   * @generated from enum value: SERVICE_PHASE_STOPPED = 4;
   */
  STOPPED = 4,

  /**
   * @generated from enum value: SERVICE_PHASE_FAILED = 5;
   */
  FAILED = 5,

  /**
   * @generated from enum value: SERVICE_PHASE_DELETED = 6;
   */
  DELETED = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(ServicePhase)
proto3.util.setEnumType(ServicePhase, "gitpod.v1.ServicePhase", [
  { no: 0, name: "SERVICE_PHASE_UNSPECIFIED" },
  { no: 1, name: "SERVICE_PHASE_STARTING" },
  { no: 2, name: "SERVICE_PHASE_RUNNING" },
  { no: 3, name: "SERVICE_PHASE_STOPPING" },
  { no: 4, name: "SERVICE_PHASE_STOPPED" },
  { no: 5, name: "SERVICE_PHASE_FAILED" },
  { no: 6, name: "SERVICE_PHASE_DELETED" },
]);

/**
 * @generated from enum gitpod.v1.TaskExecutionPhase
 */
export enum TaskExecutionPhase {
  /**
   * @generated from enum value: TASK_EXECUTION_PHASE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: TASK_EXECUTION_PHASE_PENDING = 1;
   */
  PENDING = 1,

  /**
   * @generated from enum value: TASK_EXECUTION_PHASE_RUNNING = 2;
   */
  RUNNING = 2,

  /**
   * @generated from enum value: TASK_EXECUTION_PHASE_SUCCEEDED = 3;
   */
  SUCCEEDED = 3,

  /**
   * @generated from enum value: TASK_EXECUTION_PHASE_FAILED = 4;
   */
  FAILED = 4,

  /**
   * @generated from enum value: TASK_EXECUTION_PHASE_STOPPED = 5;
   */
  STOPPED = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(TaskExecutionPhase)
proto3.util.setEnumType(TaskExecutionPhase, "gitpod.v1.TaskExecutionPhase", [
  { no: 0, name: "TASK_EXECUTION_PHASE_UNSPECIFIED" },
  { no: 1, name: "TASK_EXECUTION_PHASE_PENDING" },
  { no: 2, name: "TASK_EXECUTION_PHASE_RUNNING" },
  { no: 3, name: "TASK_EXECUTION_PHASE_SUCCEEDED" },
  { no: 4, name: "TASK_EXECUTION_PHASE_FAILED" },
  { no: 5, name: "TASK_EXECUTION_PHASE_STOPPED" },
]);

/**
 * @generated from message gitpod.v1.ServiceMetadata
 */
export class ServiceMetadata extends Message<ServiceMetadata> {
  /**
   * reference is a user-facing identifier for the service which must be unique on the environment.
   * It is used to express dependencies between services, and to identify the service in user interactions (e.g. the CLI).
   *
   * @generated from field: string reference = 1;
   */
  reference = "";

  /**
   * name is a user-facing name for the service. Unlike the reference, this field is not unique, and not referenced by the system.
   * This is a short descriptive name for the service.
   *
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * description is a user-facing description for the service. It can be used to provide context and documentation for the service.
   *
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * created_at is the time the service was created.
   *
   * @generated from field: google.protobuf.Timestamp created_at = 4;
   */
  createdAt?: Timestamp;

  /**
   * creator describes the principal who created the service.
   *
   * @generated from field: gitpod.v1.Subject creator = 5;
   */
  creator?: Subject;

  /**
   * triggered_by is a list of trigger that start the service.
   *
   * @generated from field: repeated gitpod.v1.AutomationTrigger triggered_by = 6;
   */
  triggeredBy: AutomationTrigger[] = [];

  constructor(data?: PartialMessage<ServiceMetadata>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ServiceMetadata";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "reference", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "created_at", kind: "message", T: Timestamp },
    { no: 5, name: "creator", kind: "message", T: Subject },
    { no: 6, name: "triggered_by", kind: "message", T: AutomationTrigger, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ServiceMetadata {
    return new ServiceMetadata().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ServiceMetadata {
    return new ServiceMetadata().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ServiceMetadata {
    return new ServiceMetadata().fromJsonString(jsonString, options);
  }

  static equals(a: ServiceMetadata | PlainMessage<ServiceMetadata> | undefined, b: ServiceMetadata | PlainMessage<ServiceMetadata> | undefined): boolean {
    return proto3.util.equals(ServiceMetadata, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ServiceSpec
 */
export class ServiceSpec extends Message<ServiceSpec> {
  /**
   * version of the spec. The value of this field has no semantic
   * meaning (e.g. don't interpret it as as a timestamp),
   * but it can be used to impose a partial order. If a.spec_version <
   * b.spec_version then a was the spec before b.
   *
   * @generated from field: uint64 spec_version = 1;
   */
  specVersion = protoInt64.zero;

  /**
   * desired_phase is the phase the service should be in. Used to start or stop the service.
   *
   * @generated from field: gitpod.v1.ServicePhase desired_phase = 2;
   */
  desiredPhase = ServicePhase.UNSPECIFIED;

  /**
   * commands contains the commands to start, stop and check the readiness of the service
   *
   * @generated from field: gitpod.v1.ServiceSpec.Commands commands = 3;
   */
  commands?: ServiceSpec_Commands;

  /**
   * session should be changed to trigger a restart of the service. If a service exits it will
   * not be restarted until the session is changed.
   *
   * @generated from field: string session = 4;
   */
  session = "";

  constructor(data?: PartialMessage<ServiceSpec>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ServiceSpec";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "spec_version", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "desired_phase", kind: "enum", T: proto3.getEnumType(ServicePhase) },
    { no: 3, name: "commands", kind: "message", T: ServiceSpec_Commands },
    { no: 4, name: "session", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ServiceSpec {
    return new ServiceSpec().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ServiceSpec {
    return new ServiceSpec().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ServiceSpec {
    return new ServiceSpec().fromJsonString(jsonString, options);
  }

  static equals(a: ServiceSpec | PlainMessage<ServiceSpec> | undefined, b: ServiceSpec | PlainMessage<ServiceSpec> | undefined): boolean {
    return proto3.util.equals(ServiceSpec, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ServiceSpec.Commands
 */
export class ServiceSpec_Commands extends Message<ServiceSpec_Commands> {
  /**
   * start is the command to start and run the service.
   * If start exits, the service will transition to the following phase:
   * - Stopped: if the exit code is 0
   * - Failed: if the exit code is not 0
   * If the stop command is not set, the start command will receive a SIGTERM signal when the service is
   * requested to stop. If it does not exit within 2 minutes, it will receive a SIGKILL signal.
   *
   * @generated from field: string start = 1;
   */
  start = "";

  /**
   * ready is an optional command that is run repeatedly until it exits with a zero exit code.
   * If set, the service will first go into a Starting phase, and then into a Running phase once the ready
   * command exits with a zero exit code.
   *
   * @generated from field: string ready = 2;
   */
  ready = "";

  /**
   * stop is an optional command that runs when the service is requested to stop.
   * If set, instead of sending a SIGTERM signal to the start command, the stop command will be run.
   * Once the stop command exits, the start command will receive a SIGKILL signal.
   * If the stop command exits with a non-zero exit code, the service will transition to the Failed phase.
   * If the stop command does not exit within 2 minutes, a SIGKILL signal will be sent to both the start and
   * stop commands.
   *
   * @generated from field: string stop = 3;
   */
  stop = "";

  constructor(data?: PartialMessage<ServiceSpec_Commands>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ServiceSpec.Commands";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "start", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "ready", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "stop", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ServiceSpec_Commands {
    return new ServiceSpec_Commands().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ServiceSpec_Commands {
    return new ServiceSpec_Commands().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ServiceSpec_Commands {
    return new ServiceSpec_Commands().fromJsonString(jsonString, options);
  }

  static equals(a: ServiceSpec_Commands | PlainMessage<ServiceSpec_Commands> | undefined, b: ServiceSpec_Commands | PlainMessage<ServiceSpec_Commands> | undefined): boolean {
    return proto3.util.equals(ServiceSpec_Commands, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ServiceStatus
 */
export class ServiceStatus extends Message<ServiceStatus> {
  /**
   * version of the status update. Service instances themselves are
   * unversioned, but their status has different versions. The value of this
   * field has no semantic meaning (e.g. don't interpret it as as a timestamp),
   * but it can be used to impose a partial order. If a.status_version <
   * b.status_version then a was the status before b.
   *
   * @generated from field: uint64 status_version = 1;
   */
  statusVersion = protoInt64.zero;

  /**
   * phase is the current phase of the service.
   *
   * @generated from field: gitpod.v1.ServicePhase phase = 2;
   */
  phase = ServicePhase.UNSPECIFIED;

  /**
   * failure_message summarises why the service failed to operate. If this is non-empty
   * the service has failed to operate and will likely transition to a failed state.
   *
   * @generated from field: string failure_message = 3;
   */
  failureMessage = "";

  /**
   * log_url contains the URL at which the service logs can be accessed.
   *
   * @generated from field: string log_url = 4;
   */
  logUrl = "";

  /**
   * session is the current session of the service.
   *
   * @generated from field: string session = 5;
   */
  session = "";

  constructor(data?: PartialMessage<ServiceStatus>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ServiceStatus";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status_version", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "phase", kind: "enum", T: proto3.getEnumType(ServicePhase) },
    { no: 3, name: "failure_message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "log_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "session", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ServiceStatus {
    return new ServiceStatus().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ServiceStatus {
    return new ServiceStatus().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ServiceStatus {
    return new ServiceStatus().fromJsonString(jsonString, options);
  }

  static equals(a: ServiceStatus | PlainMessage<ServiceStatus> | undefined, b: ServiceStatus | PlainMessage<ServiceStatus> | undefined): boolean {
    return proto3.util.equals(ServiceStatus, a, b);
  }
}

/**
 * @generated from message gitpod.v1.Service
 */
export class Service extends Message<Service> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string environment_id = 2;
   */
  environmentId = "";

  /**
   * @generated from field: gitpod.v1.ServiceMetadata metadata = 3;
   */
  metadata?: ServiceMetadata;

  /**
   * @generated from field: gitpod.v1.ServiceSpec spec = 4;
   */
  spec?: ServiceSpec;

  /**
   * @generated from field: gitpod.v1.ServiceStatus status = 5;
   */
  status?: ServiceStatus;

  constructor(data?: PartialMessage<Service>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.Service";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "environment_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "metadata", kind: "message", T: ServiceMetadata },
    { no: 4, name: "spec", kind: "message", T: ServiceSpec },
    { no: 5, name: "status", kind: "message", T: ServiceStatus },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Service {
    return new Service().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Service {
    return new Service().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Service {
    return new Service().fromJsonString(jsonString, options);
  }

  static equals(a: Service | PlainMessage<Service> | undefined, b: Service | PlainMessage<Service> | undefined): boolean {
    return proto3.util.equals(Service, a, b);
  }
}

/**
 * @generated from message gitpod.v1.CreateServiceRequest
 */
export class CreateServiceRequest extends Message<CreateServiceRequest> {
  /**
   * @generated from field: string environment_id = 1;
   */
  environmentId = "";

  /**
   * @generated from field: gitpod.v1.ServiceMetadata metadata = 2;
   */
  metadata?: ServiceMetadata;

  /**
   * @generated from field: gitpod.v1.ServiceSpec spec = 3;
   */
  spec?: ServiceSpec;

  constructor(data?: PartialMessage<CreateServiceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.CreateServiceRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "environment_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "metadata", kind: "message", T: ServiceMetadata },
    { no: 3, name: "spec", kind: "message", T: ServiceSpec },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateServiceRequest {
    return new CreateServiceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateServiceRequest {
    return new CreateServiceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateServiceRequest {
    return new CreateServiceRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateServiceRequest | PlainMessage<CreateServiceRequest> | undefined, b: CreateServiceRequest | PlainMessage<CreateServiceRequest> | undefined): boolean {
    return proto3.util.equals(CreateServiceRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.CreateServiceResponse
 */
export class CreateServiceResponse extends Message<CreateServiceResponse> {
  /**
   * @generated from field: gitpod.v1.Service service = 1;
   */
  service?: Service;

  constructor(data?: PartialMessage<CreateServiceResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.CreateServiceResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "service", kind: "message", T: Service },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateServiceResponse {
    return new CreateServiceResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateServiceResponse {
    return new CreateServiceResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateServiceResponse {
    return new CreateServiceResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateServiceResponse | PlainMessage<CreateServiceResponse> | undefined, b: CreateServiceResponse | PlainMessage<CreateServiceResponse> | undefined): boolean {
    return proto3.util.equals(CreateServiceResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.GetServiceRequest
 */
export class GetServiceRequest extends Message<GetServiceRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<GetServiceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.GetServiceRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetServiceRequest {
    return new GetServiceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetServiceRequest {
    return new GetServiceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetServiceRequest {
    return new GetServiceRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetServiceRequest | PlainMessage<GetServiceRequest> | undefined, b: GetServiceRequest | PlainMessage<GetServiceRequest> | undefined): boolean {
    return proto3.util.equals(GetServiceRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.GetServiceResponse
 */
export class GetServiceResponse extends Message<GetServiceResponse> {
  /**
   * @generated from field: gitpod.v1.Service service = 1;
   */
  service?: Service;

  constructor(data?: PartialMessage<GetServiceResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.GetServiceResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "service", kind: "message", T: Service },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetServiceResponse {
    return new GetServiceResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetServiceResponse {
    return new GetServiceResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetServiceResponse {
    return new GetServiceResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetServiceResponse | PlainMessage<GetServiceResponse> | undefined, b: GetServiceResponse | PlainMessage<GetServiceResponse> | undefined): boolean {
    return proto3.util.equals(GetServiceResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ListServicesRequest
 */
export class ListServicesRequest extends Message<ListServicesRequest> {
  /**
   * pagination contains the pagination options for listing services
   *
   * @generated from field: gitpod.v1.PaginationRequest pagination = 1;
   */
  pagination?: PaginationRequest;

  /**
   * filter contains the filter options for listing services
   *
   * @generated from field: gitpod.v1.ListServicesRequest.Filter filter = 2;
   */
  filter?: ListServicesRequest_Filter;

  constructor(data?: PartialMessage<ListServicesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListServicesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pagination", kind: "message", T: PaginationRequest },
    { no: 2, name: "filter", kind: "message", T: ListServicesRequest_Filter },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListServicesRequest {
    return new ListServicesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListServicesRequest {
    return new ListServicesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListServicesRequest {
    return new ListServicesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListServicesRequest | PlainMessage<ListServicesRequest> | undefined, b: ListServicesRequest | PlainMessage<ListServicesRequest> | undefined): boolean {
    return proto3.util.equals(ListServicesRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ListServicesRequest.Filter
 */
export class ListServicesRequest_Filter extends Message<ListServicesRequest_Filter> {
  /**
   * environment_ids filters the response to only services of these environments
   *
   * @generated from field: repeated string environment_ids = 1;
   */
  environmentIds: string[] = [];

  /**
   * references filters the response to only services with these references
   *
   * @generated from field: repeated string references = 2;
   */
  references: string[] = [];

  /**
   * service_ids filters the response to only services with these IDs
   *
   * @generated from field: repeated string service_ids = 3;
   */
  serviceIds: string[] = [];

  constructor(data?: PartialMessage<ListServicesRequest_Filter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListServicesRequest.Filter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "environment_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "references", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "service_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListServicesRequest_Filter {
    return new ListServicesRequest_Filter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListServicesRequest_Filter {
    return new ListServicesRequest_Filter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListServicesRequest_Filter {
    return new ListServicesRequest_Filter().fromJsonString(jsonString, options);
  }

  static equals(a: ListServicesRequest_Filter | PlainMessage<ListServicesRequest_Filter> | undefined, b: ListServicesRequest_Filter | PlainMessage<ListServicesRequest_Filter> | undefined): boolean {
    return proto3.util.equals(ListServicesRequest_Filter, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ListServicesResponse
 */
export class ListServicesResponse extends Message<ListServicesResponse> {
  /**
   * @generated from field: gitpod.v1.PaginationResponse pagination = 1;
   */
  pagination?: PaginationResponse;

  /**
   * @generated from field: repeated gitpod.v1.Service services = 2;
   */
  services: Service[] = [];

  constructor(data?: PartialMessage<ListServicesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListServicesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pagination", kind: "message", T: PaginationResponse },
    { no: 2, name: "services", kind: "message", T: Service, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListServicesResponse {
    return new ListServicesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListServicesResponse {
    return new ListServicesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListServicesResponse {
    return new ListServicesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListServicesResponse | PlainMessage<ListServicesResponse> | undefined, b: ListServicesResponse | PlainMessage<ListServicesResponse> | undefined): boolean {
    return proto3.util.equals(ListServicesResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.UpdateServiceRequest
 */
export class UpdateServiceRequest extends Message<UpdateServiceRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: gitpod.v1.UpdateServiceRequest.ServiceMetadata metadata = 2;
   */
  metadata?: UpdateServiceRequest_ServiceMetadata;

  /**
   * Changing the spec of a service is a complex operation. The spec of a service
   * can only be updated if the service is in a stopped state. If the service is
   * running, it must be stopped first.
   *
   * @generated from field: gitpod.v1.UpdateServiceRequest.ServiceSpec spec = 3;
   */
  spec?: UpdateServiceRequest_ServiceSpec;

  /**
   * Service status updates are only expected from the executing environment. As a client
   * of this API you are not expected to provide this field. Updating this field requires
   * the `environmentservice:update_status` permission.
   *
   * @generated from field: gitpod.v1.UpdateServiceRequest.ServiceStatus status = 4;
   */
  status?: UpdateServiceRequest_ServiceStatus;

  constructor(data?: PartialMessage<UpdateServiceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.UpdateServiceRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "metadata", kind: "message", T: UpdateServiceRequest_ServiceMetadata },
    { no: 3, name: "spec", kind: "message", T: UpdateServiceRequest_ServiceSpec },
    { no: 4, name: "status", kind: "message", T: UpdateServiceRequest_ServiceStatus },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateServiceRequest {
    return new UpdateServiceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateServiceRequest {
    return new UpdateServiceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateServiceRequest {
    return new UpdateServiceRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateServiceRequest | PlainMessage<UpdateServiceRequest> | undefined, b: UpdateServiceRequest | PlainMessage<UpdateServiceRequest> | undefined): boolean {
    return proto3.util.equals(UpdateServiceRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.UpdateServiceRequest.AutomationTriggerUpdate
 */
export class UpdateServiceRequest_AutomationTriggerUpdate extends Message<UpdateServiceRequest_AutomationTriggerUpdate> {
  /**
   * @generated from field: repeated gitpod.v1.AutomationTrigger trigger = 1;
   */
  trigger: AutomationTrigger[] = [];

  constructor(data?: PartialMessage<UpdateServiceRequest_AutomationTriggerUpdate>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.UpdateServiceRequest.AutomationTriggerUpdate";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "trigger", kind: "message", T: AutomationTrigger, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateServiceRequest_AutomationTriggerUpdate {
    return new UpdateServiceRequest_AutomationTriggerUpdate().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateServiceRequest_AutomationTriggerUpdate {
    return new UpdateServiceRequest_AutomationTriggerUpdate().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateServiceRequest_AutomationTriggerUpdate {
    return new UpdateServiceRequest_AutomationTriggerUpdate().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateServiceRequest_AutomationTriggerUpdate | PlainMessage<UpdateServiceRequest_AutomationTriggerUpdate> | undefined, b: UpdateServiceRequest_AutomationTriggerUpdate | PlainMessage<UpdateServiceRequest_AutomationTriggerUpdate> | undefined): boolean {
    return proto3.util.equals(UpdateServiceRequest_AutomationTriggerUpdate, a, b);
  }
}

/**
 * @generated from message gitpod.v1.UpdateServiceRequest.ServiceMetadata
 */
export class UpdateServiceRequest_ServiceMetadata extends Message<UpdateServiceRequest_ServiceMetadata> {
  /**
   * @generated from field: optional string name = 1;
   */
  name?: string;

  /**
   * @generated from field: optional string description = 2;
   */
  description?: string;

  /**
   * @generated from field: optional gitpod.v1.UpdateServiceRequest.AutomationTriggerUpdate triggered_by = 3;
   */
  triggeredBy?: UpdateServiceRequest_AutomationTriggerUpdate;

  constructor(data?: PartialMessage<UpdateServiceRequest_ServiceMetadata>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.UpdateServiceRequest.ServiceMetadata";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "triggered_by", kind: "message", T: UpdateServiceRequest_AutomationTriggerUpdate, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateServiceRequest_ServiceMetadata {
    return new UpdateServiceRequest_ServiceMetadata().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateServiceRequest_ServiceMetadata {
    return new UpdateServiceRequest_ServiceMetadata().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateServiceRequest_ServiceMetadata {
    return new UpdateServiceRequest_ServiceMetadata().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateServiceRequest_ServiceMetadata | PlainMessage<UpdateServiceRequest_ServiceMetadata> | undefined, b: UpdateServiceRequest_ServiceMetadata | PlainMessage<UpdateServiceRequest_ServiceMetadata> | undefined): boolean {
    return proto3.util.equals(UpdateServiceRequest_ServiceMetadata, a, b);
  }
}

/**
 * @generated from message gitpod.v1.UpdateServiceRequest.ServiceSpec
 */
export class UpdateServiceRequest_ServiceSpec extends Message<UpdateServiceRequest_ServiceSpec> {
  /**
   * @generated from field: optional gitpod.v1.UpdateServiceRequest.ServiceSpec.Commands commands = 1;
   */
  commands?: UpdateServiceRequest_ServiceSpec_Commands;

  constructor(data?: PartialMessage<UpdateServiceRequest_ServiceSpec>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.UpdateServiceRequest.ServiceSpec";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "commands", kind: "message", T: UpdateServiceRequest_ServiceSpec_Commands, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateServiceRequest_ServiceSpec {
    return new UpdateServiceRequest_ServiceSpec().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateServiceRequest_ServiceSpec {
    return new UpdateServiceRequest_ServiceSpec().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateServiceRequest_ServiceSpec {
    return new UpdateServiceRequest_ServiceSpec().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateServiceRequest_ServiceSpec | PlainMessage<UpdateServiceRequest_ServiceSpec> | undefined, b: UpdateServiceRequest_ServiceSpec | PlainMessage<UpdateServiceRequest_ServiceSpec> | undefined): boolean {
    return proto3.util.equals(UpdateServiceRequest_ServiceSpec, a, b);
  }
}

/**
 * @generated from message gitpod.v1.UpdateServiceRequest.ServiceSpec.Commands
 */
export class UpdateServiceRequest_ServiceSpec_Commands extends Message<UpdateServiceRequest_ServiceSpec_Commands> {
  /**
   * @generated from field: optional string start = 1;
   */
  start?: string;

  /**
   * @generated from field: optional string ready = 2;
   */
  ready?: string;

  /**
   * @generated from field: optional string stop = 3;
   */
  stop?: string;

  constructor(data?: PartialMessage<UpdateServiceRequest_ServiceSpec_Commands>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.UpdateServiceRequest.ServiceSpec.Commands";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "start", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "ready", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "stop", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateServiceRequest_ServiceSpec_Commands {
    return new UpdateServiceRequest_ServiceSpec_Commands().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateServiceRequest_ServiceSpec_Commands {
    return new UpdateServiceRequest_ServiceSpec_Commands().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateServiceRequest_ServiceSpec_Commands {
    return new UpdateServiceRequest_ServiceSpec_Commands().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateServiceRequest_ServiceSpec_Commands | PlainMessage<UpdateServiceRequest_ServiceSpec_Commands> | undefined, b: UpdateServiceRequest_ServiceSpec_Commands | PlainMessage<UpdateServiceRequest_ServiceSpec_Commands> | undefined): boolean {
    return proto3.util.equals(UpdateServiceRequest_ServiceSpec_Commands, a, b);
  }
}

/**
 * @generated from message gitpod.v1.UpdateServiceRequest.ServiceStatus
 */
export class UpdateServiceRequest_ServiceStatus extends Message<UpdateServiceRequest_ServiceStatus> {
  /**
   * @generated from field: optional gitpod.v1.ServicePhase phase = 1;
   */
  phase?: ServicePhase;

  /**
   * @generated from field: optional string failure_message = 2;
   */
  failureMessage?: string;

  /**
   * @generated from field: optional string log_url = 3;
   */
  logUrl?: string;

  /**
   * @generated from field: optional string session = 4;
   */
  session?: string;

  constructor(data?: PartialMessage<UpdateServiceRequest_ServiceStatus>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.UpdateServiceRequest.ServiceStatus";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "phase", kind: "enum", T: proto3.getEnumType(ServicePhase), opt: true },
    { no: 2, name: "failure_message", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "log_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "session", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateServiceRequest_ServiceStatus {
    return new UpdateServiceRequest_ServiceStatus().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateServiceRequest_ServiceStatus {
    return new UpdateServiceRequest_ServiceStatus().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateServiceRequest_ServiceStatus {
    return new UpdateServiceRequest_ServiceStatus().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateServiceRequest_ServiceStatus | PlainMessage<UpdateServiceRequest_ServiceStatus> | undefined, b: UpdateServiceRequest_ServiceStatus | PlainMessage<UpdateServiceRequest_ServiceStatus> | undefined): boolean {
    return proto3.util.equals(UpdateServiceRequest_ServiceStatus, a, b);
  }
}

/**
 * @generated from message gitpod.v1.UpdateServiceResponse
 */
export class UpdateServiceResponse extends Message<UpdateServiceResponse> {
  constructor(data?: PartialMessage<UpdateServiceResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.UpdateServiceResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateServiceResponse {
    return new UpdateServiceResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateServiceResponse {
    return new UpdateServiceResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateServiceResponse {
    return new UpdateServiceResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateServiceResponse | PlainMessage<UpdateServiceResponse> | undefined, b: UpdateServiceResponse | PlainMessage<UpdateServiceResponse> | undefined): boolean {
    return proto3.util.equals(UpdateServiceResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.DeleteServiceRequest
 */
export class DeleteServiceRequest extends Message<DeleteServiceRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: bool force = 2;
   */
  force = false;

  constructor(data?: PartialMessage<DeleteServiceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.DeleteServiceRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "force", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteServiceRequest {
    return new DeleteServiceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteServiceRequest {
    return new DeleteServiceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteServiceRequest {
    return new DeleteServiceRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteServiceRequest | PlainMessage<DeleteServiceRequest> | undefined, b: DeleteServiceRequest | PlainMessage<DeleteServiceRequest> | undefined): boolean {
    return proto3.util.equals(DeleteServiceRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.DeleteServiceResponse
 */
export class DeleteServiceResponse extends Message<DeleteServiceResponse> {
  constructor(data?: PartialMessage<DeleteServiceResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.DeleteServiceResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteServiceResponse {
    return new DeleteServiceResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteServiceResponse {
    return new DeleteServiceResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteServiceResponse {
    return new DeleteServiceResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteServiceResponse | PlainMessage<DeleteServiceResponse> | undefined, b: DeleteServiceResponse | PlainMessage<DeleteServiceResponse> | undefined): boolean {
    return proto3.util.equals(DeleteServiceResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.StartServiceRequest
 */
export class StartServiceRequest extends Message<StartServiceRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<StartServiceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.StartServiceRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StartServiceRequest {
    return new StartServiceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StartServiceRequest {
    return new StartServiceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StartServiceRequest {
    return new StartServiceRequest().fromJsonString(jsonString, options);
  }

  static equals(a: StartServiceRequest | PlainMessage<StartServiceRequest> | undefined, b: StartServiceRequest | PlainMessage<StartServiceRequest> | undefined): boolean {
    return proto3.util.equals(StartServiceRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.StartServiceResponse
 */
export class StartServiceResponse extends Message<StartServiceResponse> {
  constructor(data?: PartialMessage<StartServiceResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.StartServiceResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StartServiceResponse {
    return new StartServiceResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StartServiceResponse {
    return new StartServiceResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StartServiceResponse {
    return new StartServiceResponse().fromJsonString(jsonString, options);
  }

  static equals(a: StartServiceResponse | PlainMessage<StartServiceResponse> | undefined, b: StartServiceResponse | PlainMessage<StartServiceResponse> | undefined): boolean {
    return proto3.util.equals(StartServiceResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.StopServiceRequest
 */
export class StopServiceRequest extends Message<StopServiceRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<StopServiceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.StopServiceRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StopServiceRequest {
    return new StopServiceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StopServiceRequest {
    return new StopServiceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StopServiceRequest {
    return new StopServiceRequest().fromJsonString(jsonString, options);
  }

  static equals(a: StopServiceRequest | PlainMessage<StopServiceRequest> | undefined, b: StopServiceRequest | PlainMessage<StopServiceRequest> | undefined): boolean {
    return proto3.util.equals(StopServiceRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.StopServiceResponse
 */
export class StopServiceResponse extends Message<StopServiceResponse> {
  constructor(data?: PartialMessage<StopServiceResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.StopServiceResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StopServiceResponse {
    return new StopServiceResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StopServiceResponse {
    return new StopServiceResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StopServiceResponse {
    return new StopServiceResponse().fromJsonString(jsonString, options);
  }

  static equals(a: StopServiceResponse | PlainMessage<StopServiceResponse> | undefined, b: StopServiceResponse | PlainMessage<StopServiceResponse> | undefined): boolean {
    return proto3.util.equals(StopServiceResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.UpsertAutomationsFileRequest
 */
export class UpsertAutomationsFileRequest extends Message<UpsertAutomationsFileRequest> {
  /**
   * @generated from field: string environment_id = 1;
   */
  environmentId = "";

  /**
   * @generated from field: gitpod.v1.AutomationsFile automations_file = 2;
   */
  automationsFile?: AutomationsFile;

  constructor(data?: PartialMessage<UpsertAutomationsFileRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.UpsertAutomationsFileRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "environment_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "automations_file", kind: "message", T: AutomationsFile },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpsertAutomationsFileRequest {
    return new UpsertAutomationsFileRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpsertAutomationsFileRequest {
    return new UpsertAutomationsFileRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpsertAutomationsFileRequest {
    return new UpsertAutomationsFileRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpsertAutomationsFileRequest | PlainMessage<UpsertAutomationsFileRequest> | undefined, b: UpsertAutomationsFileRequest | PlainMessage<UpsertAutomationsFileRequest> | undefined): boolean {
    return proto3.util.equals(UpsertAutomationsFileRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.UpsertAutomationsFileResponse
 */
export class UpsertAutomationsFileResponse extends Message<UpsertAutomationsFileResponse> {
  /**
   * @generated from field: repeated string updated_service_ids = 1;
   */
  updatedServiceIds: string[] = [];

  /**
   * @generated from field: repeated string updated_task_ids = 2;
   */
  updatedTaskIds: string[] = [];

  constructor(data?: PartialMessage<UpsertAutomationsFileResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.UpsertAutomationsFileResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "updated_service_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "updated_task_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpsertAutomationsFileResponse {
    return new UpsertAutomationsFileResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpsertAutomationsFileResponse {
    return new UpsertAutomationsFileResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpsertAutomationsFileResponse {
    return new UpsertAutomationsFileResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpsertAutomationsFileResponse | PlainMessage<UpsertAutomationsFileResponse> | undefined, b: UpsertAutomationsFileResponse | PlainMessage<UpsertAutomationsFileResponse> | undefined): boolean {
    return proto3.util.equals(UpsertAutomationsFileResponse, a, b);
  }
}

/**
 * WARN: Do not remove any field here, as it will break reading automation yaml files. We error if there are any
 * unknown fields in the yaml (to ensure the yaml is correct), but would break if we removed any fields.
 * This includes marking a field as "reserved" in the proto file, this will also break reading the yaml.
 *
 * @generated from message gitpod.v1.AutomationsFile
 */
export class AutomationsFile extends Message<AutomationsFile> {
  /**
   * @generated from field: map<string, gitpod.v1.AutomationsFile.Service> services = 1;
   */
  services: { [key: string]: AutomationsFile_Service } = {};

  /**
   * @generated from field: map<string, gitpod.v1.AutomationsFile.Task> tasks = 2;
   */
  tasks: { [key: string]: AutomationsFile_Task } = {};

  constructor(data?: PartialMessage<AutomationsFile>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.AutomationsFile";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "services", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: AutomationsFile_Service} },
    { no: 2, name: "tasks", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: AutomationsFile_Task} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AutomationsFile {
    return new AutomationsFile().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AutomationsFile {
    return new AutomationsFile().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AutomationsFile {
    return new AutomationsFile().fromJsonString(jsonString, options);
  }

  static equals(a: AutomationsFile | PlainMessage<AutomationsFile> | undefined, b: AutomationsFile | PlainMessage<AutomationsFile> | undefined): boolean {
    return proto3.util.equals(AutomationsFile, a, b);
  }
}

/**
 * @generated from message gitpod.v1.AutomationsFile.Service
 */
export class AutomationsFile_Service extends Message<AutomationsFile_Service> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string description = 2;
   */
  description = "";

  /**
   * @generated from field: gitpod.v1.ServiceSpec.Commands commands = 3;
   */
  commands?: ServiceSpec_Commands;

  /**
   * @generated from field: repeated string triggered_by = 4;
   */
  triggeredBy: string[] = [];

  constructor(data?: PartialMessage<AutomationsFile_Service>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.AutomationsFile.Service";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "commands", kind: "message", T: ServiceSpec_Commands },
    { no: 4, name: "triggered_by", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AutomationsFile_Service {
    return new AutomationsFile_Service().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AutomationsFile_Service {
    return new AutomationsFile_Service().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AutomationsFile_Service {
    return new AutomationsFile_Service().fromJsonString(jsonString, options);
  }

  static equals(a: AutomationsFile_Service | PlainMessage<AutomationsFile_Service> | undefined, b: AutomationsFile_Service | PlainMessage<AutomationsFile_Service> | undefined): boolean {
    return proto3.util.equals(AutomationsFile_Service, a, b);
  }
}

/**
 * @generated from message gitpod.v1.AutomationsFile.Task
 */
export class AutomationsFile_Task extends Message<AutomationsFile_Task> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string description = 2;
   */
  description = "";

  /**
   * @generated from field: string command = 3;
   */
  command = "";

  /**
   * @generated from field: repeated string depends_on = 4;
   */
  dependsOn: string[] = [];

  /**
   * @generated from field: repeated string triggered_by = 5;
   */
  triggeredBy: string[] = [];

  /**
   * @generated from oneof gitpod.v1.AutomationsFile.Task.runs_on
   */
  runsOn: {
    /**
     * @generated from field: gitpod.v1.RunsOn.Docker docker = 10;
     */
    value: RunsOn_Docker;
    case: "docker";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<AutomationsFile_Task>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.AutomationsFile.Task";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "command", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "depends_on", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "triggered_by", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 10, name: "docker", kind: "message", T: RunsOn_Docker, oneof: "runs_on" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AutomationsFile_Task {
    return new AutomationsFile_Task().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AutomationsFile_Task {
    return new AutomationsFile_Task().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AutomationsFile_Task {
    return new AutomationsFile_Task().fromJsonString(jsonString, options);
  }

  static equals(a: AutomationsFile_Task | PlainMessage<AutomationsFile_Task> | undefined, b: AutomationsFile_Task | PlainMessage<AutomationsFile_Task> | undefined): boolean {
    return proto3.util.equals(AutomationsFile_Task, a, b);
  }
}

/**
 * @generated from message gitpod.v1.CreateTaskRequest
 */
export class CreateTaskRequest extends Message<CreateTaskRequest> {
  /**
   * @generated from field: string environment_id = 1;
   */
  environmentId = "";

  /**
   * @generated from field: gitpod.v1.TaskMetadata metadata = 2;
   */
  metadata?: TaskMetadata;

  /**
   * @generated from field: gitpod.v1.TaskSpec spec = 3;
   */
  spec?: TaskSpec;

  /**
   * @generated from field: repeated string depends_on = 4;
   */
  dependsOn: string[] = [];

  constructor(data?: PartialMessage<CreateTaskRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.CreateTaskRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "environment_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "metadata", kind: "message", T: TaskMetadata },
    { no: 3, name: "spec", kind: "message", T: TaskSpec },
    { no: 4, name: "depends_on", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateTaskRequest {
    return new CreateTaskRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateTaskRequest {
    return new CreateTaskRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateTaskRequest {
    return new CreateTaskRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateTaskRequest | PlainMessage<CreateTaskRequest> | undefined, b: CreateTaskRequest | PlainMessage<CreateTaskRequest> | undefined): boolean {
    return proto3.util.equals(CreateTaskRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.CreateTaskResponse
 */
export class CreateTaskResponse extends Message<CreateTaskResponse> {
  /**
   * @generated from field: gitpod.v1.Task task = 1;
   */
  task?: Task;

  constructor(data?: PartialMessage<CreateTaskResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.CreateTaskResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "task", kind: "message", T: Task },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateTaskResponse {
    return new CreateTaskResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateTaskResponse {
    return new CreateTaskResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateTaskResponse {
    return new CreateTaskResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateTaskResponse | PlainMessage<CreateTaskResponse> | undefined, b: CreateTaskResponse | PlainMessage<CreateTaskResponse> | undefined): boolean {
    return proto3.util.equals(CreateTaskResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.GetTaskRequest
 */
export class GetTaskRequest extends Message<GetTaskRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<GetTaskRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.GetTaskRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTaskRequest {
    return new GetTaskRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTaskRequest {
    return new GetTaskRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetTaskRequest {
    return new GetTaskRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetTaskRequest | PlainMessage<GetTaskRequest> | undefined, b: GetTaskRequest | PlainMessage<GetTaskRequest> | undefined): boolean {
    return proto3.util.equals(GetTaskRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.GetTaskResponse
 */
export class GetTaskResponse extends Message<GetTaskResponse> {
  /**
   * @generated from field: gitpod.v1.Task task = 1;
   */
  task?: Task;

  constructor(data?: PartialMessage<GetTaskResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.GetTaskResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "task", kind: "message", T: Task },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTaskResponse {
    return new GetTaskResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTaskResponse {
    return new GetTaskResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetTaskResponse {
    return new GetTaskResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetTaskResponse | PlainMessage<GetTaskResponse> | undefined, b: GetTaskResponse | PlainMessage<GetTaskResponse> | undefined): boolean {
    return proto3.util.equals(GetTaskResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ListTasksRequest
 */
export class ListTasksRequest extends Message<ListTasksRequest> {
  /**
   * pagination contains the pagination options for listing tasks
   *
   * @generated from field: gitpod.v1.PaginationRequest pagination = 1;
   */
  pagination?: PaginationRequest;

  /**
   * filter contains the filter options for listing tasks
   *
   * @generated from field: gitpod.v1.ListTasksRequest.Filter filter = 2;
   */
  filter?: ListTasksRequest_Filter;

  constructor(data?: PartialMessage<ListTasksRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListTasksRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pagination", kind: "message", T: PaginationRequest },
    { no: 2, name: "filter", kind: "message", T: ListTasksRequest_Filter },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListTasksRequest {
    return new ListTasksRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListTasksRequest {
    return new ListTasksRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListTasksRequest {
    return new ListTasksRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListTasksRequest | PlainMessage<ListTasksRequest> | undefined, b: ListTasksRequest | PlainMessage<ListTasksRequest> | undefined): boolean {
    return proto3.util.equals(ListTasksRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ListTasksRequest.Filter
 */
export class ListTasksRequest_Filter extends Message<ListTasksRequest_Filter> {
  /**
   * environment_ids filters the response to only tasks of these environments
   *
   * @generated from field: repeated string environment_ids = 1;
   */
  environmentIds: string[] = [];

  /**
   * references filters the response to only services with these references
   *
   * @generated from field: repeated string references = 2;
   */
  references: string[] = [];

  /**
   * task_ids filters the response to only tasks with these IDs
   *
   * @generated from field: repeated string task_ids = 3;
   */
  taskIds: string[] = [];

  constructor(data?: PartialMessage<ListTasksRequest_Filter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListTasksRequest.Filter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "environment_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "references", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "task_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListTasksRequest_Filter {
    return new ListTasksRequest_Filter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListTasksRequest_Filter {
    return new ListTasksRequest_Filter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListTasksRequest_Filter {
    return new ListTasksRequest_Filter().fromJsonString(jsonString, options);
  }

  static equals(a: ListTasksRequest_Filter | PlainMessage<ListTasksRequest_Filter> | undefined, b: ListTasksRequest_Filter | PlainMessage<ListTasksRequest_Filter> | undefined): boolean {
    return proto3.util.equals(ListTasksRequest_Filter, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ListTasksResponse
 */
export class ListTasksResponse extends Message<ListTasksResponse> {
  /**
   * @generated from field: gitpod.v1.PaginationResponse pagination = 1;
   */
  pagination?: PaginationResponse;

  /**
   * @generated from field: repeated gitpod.v1.Task tasks = 2;
   */
  tasks: Task[] = [];

  constructor(data?: PartialMessage<ListTasksResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListTasksResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pagination", kind: "message", T: PaginationResponse },
    { no: 2, name: "tasks", kind: "message", T: Task, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListTasksResponse {
    return new ListTasksResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListTasksResponse {
    return new ListTasksResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListTasksResponse {
    return new ListTasksResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListTasksResponse | PlainMessage<ListTasksResponse> | undefined, b: ListTasksResponse | PlainMessage<ListTasksResponse> | undefined): boolean {
    return proto3.util.equals(ListTasksResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.UpdateTaskRequest
 */
export class UpdateTaskRequest extends Message<UpdateTaskRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: gitpod.v1.UpdateTaskRequest.TaskMetadata metadata = 2;
   */
  metadata?: UpdateTaskRequest_TaskMetadata;

  /**
   * @generated from field: gitpod.v1.UpdateTaskRequest.TaskSpec spec = 3;
   */
  spec?: UpdateTaskRequest_TaskSpec;

  /**
   * dependencies specifies the IDs of the automations this task depends on.
   *
   * @generated from field: repeated string depends_on = 4;
   */
  dependsOn: string[] = [];

  constructor(data?: PartialMessage<UpdateTaskRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.UpdateTaskRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "metadata", kind: "message", T: UpdateTaskRequest_TaskMetadata },
    { no: 3, name: "spec", kind: "message", T: UpdateTaskRequest_TaskSpec },
    { no: 4, name: "depends_on", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateTaskRequest {
    return new UpdateTaskRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateTaskRequest {
    return new UpdateTaskRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateTaskRequest {
    return new UpdateTaskRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateTaskRequest | PlainMessage<UpdateTaskRequest> | undefined, b: UpdateTaskRequest | PlainMessage<UpdateTaskRequest> | undefined): boolean {
    return proto3.util.equals(UpdateTaskRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.UpdateTaskRequest.AutomationTriggerUpdate
 */
export class UpdateTaskRequest_AutomationTriggerUpdate extends Message<UpdateTaskRequest_AutomationTriggerUpdate> {
  /**
   * @generated from field: repeated gitpod.v1.AutomationTrigger trigger = 1;
   */
  trigger: AutomationTrigger[] = [];

  constructor(data?: PartialMessage<UpdateTaskRequest_AutomationTriggerUpdate>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.UpdateTaskRequest.AutomationTriggerUpdate";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "trigger", kind: "message", T: AutomationTrigger, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateTaskRequest_AutomationTriggerUpdate {
    return new UpdateTaskRequest_AutomationTriggerUpdate().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateTaskRequest_AutomationTriggerUpdate {
    return new UpdateTaskRequest_AutomationTriggerUpdate().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateTaskRequest_AutomationTriggerUpdate {
    return new UpdateTaskRequest_AutomationTriggerUpdate().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateTaskRequest_AutomationTriggerUpdate | PlainMessage<UpdateTaskRequest_AutomationTriggerUpdate> | undefined, b: UpdateTaskRequest_AutomationTriggerUpdate | PlainMessage<UpdateTaskRequest_AutomationTriggerUpdate> | undefined): boolean {
    return proto3.util.equals(UpdateTaskRequest_AutomationTriggerUpdate, a, b);
  }
}

/**
 * @generated from message gitpod.v1.UpdateTaskRequest.TaskMetadata
 */
export class UpdateTaskRequest_TaskMetadata extends Message<UpdateTaskRequest_TaskMetadata> {
  /**
   * @generated from field: optional string name = 1;
   */
  name?: string;

  /**
   * @generated from field: optional string description = 2;
   */
  description?: string;

  /**
   * @generated from field: optional gitpod.v1.UpdateTaskRequest.AutomationTriggerUpdate triggered_by = 3;
   */
  triggeredBy?: UpdateTaskRequest_AutomationTriggerUpdate;

  constructor(data?: PartialMessage<UpdateTaskRequest_TaskMetadata>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.UpdateTaskRequest.TaskMetadata";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "triggered_by", kind: "message", T: UpdateTaskRequest_AutomationTriggerUpdate, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateTaskRequest_TaskMetadata {
    return new UpdateTaskRequest_TaskMetadata().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateTaskRequest_TaskMetadata {
    return new UpdateTaskRequest_TaskMetadata().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateTaskRequest_TaskMetadata {
    return new UpdateTaskRequest_TaskMetadata().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateTaskRequest_TaskMetadata | PlainMessage<UpdateTaskRequest_TaskMetadata> | undefined, b: UpdateTaskRequest_TaskMetadata | PlainMessage<UpdateTaskRequest_TaskMetadata> | undefined): boolean {
    return proto3.util.equals(UpdateTaskRequest_TaskMetadata, a, b);
  }
}

/**
 * @generated from message gitpod.v1.UpdateTaskRequest.TaskSpec
 */
export class UpdateTaskRequest_TaskSpec extends Message<UpdateTaskRequest_TaskSpec> {
  /**
   * @generated from field: optional string command = 1;
   */
  command?: string;

  /**
   * @generated from field: optional gitpod.v1.RunsOn runs_on = 2;
   */
  runsOn?: RunsOn;

  constructor(data?: PartialMessage<UpdateTaskRequest_TaskSpec>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.UpdateTaskRequest.TaskSpec";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "command", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "runs_on", kind: "message", T: RunsOn, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateTaskRequest_TaskSpec {
    return new UpdateTaskRequest_TaskSpec().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateTaskRequest_TaskSpec {
    return new UpdateTaskRequest_TaskSpec().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateTaskRequest_TaskSpec {
    return new UpdateTaskRequest_TaskSpec().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateTaskRequest_TaskSpec | PlainMessage<UpdateTaskRequest_TaskSpec> | undefined, b: UpdateTaskRequest_TaskSpec | PlainMessage<UpdateTaskRequest_TaskSpec> | undefined): boolean {
    return proto3.util.equals(UpdateTaskRequest_TaskSpec, a, b);
  }
}

/**
 * @generated from message gitpod.v1.UpdateTaskResponse
 */
export class UpdateTaskResponse extends Message<UpdateTaskResponse> {
  constructor(data?: PartialMessage<UpdateTaskResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.UpdateTaskResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateTaskResponse {
    return new UpdateTaskResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateTaskResponse {
    return new UpdateTaskResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateTaskResponse {
    return new UpdateTaskResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateTaskResponse | PlainMessage<UpdateTaskResponse> | undefined, b: UpdateTaskResponse | PlainMessage<UpdateTaskResponse> | undefined): boolean {
    return proto3.util.equals(UpdateTaskResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.DeleteTaskRequest
 */
export class DeleteTaskRequest extends Message<DeleteTaskRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<DeleteTaskRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.DeleteTaskRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteTaskRequest {
    return new DeleteTaskRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteTaskRequest {
    return new DeleteTaskRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteTaskRequest {
    return new DeleteTaskRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteTaskRequest | PlainMessage<DeleteTaskRequest> | undefined, b: DeleteTaskRequest | PlainMessage<DeleteTaskRequest> | undefined): boolean {
    return proto3.util.equals(DeleteTaskRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.DeleteTaskResponse
 */
export class DeleteTaskResponse extends Message<DeleteTaskResponse> {
  constructor(data?: PartialMessage<DeleteTaskResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.DeleteTaskResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteTaskResponse {
    return new DeleteTaskResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteTaskResponse {
    return new DeleteTaskResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteTaskResponse {
    return new DeleteTaskResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteTaskResponse | PlainMessage<DeleteTaskResponse> | undefined, b: DeleteTaskResponse | PlainMessage<DeleteTaskResponse> | undefined): boolean {
    return proto3.util.equals(DeleteTaskResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.StartTaskRequest
 */
export class StartTaskRequest extends Message<StartTaskRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<StartTaskRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.StartTaskRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StartTaskRequest {
    return new StartTaskRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StartTaskRequest {
    return new StartTaskRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StartTaskRequest {
    return new StartTaskRequest().fromJsonString(jsonString, options);
  }

  static equals(a: StartTaskRequest | PlainMessage<StartTaskRequest> | undefined, b: StartTaskRequest | PlainMessage<StartTaskRequest> | undefined): boolean {
    return proto3.util.equals(StartTaskRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.StartTaskResponse
 */
export class StartTaskResponse extends Message<StartTaskResponse> {
  /**
   * @generated from field: gitpod.v1.TaskExecution task_execution = 1;
   */
  taskExecution?: TaskExecution;

  constructor(data?: PartialMessage<StartTaskResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.StartTaskResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "task_execution", kind: "message", T: TaskExecution },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StartTaskResponse {
    return new StartTaskResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StartTaskResponse {
    return new StartTaskResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StartTaskResponse {
    return new StartTaskResponse().fromJsonString(jsonString, options);
  }

  static equals(a: StartTaskResponse | PlainMessage<StartTaskResponse> | undefined, b: StartTaskResponse | PlainMessage<StartTaskResponse> | undefined): boolean {
    return proto3.util.equals(StartTaskResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ListTaskExecutionsRequest
 */
export class ListTaskExecutionsRequest extends Message<ListTaskExecutionsRequest> {
  /**
   * pagination contains the pagination options for listing task runs
   *
   * @generated from field: gitpod.v1.PaginationRequest pagination = 1;
   */
  pagination?: PaginationRequest;

  /**
   * filter contains the filter options for listing task runs
   *
   * @generated from field: gitpod.v1.ListTaskExecutionsRequest.Filter filter = 2;
   */
  filter?: ListTaskExecutionsRequest_Filter;

  constructor(data?: PartialMessage<ListTaskExecutionsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListTaskExecutionsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pagination", kind: "message", T: PaginationRequest },
    { no: 2, name: "filter", kind: "message", T: ListTaskExecutionsRequest_Filter },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListTaskExecutionsRequest {
    return new ListTaskExecutionsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListTaskExecutionsRequest {
    return new ListTaskExecutionsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListTaskExecutionsRequest {
    return new ListTaskExecutionsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListTaskExecutionsRequest | PlainMessage<ListTaskExecutionsRequest> | undefined, b: ListTaskExecutionsRequest | PlainMessage<ListTaskExecutionsRequest> | undefined): boolean {
    return proto3.util.equals(ListTaskExecutionsRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ListTaskExecutionsRequest.Filter
 */
export class ListTaskExecutionsRequest_Filter extends Message<ListTaskExecutionsRequest_Filter> {
  /**
   * task_ids filters the response to only task runs of these tasks
   *
   * @generated from field: repeated string task_ids = 1;
   */
  taskIds: string[] = [];

  /**
   * task_references filters the response to only task runs with this reference
   *
   * @generated from field: repeated string task_references = 2;
   */
  taskReferences: string[] = [];

  /**
   * environment_ids filters the response to only task runs of these environments
   *
   * @generated from field: repeated string environment_ids = 3;
   */
  environmentIds: string[] = [];

  /**
   * phases filters the response to only task runs in these phases
   *
   * @generated from field: repeated gitpod.v1.TaskExecutionPhase phases = 4;
   */
  phases: TaskExecutionPhase[] = [];

  constructor(data?: PartialMessage<ListTaskExecutionsRequest_Filter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListTaskExecutionsRequest.Filter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "task_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "task_references", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "environment_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "phases", kind: "enum", T: proto3.getEnumType(TaskExecutionPhase), repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListTaskExecutionsRequest_Filter {
    return new ListTaskExecutionsRequest_Filter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListTaskExecutionsRequest_Filter {
    return new ListTaskExecutionsRequest_Filter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListTaskExecutionsRequest_Filter {
    return new ListTaskExecutionsRequest_Filter().fromJsonString(jsonString, options);
  }

  static equals(a: ListTaskExecutionsRequest_Filter | PlainMessage<ListTaskExecutionsRequest_Filter> | undefined, b: ListTaskExecutionsRequest_Filter | PlainMessage<ListTaskExecutionsRequest_Filter> | undefined): boolean {
    return proto3.util.equals(ListTaskExecutionsRequest_Filter, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ListTaskExecutionsResponse
 */
export class ListTaskExecutionsResponse extends Message<ListTaskExecutionsResponse> {
  /**
   * @generated from field: gitpod.v1.PaginationResponse pagination = 1;
   */
  pagination?: PaginationResponse;

  /**
   * @generated from field: repeated gitpod.v1.TaskExecution task_executions = 2;
   */
  taskExecutions: TaskExecution[] = [];

  constructor(data?: PartialMessage<ListTaskExecutionsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListTaskExecutionsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pagination", kind: "message", T: PaginationResponse },
    { no: 2, name: "task_executions", kind: "message", T: TaskExecution, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListTaskExecutionsResponse {
    return new ListTaskExecutionsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListTaskExecutionsResponse {
    return new ListTaskExecutionsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListTaskExecutionsResponse {
    return new ListTaskExecutionsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListTaskExecutionsResponse | PlainMessage<ListTaskExecutionsResponse> | undefined, b: ListTaskExecutionsResponse | PlainMessage<ListTaskExecutionsResponse> | undefined): boolean {
    return proto3.util.equals(ListTaskExecutionsResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.GetTaskExecutionRequest
 */
export class GetTaskExecutionRequest extends Message<GetTaskExecutionRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<GetTaskExecutionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.GetTaskExecutionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTaskExecutionRequest {
    return new GetTaskExecutionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTaskExecutionRequest {
    return new GetTaskExecutionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetTaskExecutionRequest {
    return new GetTaskExecutionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetTaskExecutionRequest | PlainMessage<GetTaskExecutionRequest> | undefined, b: GetTaskExecutionRequest | PlainMessage<GetTaskExecutionRequest> | undefined): boolean {
    return proto3.util.equals(GetTaskExecutionRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.GetTaskExecutionResponse
 */
export class GetTaskExecutionResponse extends Message<GetTaskExecutionResponse> {
  /**
   * @generated from field: gitpod.v1.TaskExecution task_execution = 1;
   */
  taskExecution?: TaskExecution;

  constructor(data?: PartialMessage<GetTaskExecutionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.GetTaskExecutionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "task_execution", kind: "message", T: TaskExecution },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTaskExecutionResponse {
    return new GetTaskExecutionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTaskExecutionResponse {
    return new GetTaskExecutionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetTaskExecutionResponse {
    return new GetTaskExecutionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetTaskExecutionResponse | PlainMessage<GetTaskExecutionResponse> | undefined, b: GetTaskExecutionResponse | PlainMessage<GetTaskExecutionResponse> | undefined): boolean {
    return proto3.util.equals(GetTaskExecutionResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.StopTaskExecutionRequest
 */
export class StopTaskExecutionRequest extends Message<StopTaskExecutionRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<StopTaskExecutionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.StopTaskExecutionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StopTaskExecutionRequest {
    return new StopTaskExecutionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StopTaskExecutionRequest {
    return new StopTaskExecutionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StopTaskExecutionRequest {
    return new StopTaskExecutionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: StopTaskExecutionRequest | PlainMessage<StopTaskExecutionRequest> | undefined, b: StopTaskExecutionRequest | PlainMessage<StopTaskExecutionRequest> | undefined): boolean {
    return proto3.util.equals(StopTaskExecutionRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.StopTaskExecutionResponse
 */
export class StopTaskExecutionResponse extends Message<StopTaskExecutionResponse> {
  constructor(data?: PartialMessage<StopTaskExecutionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.StopTaskExecutionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StopTaskExecutionResponse {
    return new StopTaskExecutionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StopTaskExecutionResponse {
    return new StopTaskExecutionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StopTaskExecutionResponse {
    return new StopTaskExecutionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: StopTaskExecutionResponse | PlainMessage<StopTaskExecutionResponse> | undefined, b: StopTaskExecutionResponse | PlainMessage<StopTaskExecutionResponse> | undefined): boolean {
    return proto3.util.equals(StopTaskExecutionResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.UpdateTaskExecutionStatusRequest
 */
export class UpdateTaskExecutionStatusRequest extends Message<UpdateTaskExecutionStatusRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: repeated gitpod.v1.TaskExecutionStatus.Step steps = 2;
   */
  steps: TaskExecutionStatus_Step[] = [];

  /**
   * failure_message marks the task execution as failed and provides a message explaining the failure.
   * If an individual step has failed, callers are NOT expected to set this message; only if the task execution as a whole has failed/cannot be started.
   *
   * @generated from field: optional string failure_message = 3;
   */
  failureMessage?: string;

  /**
   * log_url is the URL to the logs of the task's steps. If this is empty, the task either has no logs or has not yet started.
   *
   * @generated from field: optional string log_url = 4;
   */
  logUrl?: string;

  constructor(data?: PartialMessage<UpdateTaskExecutionStatusRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.UpdateTaskExecutionStatusRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "steps", kind: "message", T: TaskExecutionStatus_Step, repeated: true },
    { no: 3, name: "failure_message", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "log_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateTaskExecutionStatusRequest {
    return new UpdateTaskExecutionStatusRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateTaskExecutionStatusRequest {
    return new UpdateTaskExecutionStatusRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateTaskExecutionStatusRequest {
    return new UpdateTaskExecutionStatusRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateTaskExecutionStatusRequest | PlainMessage<UpdateTaskExecutionStatusRequest> | undefined, b: UpdateTaskExecutionStatusRequest | PlainMessage<UpdateTaskExecutionStatusRequest> | undefined): boolean {
    return proto3.util.equals(UpdateTaskExecutionStatusRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.UpdateTaskExecutionStatusResponse
 */
export class UpdateTaskExecutionStatusResponse extends Message<UpdateTaskExecutionStatusResponse> {
  constructor(data?: PartialMessage<UpdateTaskExecutionStatusResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.UpdateTaskExecutionStatusResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateTaskExecutionStatusResponse {
    return new UpdateTaskExecutionStatusResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateTaskExecutionStatusResponse {
    return new UpdateTaskExecutionStatusResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateTaskExecutionStatusResponse {
    return new UpdateTaskExecutionStatusResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateTaskExecutionStatusResponse | PlainMessage<UpdateTaskExecutionStatusResponse> | undefined, b: UpdateTaskExecutionStatusResponse | PlainMessage<UpdateTaskExecutionStatusResponse> | undefined): boolean {
    return proto3.util.equals(UpdateTaskExecutionStatusResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.TaskMetadata
 */
export class TaskMetadata extends Message<TaskMetadata> {
  /**
   * reference is a user-facing identifier for the task which must be unique on the environment.
   * It is used to express dependencies between tasks, and to identify the task in user interactions (e.g. the CLI).
   *
   * @generated from field: string reference = 1;
   */
  reference = "";

  /**
   * name is a user-facing name for the task. Unlike the reference, this field is not unique, and not referenced by the system.
   * This is a short descriptive name for the task.
   *
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * description is a user-facing description for the task. It can be used to provide context and documentation for the task.
   *
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * created_at is the time the task was created.
   *
   * @generated from field: google.protobuf.Timestamp created_at = 4;
   */
  createdAt?: Timestamp;

  /**
   * creator describes the principal who created the task.
   *
   * @generated from field: gitpod.v1.Subject creator = 5;
   */
  creator?: Subject;

  /**
   * triggered_by is a list of trigger that start the task.
   *
   * @generated from field: repeated gitpod.v1.AutomationTrigger triggered_by = 6;
   */
  triggeredBy: AutomationTrigger[] = [];

  constructor(data?: PartialMessage<TaskMetadata>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.TaskMetadata";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "reference", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "created_at", kind: "message", T: Timestamp },
    { no: 5, name: "creator", kind: "message", T: Subject },
    { no: 6, name: "triggered_by", kind: "message", T: AutomationTrigger, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TaskMetadata {
    return new TaskMetadata().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TaskMetadata {
    return new TaskMetadata().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TaskMetadata {
    return new TaskMetadata().fromJsonString(jsonString, options);
  }

  static equals(a: TaskMetadata | PlainMessage<TaskMetadata> | undefined, b: TaskMetadata | PlainMessage<TaskMetadata> | undefined): boolean {
    return proto3.util.equals(TaskMetadata, a, b);
  }
}

/**
 * @generated from message gitpod.v1.TaskSpec
 */
export class TaskSpec extends Message<TaskSpec> {
  /**
   * command contains the command the task should execute
   *
   * @generated from field: string command = 1;
   */
  command = "";

  /**
   * runs_on specifies the environment the task should run on.
   *
   * @generated from field: gitpod.v1.RunsOn runs_on = 2;
   */
  runsOn?: RunsOn;

  constructor(data?: PartialMessage<TaskSpec>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.TaskSpec";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "command", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "runs_on", kind: "message", T: RunsOn },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TaskSpec {
    return new TaskSpec().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TaskSpec {
    return new TaskSpec().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TaskSpec {
    return new TaskSpec().fromJsonString(jsonString, options);
  }

  static equals(a: TaskSpec | PlainMessage<TaskSpec> | undefined, b: TaskSpec | PlainMessage<TaskSpec> | undefined): boolean {
    return proto3.util.equals(TaskSpec, a, b);
  }
}

/**
 * @generated from message gitpod.v1.Task
 */
export class Task extends Message<Task> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string environment_id = 2;
   */
  environmentId = "";

  /**
   * @generated from field: gitpod.v1.TaskMetadata metadata = 3;
   */
  metadata?: TaskMetadata;

  /**
   * @generated from field: gitpod.v1.TaskSpec spec = 4;
   */
  spec?: TaskSpec;

  /**
   * dependencies specifies the IDs of the automations this task depends on.
   *
   * @generated from field: repeated string depends_on = 5;
   */
  dependsOn: string[] = [];

  constructor(data?: PartialMessage<Task>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.Task";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "environment_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "metadata", kind: "message", T: TaskMetadata },
    { no: 4, name: "spec", kind: "message", T: TaskSpec },
    { no: 5, name: "depends_on", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Task {
    return new Task().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Task {
    return new Task().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Task {
    return new Task().fromJsonString(jsonString, options);
  }

  static equals(a: Task | PlainMessage<Task> | undefined, b: Task | PlainMessage<Task> | undefined): boolean {
    return proto3.util.equals(Task, a, b);
  }
}

/**
 * @generated from message gitpod.v1.RunsOn
 */
export class RunsOn extends Message<RunsOn> {
  /**
   * @generated from oneof gitpod.v1.RunsOn.target
   */
  target: {
    /**
     * @generated from field: gitpod.v1.RunsOn.Docker docker = 10;
     */
    value: RunsOn_Docker;
    case: "docker";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<RunsOn>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.RunsOn";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 10, name: "docker", kind: "message", T: RunsOn_Docker, oneof: "target" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RunsOn {
    return new RunsOn().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RunsOn {
    return new RunsOn().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RunsOn {
    return new RunsOn().fromJsonString(jsonString, options);
  }

  static equals(a: RunsOn | PlainMessage<RunsOn> | undefined, b: RunsOn | PlainMessage<RunsOn> | undefined): boolean {
    return proto3.util.equals(RunsOn, a, b);
  }
}

/**
 * @generated from message gitpod.v1.RunsOn.Docker
 */
export class RunsOn_Docker extends Message<RunsOn_Docker> {
  /**
   * @generated from field: string image = 1;
   */
  image = "";

  /**
   * @generated from field: repeated string environment = 2;
   */
  environment: string[] = [];

  constructor(data?: PartialMessage<RunsOn_Docker>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.RunsOn.Docker";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "environment", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RunsOn_Docker {
    return new RunsOn_Docker().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RunsOn_Docker {
    return new RunsOn_Docker().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RunsOn_Docker {
    return new RunsOn_Docker().fromJsonString(jsonString, options);
  }

  static equals(a: RunsOn_Docker | PlainMessage<RunsOn_Docker> | undefined, b: RunsOn_Docker | PlainMessage<RunsOn_Docker> | undefined): boolean {
    return proto3.util.equals(RunsOn_Docker, a, b);
  }
}

/**
 * @generated from message gitpod.v1.TaskExecution
 */
export class TaskExecution extends Message<TaskExecution> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: gitpod.v1.TaskExecutionMetadata metadata = 2;
   */
  metadata?: TaskExecutionMetadata;

  /**
   * @generated from field: gitpod.v1.TaskExecutionSpec spec = 3;
   */
  spec?: TaskExecutionSpec;

  /**
   * @generated from field: gitpod.v1.TaskExecutionStatus status = 4;
   */
  status?: TaskExecutionStatus;

  constructor(data?: PartialMessage<TaskExecution>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.TaskExecution";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "metadata", kind: "message", T: TaskExecutionMetadata },
    { no: 3, name: "spec", kind: "message", T: TaskExecutionSpec },
    { no: 4, name: "status", kind: "message", T: TaskExecutionStatus },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TaskExecution {
    return new TaskExecution().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TaskExecution {
    return new TaskExecution().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TaskExecution {
    return new TaskExecution().fromJsonString(jsonString, options);
  }

  static equals(a: TaskExecution | PlainMessage<TaskExecution> | undefined, b: TaskExecution | PlainMessage<TaskExecution> | undefined): boolean {
    return proto3.util.equals(TaskExecution, a, b);
  }
}

/**
 * @generated from message gitpod.v1.TaskExecutionMetadata
 */
export class TaskExecutionMetadata extends Message<TaskExecutionMetadata> {
  /**
   * task_id is the ID of the main task being executed.
   *
   * @generated from field: string task_id = 1;
   */
  taskId = "";

  /**
   * environment_id is the ID of the environment in which the task run is executed.
   *
   * @generated from field: string environment_id = 2;
   */
  environmentId = "";

  /**
   * created_at is the time the task was created.
   *
   * @generated from field: google.protobuf.Timestamp created_at = 3;
   */
  createdAt?: Timestamp;

  /**
   * creator describes the principal who created/started the task run.
   *
   * @generated from field: gitpod.v1.Subject creator = 4;
   */
  creator?: Subject;

  /**
   * started_by describes the trigger that started the task execution.
   *
   * @generated from field: string started_by = 6;
   */
  startedBy = "";

  /**
   * started_at is the time the task execution actually started to run.
   *
   * @generated from field: google.protobuf.Timestamp started_at = 7;
   */
  startedAt?: Timestamp;

  /**
   * completed_at is the time the task execution was done.
   *
   * @generated from field: google.protobuf.Timestamp completed_at = 8;
   */
  completedAt?: Timestamp;

  constructor(data?: PartialMessage<TaskExecutionMetadata>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.TaskExecutionMetadata";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "task_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "environment_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "created_at", kind: "message", T: Timestamp },
    { no: 4, name: "creator", kind: "message", T: Subject },
    { no: 6, name: "started_by", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "started_at", kind: "message", T: Timestamp },
    { no: 8, name: "completed_at", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TaskExecutionMetadata {
    return new TaskExecutionMetadata().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TaskExecutionMetadata {
    return new TaskExecutionMetadata().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TaskExecutionMetadata {
    return new TaskExecutionMetadata().fromJsonString(jsonString, options);
  }

  static equals(a: TaskExecutionMetadata | PlainMessage<TaskExecutionMetadata> | undefined, b: TaskExecutionMetadata | PlainMessage<TaskExecutionMetadata> | undefined): boolean {
    return proto3.util.equals(TaskExecutionMetadata, a, b);
  }
}

/**
 * @generated from message gitpod.v1.TaskExecutionSpec
 */
export class TaskExecutionSpec extends Message<TaskExecutionSpec> {
  /**
   * plan is a list of groups of steps. The steps in a group are executed concurrently, while the groups are executed sequentially.
   * The order of the groups is the order in which they are executed.
   *
   * @generated from field: repeated gitpod.v1.TaskExecutionSpec.Group plan = 1;
   */
  plan: TaskExecutionSpec_Group[] = [];

  /**
   * desired_phase is the phase the task execution should be in. Used to stop a running task execution early.
   *
   * @generated from field: gitpod.v1.TaskExecutionPhase desired_phase = 2;
   */
  desiredPhase = TaskExecutionPhase.UNSPECIFIED;

  constructor(data?: PartialMessage<TaskExecutionSpec>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.TaskExecutionSpec";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "plan", kind: "message", T: TaskExecutionSpec_Group, repeated: true },
    { no: 2, name: "desired_phase", kind: "enum", T: proto3.getEnumType(TaskExecutionPhase) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TaskExecutionSpec {
    return new TaskExecutionSpec().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TaskExecutionSpec {
    return new TaskExecutionSpec().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TaskExecutionSpec {
    return new TaskExecutionSpec().fromJsonString(jsonString, options);
  }

  static equals(a: TaskExecutionSpec | PlainMessage<TaskExecutionSpec> | undefined, b: TaskExecutionSpec | PlainMessage<TaskExecutionSpec> | undefined): boolean {
    return proto3.util.equals(TaskExecutionSpec, a, b);
  }
}

/**
 * @generated from message gitpod.v1.TaskExecutionSpec.Task
 */
export class TaskExecutionSpec_Task extends Message<TaskExecutionSpec_Task> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: gitpod.v1.TaskSpec spec = 2;
   */
  spec?: TaskSpec;

  constructor(data?: PartialMessage<TaskExecutionSpec_Task>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.TaskExecutionSpec.Task";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "spec", kind: "message", T: TaskSpec },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TaskExecutionSpec_Task {
    return new TaskExecutionSpec_Task().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TaskExecutionSpec_Task {
    return new TaskExecutionSpec_Task().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TaskExecutionSpec_Task {
    return new TaskExecutionSpec_Task().fromJsonString(jsonString, options);
  }

  static equals(a: TaskExecutionSpec_Task | PlainMessage<TaskExecutionSpec_Task> | undefined, b: TaskExecutionSpec_Task | PlainMessage<TaskExecutionSpec_Task> | undefined): boolean {
    return proto3.util.equals(TaskExecutionSpec_Task, a, b);
  }
}

/**
 * @generated from message gitpod.v1.TaskExecutionSpec.Step
 */
export class TaskExecutionSpec_Step extends Message<TaskExecutionSpec_Step> {
  /**
   * ID is the ID of the execution step
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: repeated string depends_on = 2;
   */
  dependsOn: string[] = [];

  /**
   * @generated from field: string label = 3;
   */
  label = "";

  /**
   * @generated from oneof gitpod.v1.TaskExecutionSpec.Step.step
   */
  step: {
    /**
     * @generated from field: gitpod.v1.TaskExecutionSpec.Task task = 10;
     */
    value: TaskExecutionSpec_Task;
    case: "task";
  } | {
    /**
     * @generated from field: string service_id = 11;
     */
    value: string;
    case: "serviceId";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<TaskExecutionSpec_Step>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.TaskExecutionSpec.Step";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "depends_on", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "label", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "task", kind: "message", T: TaskExecutionSpec_Task, oneof: "step" },
    { no: 11, name: "service_id", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "step" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TaskExecutionSpec_Step {
    return new TaskExecutionSpec_Step().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TaskExecutionSpec_Step {
    return new TaskExecutionSpec_Step().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TaskExecutionSpec_Step {
    return new TaskExecutionSpec_Step().fromJsonString(jsonString, options);
  }

  static equals(a: TaskExecutionSpec_Step | PlainMessage<TaskExecutionSpec_Step> | undefined, b: TaskExecutionSpec_Step | PlainMessage<TaskExecutionSpec_Step> | undefined): boolean {
    return proto3.util.equals(TaskExecutionSpec_Step, a, b);
  }
}

/**
 * @generated from message gitpod.v1.TaskExecutionSpec.Group
 */
export class TaskExecutionSpec_Group extends Message<TaskExecutionSpec_Group> {
  /**
   * @generated from field: repeated gitpod.v1.TaskExecutionSpec.Step steps = 1;
   */
  steps: TaskExecutionSpec_Step[] = [];

  constructor(data?: PartialMessage<TaskExecutionSpec_Group>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.TaskExecutionSpec.Group";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "steps", kind: "message", T: TaskExecutionSpec_Step, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TaskExecutionSpec_Group {
    return new TaskExecutionSpec_Group().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TaskExecutionSpec_Group {
    return new TaskExecutionSpec_Group().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TaskExecutionSpec_Group {
    return new TaskExecutionSpec_Group().fromJsonString(jsonString, options);
  }

  static equals(a: TaskExecutionSpec_Group | PlainMessage<TaskExecutionSpec_Group> | undefined, b: TaskExecutionSpec_Group | PlainMessage<TaskExecutionSpec_Group> | undefined): boolean {
    return proto3.util.equals(TaskExecutionSpec_Group, a, b);
  }
}

/**
 * @generated from message gitpod.v1.TaskExecutionStatus
 */
export class TaskExecutionStatus extends Message<TaskExecutionStatus> {
  /**
   * version of the status update. Task executions themselves are
   * unversioned, but their status has different versions. The value of this
   * field has no semantic meaning (e.g. don't interpret it as as a timestamp),
   * but it can be used to impose a partial order. If a.status_version <
   * b.status_version then a was the status before b.
   *
   * @generated from field: uint64 status_version = 1;
   */
  statusVersion = protoInt64.zero;

  /**
   * the phase of a task execution represents the aggregated phase of all steps.
   *
   * @generated from field: gitpod.v1.TaskExecutionPhase phase = 2;
   */
  phase = TaskExecutionPhase.UNSPECIFIED;

  /**
   * failure_message summarises why the task execution failed to operate. If this is non-empty
   * the task execution has failed to operate and will likely transition to a failed state.
   *
   * @generated from field: string failure_message = 3;
   */
  failureMessage = "";

  /**
   * steps provides the status for each individual step of the task execution. If a step is missing it
   * has not yet started.
   *
   * @generated from field: repeated gitpod.v1.TaskExecutionStatus.Step steps = 4;
   */
  steps: TaskExecutionStatus_Step[] = [];

  /**
   * log_url is the URL to the logs of the task's steps. If this is empty, the task either has no logs
   * or has not yet started.
   *
   * @generated from field: string log_url = 5;
   */
  logUrl = "";

  constructor(data?: PartialMessage<TaskExecutionStatus>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.TaskExecutionStatus";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status_version", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "phase", kind: "enum", T: proto3.getEnumType(TaskExecutionPhase) },
    { no: 3, name: "failure_message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "steps", kind: "message", T: TaskExecutionStatus_Step, repeated: true },
    { no: 5, name: "log_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TaskExecutionStatus {
    return new TaskExecutionStatus().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TaskExecutionStatus {
    return new TaskExecutionStatus().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TaskExecutionStatus {
    return new TaskExecutionStatus().fromJsonString(jsonString, options);
  }

  static equals(a: TaskExecutionStatus | PlainMessage<TaskExecutionStatus> | undefined, b: TaskExecutionStatus | PlainMessage<TaskExecutionStatus> | undefined): boolean {
    return proto3.util.equals(TaskExecutionStatus, a, b);
  }
}

/**
 * @generated from message gitpod.v1.TaskExecutionStatus.Step
 */
export class TaskExecutionStatus_Step extends Message<TaskExecutionStatus_Step> {
  /**
   * ID is the ID of the execution step
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * phase is the current phase of the execution step
   *
   * @generated from field: gitpod.v1.TaskExecutionPhase phase = 2;
   */
  phase = TaskExecutionPhase.UNSPECIFIED;

  /**
   * failure_message summarises why the step failed to operate. If this is non-empty
   * the step has failed to operate and will likely transition to a failed state.
   *
   * @generated from field: string failure_message = 3;
   */
  failureMessage = "";

  constructor(data?: PartialMessage<TaskExecutionStatus_Step>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.TaskExecutionStatus.Step";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "phase", kind: "enum", T: proto3.getEnumType(TaskExecutionPhase) },
    { no: 3, name: "failure_message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TaskExecutionStatus_Step {
    return new TaskExecutionStatus_Step().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TaskExecutionStatus_Step {
    return new TaskExecutionStatus_Step().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TaskExecutionStatus_Step {
    return new TaskExecutionStatus_Step().fromJsonString(jsonString, options);
  }

  static equals(a: TaskExecutionStatus_Step | PlainMessage<TaskExecutionStatus_Step> | undefined, b: TaskExecutionStatus_Step | PlainMessage<TaskExecutionStatus_Step> | undefined): boolean {
    return proto3.util.equals(TaskExecutionStatus_Step, a, b);
  }
}

/**
 * An AutomationTrigger represents a trigger for an automation action.
 * The `post_environment_start` field indicates that the automation should be triggered after the environment has started.
 * The `post_devcontainer_start` field indicates that the automation should be triggered after the dev container has started.
 *
 * @generated from message gitpod.v1.AutomationTrigger
 */
export class AutomationTrigger extends Message<AutomationTrigger> {
  /**
   * @generated from oneof gitpod.v1.AutomationTrigger.trigger
   */
  trigger: {
    /**
     * @generated from field: bool manual = 1;
     */
    value: boolean;
    case: "manual";
  } | {
    /**
     * @generated from field: bool post_environment_start = 10;
     */
    value: boolean;
    case: "postEnvironmentStart";
  } | {
    /**
     * @generated from field: bool post_devcontainer_start = 20;
     */
    value: boolean;
    case: "postDevcontainerStart";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<AutomationTrigger>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.AutomationTrigger";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "manual", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "trigger" },
    { no: 10, name: "post_environment_start", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "trigger" },
    { no: 20, name: "post_devcontainer_start", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "trigger" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AutomationTrigger {
    return new AutomationTrigger().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AutomationTrigger {
    return new AutomationTrigger().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AutomationTrigger {
    return new AutomationTrigger().fromJsonString(jsonString, options);
  }

  static equals(a: AutomationTrigger | PlainMessage<AutomationTrigger> | undefined, b: AutomationTrigger | PlainMessage<AutomationTrigger> | undefined): boolean {
    return proto3.util.equals(AutomationTrigger, a, b);
  }
}

