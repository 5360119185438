// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file gitpod/v1/account.proto (package gitpod.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { DeleteAccountRequest, DeleteAccountResponse, GetAccountRequest, GetAccountResponse } from "./account_pb.js";
import { MethodIdempotency, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service gitpod.v1.AccountService
 */
export const AccountService = {
  typeName: "gitpod.v1.AccountService",
  methods: {
    /**
     * GetAccount retrieves a single Account.
     *
     * @generated from rpc gitpod.v1.AccountService.GetAccount
     */
    getAccount: {
      name: "GetAccount",
      I: GetAccountRequest,
      O: GetAccountResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * DeleteAccount deletes an Account.
     * To Delete an Account, the Account must not be an active member of any Organization.
     *
     * @generated from rpc gitpod.v1.AccountService.DeleteAccount
     */
    deleteAccount: {
      name: "DeleteAccount",
      I: DeleteAccountRequest,
      O: DeleteAccountResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

