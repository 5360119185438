// @generated by protoc-gen-es v1.8.0 with parameter "target=ts"
// @generated from file gitpod/v1/organization.proto (package gitpod.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { PaginationRequest, PaginationResponse } from "./pagination_pb.js";
import { UserStatus } from "./user_pb.js";

/**
 * @generated from enum gitpod.v1.OrganizationRole
 */
export enum OrganizationRole {
  /**
   * @generated from enum value: ORGANIZATION_ROLE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: ORGANIZATION_ROLE_ADMIN = 1;
   */
  ADMIN = 1,

  /**
   * @generated from enum value: ORGANIZATION_ROLE_MEMBER = 2;
   */
  MEMBER = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(OrganizationRole)
proto3.util.setEnumType(OrganizationRole, "gitpod.v1.OrganizationRole", [
  { no: 0, name: "ORGANIZATION_ROLE_UNSPECIFIED" },
  { no: 1, name: "ORGANIZATION_ROLE_ADMIN" },
  { no: 2, name: "ORGANIZATION_ROLE_MEMBER" },
]);

/**
 * @generated from enum gitpod.v1.SSOConfigurationState
 */
export enum SSOConfigurationState {
  /**
   * @generated from enum value: SSO_CONFIGURATION_STATE_UNSPECIFIED = 0;
   */
  SSO_CONFIGURATION_STATE_UNSPECIFIED = 0,

  /**
   * @generated from enum value: SSO_CONFIGURATION_STATE_INACTIVE = 1;
   */
  SSO_CONFIGURATION_STATE_INACTIVE = 1,

  /**
   * @generated from enum value: SSO_CONFIGURATION_STATE_ACTIVE = 2;
   */
  SSO_CONFIGURATION_STATE_ACTIVE = 2,

  /**
   * @generated from enum value: SSO_CONFIGURATION_STATE_ACTIVE_NO_SIGNUPS = 3;
   */
  SSO_CONFIGURATION_STATE_ACTIVE_NO_SIGNUPS = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(SSOConfigurationState)
proto3.util.setEnumType(SSOConfigurationState, "gitpod.v1.SSOConfigurationState", [
  { no: 0, name: "SSO_CONFIGURATION_STATE_UNSPECIFIED" },
  { no: 1, name: "SSO_CONFIGURATION_STATE_INACTIVE" },
  { no: 2, name: "SSO_CONFIGURATION_STATE_ACTIVE" },
  { no: 3, name: "SSO_CONFIGURATION_STATE_ACTIVE_NO_SIGNUPS" },
]);

/**
 * @generated from message gitpod.v1.UpdateOrganizationRequest
 */
export class UpdateOrganizationRequest extends Message<UpdateOrganizationRequest> {
  /**
   * organization_id is the ID of the organization to update the settings for.
   *
   * @generated from field: string organization_id = 1;
   */
  organizationId = "";

  /**
   * name is the new name of the organization
   *
   * @generated from field: optional string name = 2;
   */
  name?: string;

  /**
   * invite_domains is the domain allowlist of the organization
   *
   * @generated from field: optional gitpod.v1.InviteDomains invite_domains = 3;
   */
  inviteDomains?: InviteDomains;

  constructor(data?: PartialMessage<UpdateOrganizationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.UpdateOrganizationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "organization_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "invite_domains", kind: "message", T: InviteDomains, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateOrganizationRequest {
    return new UpdateOrganizationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateOrganizationRequest {
    return new UpdateOrganizationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateOrganizationRequest {
    return new UpdateOrganizationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateOrganizationRequest | PlainMessage<UpdateOrganizationRequest> | undefined, b: UpdateOrganizationRequest | PlainMessage<UpdateOrganizationRequest> | undefined): boolean {
    return proto3.util.equals(UpdateOrganizationRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.UpdateOrganizationResponse
 */
export class UpdateOrganizationResponse extends Message<UpdateOrganizationResponse> {
  /**
   * organization is the updated organization
   *
   * @generated from field: gitpod.v1.Organization organization = 1;
   */
  organization?: Organization;

  constructor(data?: PartialMessage<UpdateOrganizationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.UpdateOrganizationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "organization", kind: "message", T: Organization },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateOrganizationResponse {
    return new UpdateOrganizationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateOrganizationResponse {
    return new UpdateOrganizationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateOrganizationResponse {
    return new UpdateOrganizationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateOrganizationResponse | PlainMessage<UpdateOrganizationResponse> | undefined, b: UpdateOrganizationResponse | PlainMessage<UpdateOrganizationResponse> | undefined): boolean {
    return proto3.util.equals(UpdateOrganizationResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.CreateOrganizationRequest
 */
export class CreateOrganizationRequest extends Message<CreateOrganizationRequest> {
  /**
   * name is the organization name
   *
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * join_organization decides whether the Identity issuing this request joins the org on creation
   *
   * @generated from field: bool join_organization = 2;
   */
  joinOrganization = false;

  /**
   * Should other Accounts with the same domain be automatically invited to the organization?
   *
   * @generated from field: bool invite_accounts_with_matching_domain = 3;
   */
  inviteAccountsWithMatchingDomain = false;

  constructor(data?: PartialMessage<CreateOrganizationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.CreateOrganizationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "join_organization", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "invite_accounts_with_matching_domain", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateOrganizationRequest {
    return new CreateOrganizationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateOrganizationRequest {
    return new CreateOrganizationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateOrganizationRequest {
    return new CreateOrganizationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateOrganizationRequest | PlainMessage<CreateOrganizationRequest> | undefined, b: CreateOrganizationRequest | PlainMessage<CreateOrganizationRequest> | undefined): boolean {
    return proto3.util.equals(CreateOrganizationRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.CreateOrganizationResponse
 */
export class CreateOrganizationResponse extends Message<CreateOrganizationResponse> {
  /**
   * organization is the created organization
   *
   * @generated from field: gitpod.v1.Organization organization = 1;
   */
  organization?: Organization;

  /**
   * member is the member that joined the org on creation. Only set if specified "join_organization" is "true" in the request.
   *
   * @generated from field: gitpod.v1.OrganizationMember member = 2;
   */
  member?: OrganizationMember;

  constructor(data?: PartialMessage<CreateOrganizationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.CreateOrganizationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "organization", kind: "message", T: Organization },
    { no: 2, name: "member", kind: "message", T: OrganizationMember },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateOrganizationResponse {
    return new CreateOrganizationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateOrganizationResponse {
    return new CreateOrganizationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateOrganizationResponse {
    return new CreateOrganizationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateOrganizationResponse | PlainMessage<CreateOrganizationResponse> | undefined, b: CreateOrganizationResponse | PlainMessage<CreateOrganizationResponse> | undefined): boolean {
    return proto3.util.equals(CreateOrganizationResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.JoinOrganizationRequest
 */
export class JoinOrganizationRequest extends Message<JoinOrganizationRequest> {
  /**
   * @generated from oneof gitpod.v1.JoinOrganizationRequest.join_id
   */
  joinId: {
    /**
     * organization_id is the unique identifier of the Organization to join.
     *
     * @generated from field: string organization_id = 1;
     */
    value: string;
    case: "organizationId";
  } | {
    /**
     * invite_id is the unique identifier of the invite to join the organization.
     *
     * @generated from field: string invite_id = 2;
     */
    value: string;
    case: "inviteId";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<JoinOrganizationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.JoinOrganizationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "organization_id", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "join_id" },
    { no: 2, name: "invite_id", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "join_id" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): JoinOrganizationRequest {
    return new JoinOrganizationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): JoinOrganizationRequest {
    return new JoinOrganizationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): JoinOrganizationRequest {
    return new JoinOrganizationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: JoinOrganizationRequest | PlainMessage<JoinOrganizationRequest> | undefined, b: JoinOrganizationRequest | PlainMessage<JoinOrganizationRequest> | undefined): boolean {
    return proto3.util.equals(JoinOrganizationRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.JoinOrganizationResponse
 */
export class JoinOrganizationResponse extends Message<JoinOrganizationResponse> {
  /**
   * member is the member that was created by joining the organization.
   *
   * @generated from field: gitpod.v1.OrganizationMember member = 1;
   */
  member?: OrganizationMember;

  constructor(data?: PartialMessage<JoinOrganizationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.JoinOrganizationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "member", kind: "message", T: OrganizationMember },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): JoinOrganizationResponse {
    return new JoinOrganizationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): JoinOrganizationResponse {
    return new JoinOrganizationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): JoinOrganizationResponse {
    return new JoinOrganizationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: JoinOrganizationResponse | PlainMessage<JoinOrganizationResponse> | undefined, b: JoinOrganizationResponse | PlainMessage<JoinOrganizationResponse> | undefined): boolean {
    return proto3.util.equals(JoinOrganizationResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.LeaveOrganizationRequest
 */
export class LeaveOrganizationRequest extends Message<LeaveOrganizationRequest> {
  /**
   * @generated from field: string user_id = 1;
   */
  userId = "";

  constructor(data?: PartialMessage<LeaveOrganizationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.LeaveOrganizationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LeaveOrganizationRequest {
    return new LeaveOrganizationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LeaveOrganizationRequest {
    return new LeaveOrganizationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LeaveOrganizationRequest {
    return new LeaveOrganizationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: LeaveOrganizationRequest | PlainMessage<LeaveOrganizationRequest> | undefined, b: LeaveOrganizationRequest | PlainMessage<LeaveOrganizationRequest> | undefined): boolean {
    return proto3.util.equals(LeaveOrganizationRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.LeaveOrganizationResponse
 */
export class LeaveOrganizationResponse extends Message<LeaveOrganizationResponse> {
  constructor(data?: PartialMessage<LeaveOrganizationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.LeaveOrganizationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LeaveOrganizationResponse {
    return new LeaveOrganizationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LeaveOrganizationResponse {
    return new LeaveOrganizationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LeaveOrganizationResponse {
    return new LeaveOrganizationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: LeaveOrganizationResponse | PlainMessage<LeaveOrganizationResponse> | undefined, b: LeaveOrganizationResponse | PlainMessage<LeaveOrganizationResponse> | undefined): boolean {
    return proto3.util.equals(LeaveOrganizationResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.GetOrganizationRequest
 */
export class GetOrganizationRequest extends Message<GetOrganizationRequest> {
  /**
   * organization_id is the unique identifier of the Organization to retreive.
   *
   * @generated from field: string organization_id = 1;
   */
  organizationId = "";

  constructor(data?: PartialMessage<GetOrganizationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.GetOrganizationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "organization_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetOrganizationRequest {
    return new GetOrganizationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetOrganizationRequest {
    return new GetOrganizationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetOrganizationRequest {
    return new GetOrganizationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetOrganizationRequest | PlainMessage<GetOrganizationRequest> | undefined, b: GetOrganizationRequest | PlainMessage<GetOrganizationRequest> | undefined): boolean {
    return proto3.util.equals(GetOrganizationRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.GetOrganizationResponse
 */
export class GetOrganizationResponse extends Message<GetOrganizationResponse> {
  /**
   * organization is the requested organization
   *
   * @generated from field: gitpod.v1.Organization organization = 1;
   */
  organization?: Organization;

  constructor(data?: PartialMessage<GetOrganizationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.GetOrganizationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "organization", kind: "message", T: Organization },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetOrganizationResponse {
    return new GetOrganizationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetOrganizationResponse {
    return new GetOrganizationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetOrganizationResponse {
    return new GetOrganizationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetOrganizationResponse | PlainMessage<GetOrganizationResponse> | undefined, b: GetOrganizationResponse | PlainMessage<GetOrganizationResponse> | undefined): boolean {
    return proto3.util.equals(GetOrganizationResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ListOrganizationsRequest
 */
export class ListOrganizationsRequest extends Message<ListOrganizationsRequest> {
  /**
   * pagination contains the pagination options for listing organizations
   *
   * @generated from field: gitpod.v1.PaginationRequest pagination = 1;
   */
  pagination?: PaginationRequest;

  /**
   * scope is the scope of the organizations to list
   *
   * @generated from field: gitpod.v1.ListOrganizationsRequest.Scope scope = 2;
   */
  scope = ListOrganizationsRequest_Scope.UNSPECIFIED;

  constructor(data?: PartialMessage<ListOrganizationsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListOrganizationsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pagination", kind: "message", T: PaginationRequest },
    { no: 2, name: "scope", kind: "enum", T: proto3.getEnumType(ListOrganizationsRequest_Scope) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListOrganizationsRequest {
    return new ListOrganizationsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListOrganizationsRequest {
    return new ListOrganizationsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListOrganizationsRequest {
    return new ListOrganizationsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListOrganizationsRequest | PlainMessage<ListOrganizationsRequest> | undefined, b: ListOrganizationsRequest | PlainMessage<ListOrganizationsRequest> | undefined): boolean {
    return proto3.util.equals(ListOrganizationsRequest, a, b);
  }
}

/**
 * @generated from enum gitpod.v1.ListOrganizationsRequest.Scope
 */
export enum ListOrganizationsRequest_Scope {
  /**
   * @generated from enum value: SCOPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: SCOPE_MEMBER = 1;
   */
  MEMBER = 1,

  /**
   * @generated from enum value: SCOPE_ALL = 2;
   */
  ALL = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(ListOrganizationsRequest_Scope)
proto3.util.setEnumType(ListOrganizationsRequest_Scope, "gitpod.v1.ListOrganizationsRequest.Scope", [
  { no: 0, name: "SCOPE_UNSPECIFIED" },
  { no: 1, name: "SCOPE_MEMBER" },
  { no: 2, name: "SCOPE_ALL" },
]);

/**
 * @generated from message gitpod.v1.ListOrganizationsResponse
 */
export class ListOrganizationsResponse extends Message<ListOrganizationsResponse> {
  /**
   * pagination contains the pagination options for listing organizations
   *
   * @generated from field: gitpod.v1.PaginationResponse pagination = 1;
   */
  pagination?: PaginationResponse;

  /**
   * organizations are the organizations that matched the query
   *
   * @generated from field: repeated gitpod.v1.Organization organizations = 2;
   */
  organizations: Organization[] = [];

  constructor(data?: PartialMessage<ListOrganizationsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListOrganizationsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pagination", kind: "message", T: PaginationResponse },
    { no: 2, name: "organizations", kind: "message", T: Organization, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListOrganizationsResponse {
    return new ListOrganizationsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListOrganizationsResponse {
    return new ListOrganizationsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListOrganizationsResponse {
    return new ListOrganizationsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListOrganizationsResponse | PlainMessage<ListOrganizationsResponse> | undefined, b: ListOrganizationsResponse | PlainMessage<ListOrganizationsResponse> | undefined): boolean {
    return proto3.util.equals(ListOrganizationsResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.DeleteOrganizationRequest
 */
export class DeleteOrganizationRequest extends Message<DeleteOrganizationRequest> {
  /**
   * organization_id is the ID of the organization to delete
   *
   * @generated from field: string organization_id = 1;
   */
  organizationId = "";

  constructor(data?: PartialMessage<DeleteOrganizationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.DeleteOrganizationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "organization_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteOrganizationRequest {
    return new DeleteOrganizationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteOrganizationRequest {
    return new DeleteOrganizationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteOrganizationRequest {
    return new DeleteOrganizationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteOrganizationRequest | PlainMessage<DeleteOrganizationRequest> | undefined, b: DeleteOrganizationRequest | PlainMessage<DeleteOrganizationRequest> | undefined): boolean {
    return proto3.util.equals(DeleteOrganizationRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.DeleteOrganizationResponse
 */
export class DeleteOrganizationResponse extends Message<DeleteOrganizationResponse> {
  constructor(data?: PartialMessage<DeleteOrganizationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.DeleteOrganizationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteOrganizationResponse {
    return new DeleteOrganizationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteOrganizationResponse {
    return new DeleteOrganizationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteOrganizationResponse {
    return new DeleteOrganizationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteOrganizationResponse | PlainMessage<DeleteOrganizationResponse> | undefined, b: DeleteOrganizationResponse | PlainMessage<DeleteOrganizationResponse> | undefined): boolean {
    return proto3.util.equals(DeleteOrganizationResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ListMembersRequest
 */
export class ListMembersRequest extends Message<ListMembersRequest> {
  /**
   * pagination contains the pagination options for listing members
   *
   * @generated from field: gitpod.v1.PaginationRequest pagination = 1;
   */
  pagination?: PaginationRequest;

  /**
   * organization_id is the ID of the organization to list members for
   *
   * @generated from field: string organization_id = 2;
   */
  organizationId = "";

  constructor(data?: PartialMessage<ListMembersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListMembersRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pagination", kind: "message", T: PaginationRequest },
    { no: 2, name: "organization_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListMembersRequest {
    return new ListMembersRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListMembersRequest {
    return new ListMembersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListMembersRequest {
    return new ListMembersRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListMembersRequest | PlainMessage<ListMembersRequest> | undefined, b: ListMembersRequest | PlainMessage<ListMembersRequest> | undefined): boolean {
    return proto3.util.equals(ListMembersRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ListMembersResponse
 */
export class ListMembersResponse extends Message<ListMembersResponse> {
  /**
   * pagination contains the pagination options for listing members
   *
   * @generated from field: gitpod.v1.PaginationResponse pagination = 1;
   */
  pagination?: PaginationResponse;

  /**
   * members are the members of the organization
   *
   * @generated from field: repeated gitpod.v1.OrganizationMember members = 2;
   */
  members: OrganizationMember[] = [];

  constructor(data?: PartialMessage<ListMembersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListMembersResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pagination", kind: "message", T: PaginationResponse },
    { no: 2, name: "members", kind: "message", T: OrganizationMember, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListMembersResponse {
    return new ListMembersResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListMembersResponse {
    return new ListMembersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListMembersResponse {
    return new ListMembersResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListMembersResponse | PlainMessage<ListMembersResponse> | undefined, b: ListMembersResponse | PlainMessage<ListMembersResponse> | undefined): boolean {
    return proto3.util.equals(ListMembersResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.SetRoleRequest
 */
export class SetRoleRequest extends Message<SetRoleRequest> {
  /**
   * @generated from field: string organization_id = 1;
   */
  organizationId = "";

  /**
   * @generated from field: string user_id = 2;
   */
  userId = "";

  /**
   * @generated from field: gitpod.v1.OrganizationRole role = 3;
   */
  role = OrganizationRole.UNSPECIFIED;

  constructor(data?: PartialMessage<SetRoleRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.SetRoleRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "organization_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "role", kind: "enum", T: proto3.getEnumType(OrganizationRole) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SetRoleRequest {
    return new SetRoleRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SetRoleRequest {
    return new SetRoleRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SetRoleRequest {
    return new SetRoleRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SetRoleRequest | PlainMessage<SetRoleRequest> | undefined, b: SetRoleRequest | PlainMessage<SetRoleRequest> | undefined): boolean {
    return proto3.util.equals(SetRoleRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.SetRoleResponse
 */
export class SetRoleResponse extends Message<SetRoleResponse> {
  constructor(data?: PartialMessage<SetRoleResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.SetRoleResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SetRoleResponse {
    return new SetRoleResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SetRoleResponse {
    return new SetRoleResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SetRoleResponse {
    return new SetRoleResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SetRoleResponse | PlainMessage<SetRoleResponse> | undefined, b: SetRoleResponse | PlainMessage<SetRoleResponse> | undefined): boolean {
    return proto3.util.equals(SetRoleResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.GetOrganizationInviteRequest
 */
export class GetOrganizationInviteRequest extends Message<GetOrganizationInviteRequest> {
  /**
   * @generated from field: string organization_id = 1;
   */
  organizationId = "";

  constructor(data?: PartialMessage<GetOrganizationInviteRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.GetOrganizationInviteRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "organization_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetOrganizationInviteRequest {
    return new GetOrganizationInviteRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetOrganizationInviteRequest {
    return new GetOrganizationInviteRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetOrganizationInviteRequest {
    return new GetOrganizationInviteRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetOrganizationInviteRequest | PlainMessage<GetOrganizationInviteRequest> | undefined, b: GetOrganizationInviteRequest | PlainMessage<GetOrganizationInviteRequest> | undefined): boolean {
    return proto3.util.equals(GetOrganizationInviteRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.GetOrganizationInviteResponse
 */
export class GetOrganizationInviteResponse extends Message<GetOrganizationInviteResponse> {
  /**
   * @generated from field: gitpod.v1.OrganizationInvite invite = 1;
   */
  invite?: OrganizationInvite;

  constructor(data?: PartialMessage<GetOrganizationInviteResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.GetOrganizationInviteResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "invite", kind: "message", T: OrganizationInvite },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetOrganizationInviteResponse {
    return new GetOrganizationInviteResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetOrganizationInviteResponse {
    return new GetOrganizationInviteResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetOrganizationInviteResponse {
    return new GetOrganizationInviteResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetOrganizationInviteResponse | PlainMessage<GetOrganizationInviteResponse> | undefined, b: GetOrganizationInviteResponse | PlainMessage<GetOrganizationInviteResponse> | undefined): boolean {
    return proto3.util.equals(GetOrganizationInviteResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.OrganizationInvite
 */
export class OrganizationInvite extends Message<OrganizationInvite> {
  /**
   * invite_id is the unique identifier of the invite to join the organization.
   * Use JoinOrganization with this ID to join the organization.
   *
   * @generated from field: string invite_id = 1;
   */
  inviteId = "";

  constructor(data?: PartialMessage<OrganizationInvite>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.OrganizationInvite";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "invite_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OrganizationInvite {
    return new OrganizationInvite().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OrganizationInvite {
    return new OrganizationInvite().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OrganizationInvite {
    return new OrganizationInvite().fromJsonString(jsonString, options);
  }

  static equals(a: OrganizationInvite | PlainMessage<OrganizationInvite> | undefined, b: OrganizationInvite | PlainMessage<OrganizationInvite> | undefined): boolean {
    return proto3.util.equals(OrganizationInvite, a, b);
  }
}

/**
 * @generated from message gitpod.v1.CreateOrganizationInviteRequest
 */
export class CreateOrganizationInviteRequest extends Message<CreateOrganizationInviteRequest> {
  /**
   * @generated from field: string organization_id = 1;
   */
  organizationId = "";

  constructor(data?: PartialMessage<CreateOrganizationInviteRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.CreateOrganizationInviteRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "organization_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateOrganizationInviteRequest {
    return new CreateOrganizationInviteRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateOrganizationInviteRequest {
    return new CreateOrganizationInviteRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateOrganizationInviteRequest {
    return new CreateOrganizationInviteRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateOrganizationInviteRequest | PlainMessage<CreateOrganizationInviteRequest> | undefined, b: CreateOrganizationInviteRequest | PlainMessage<CreateOrganizationInviteRequest> | undefined): boolean {
    return proto3.util.equals(CreateOrganizationInviteRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.CreateOrganizationInviteResponse
 */
export class CreateOrganizationInviteResponse extends Message<CreateOrganizationInviteResponse> {
  /**
   * @generated from field: gitpod.v1.OrganizationInvite invite = 1;
   */
  invite?: OrganizationInvite;

  constructor(data?: PartialMessage<CreateOrganizationInviteResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.CreateOrganizationInviteResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "invite", kind: "message", T: OrganizationInvite },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateOrganizationInviteResponse {
    return new CreateOrganizationInviteResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateOrganizationInviteResponse {
    return new CreateOrganizationInviteResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateOrganizationInviteResponse {
    return new CreateOrganizationInviteResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateOrganizationInviteResponse | PlainMessage<CreateOrganizationInviteResponse> | undefined, b: CreateOrganizationInviteResponse | PlainMessage<CreateOrganizationInviteResponse> | undefined): boolean {
    return proto3.util.equals(CreateOrganizationInviteResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.GetOrganizationInviteSummaryRequest
 */
export class GetOrganizationInviteSummaryRequest extends Message<GetOrganizationInviteSummaryRequest> {
  /**
   * @generated from field: string invite_id = 1;
   */
  inviteId = "";

  constructor(data?: PartialMessage<GetOrganizationInviteSummaryRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.GetOrganizationInviteSummaryRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "invite_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetOrganizationInviteSummaryRequest {
    return new GetOrganizationInviteSummaryRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetOrganizationInviteSummaryRequest {
    return new GetOrganizationInviteSummaryRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetOrganizationInviteSummaryRequest {
    return new GetOrganizationInviteSummaryRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetOrganizationInviteSummaryRequest | PlainMessage<GetOrganizationInviteSummaryRequest> | undefined, b: GetOrganizationInviteSummaryRequest | PlainMessage<GetOrganizationInviteSummaryRequest> | undefined): boolean {
    return proto3.util.equals(GetOrganizationInviteSummaryRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.GetOrganizationInviteSummaryResponse
 */
export class GetOrganizationInviteSummaryResponse extends Message<GetOrganizationInviteSummaryResponse> {
  /**
   * @generated from field: string organization_id = 1;
   */
  organizationId = "";

  /**
   * @generated from field: string organization_name = 2;
   */
  organizationName = "";

  /**
   * @generated from field: int32 organization_member_count = 3;
   */
  organizationMemberCount = 0;

  constructor(data?: PartialMessage<GetOrganizationInviteSummaryResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.GetOrganizationInviteSummaryResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "organization_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "organization_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "organization_member_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetOrganizationInviteSummaryResponse {
    return new GetOrganizationInviteSummaryResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetOrganizationInviteSummaryResponse {
    return new GetOrganizationInviteSummaryResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetOrganizationInviteSummaryResponse {
    return new GetOrganizationInviteSummaryResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetOrganizationInviteSummaryResponse | PlainMessage<GetOrganizationInviteSummaryResponse> | undefined, b: GetOrganizationInviteSummaryResponse | PlainMessage<GetOrganizationInviteSummaryResponse> | undefined): boolean {
    return proto3.util.equals(GetOrganizationInviteSummaryResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.Organization
 */
export class Organization extends Message<Organization> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 3;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp updated_at = 4;
   */
  updatedAt?: Timestamp;

  /**
   * @generated from field: gitpod.v1.InviteDomains invite_domains = 5;
   */
  inviteDomains?: InviteDomains;

  constructor(data?: PartialMessage<Organization>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.Organization";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "created_at", kind: "message", T: Timestamp },
    { no: 4, name: "updated_at", kind: "message", T: Timestamp },
    { no: 5, name: "invite_domains", kind: "message", T: InviteDomains },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Organization {
    return new Organization().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Organization {
    return new Organization().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Organization {
    return new Organization().fromJsonString(jsonString, options);
  }

  static equals(a: Organization | PlainMessage<Organization> | undefined, b: Organization | PlainMessage<Organization> | undefined): boolean {
    return proto3.util.equals(Organization, a, b);
  }
}

/**
 * @generated from message gitpod.v1.OrganizationMember
 */
export class OrganizationMember extends Message<OrganizationMember> {
  /**
   * @generated from field: string user_id = 1;
   */
  userId = "";

  /**
   * @generated from field: gitpod.v1.OrganizationRole role = 2;
   */
  role = OrganizationRole.UNSPECIFIED;

  /**
   * @generated from field: google.protobuf.Timestamp member_since = 3;
   */
  memberSince?: Timestamp;

  /**
   * @generated from field: string avatar_url = 4;
   */
  avatarUrl = "";

  /**
   * @generated from field: string full_name = 5;
   */
  fullName = "";

  /**
   * @generated from field: string email = 6;
   */
  email = "";

  /**
   * @generated from field: gitpod.v1.UserStatus status = 7;
   */
  status = UserStatus.UNSPECIFIED;

  constructor(data?: PartialMessage<OrganizationMember>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.OrganizationMember";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "role", kind: "enum", T: proto3.getEnumType(OrganizationRole) },
    { no: 3, name: "member_since", kind: "message", T: Timestamp },
    { no: 4, name: "avatar_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "full_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "status", kind: "enum", T: proto3.getEnumType(UserStatus) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OrganizationMember {
    return new OrganizationMember().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OrganizationMember {
    return new OrganizationMember().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OrganizationMember {
    return new OrganizationMember().fromJsonString(jsonString, options);
  }

  static equals(a: OrganizationMember | PlainMessage<OrganizationMember> | undefined, b: OrganizationMember | PlainMessage<OrganizationMember> | undefined): boolean {
    return proto3.util.equals(OrganizationMember, a, b);
  }
}

/**
 * @generated from message gitpod.v1.InviteDomains
 */
export class InviteDomains extends Message<InviteDomains> {
  /**
   * domains is the list of domains that are allowed to join the organization
   *
   * @generated from field: repeated string domains = 1;
   */
  domains: string[] = [];

  constructor(data?: PartialMessage<InviteDomains>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.InviteDomains";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "domains", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InviteDomains {
    return new InviteDomains().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InviteDomains {
    return new InviteDomains().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InviteDomains {
    return new InviteDomains().fromJsonString(jsonString, options);
  }

  static equals(a: InviteDomains | PlainMessage<InviteDomains> | undefined, b: InviteDomains | PlainMessage<InviteDomains> | undefined): boolean {
    return proto3.util.equals(InviteDomains, a, b);
  }
}

/**
 * @generated from message gitpod.v1.CreateSSOConfigurationRequest
 */
export class CreateSSOConfigurationRequest extends Message<CreateSSOConfigurationRequest> {
  /**
   * @generated from field: string organization_id = 1;
   */
  organizationId = "";

  /**
   * client_id is the client ID of the OIDC application set on the IdP
   *
   * @generated from field: string client_id = 2;
   */
  clientId = "";

  /**
   * client_secret is the client secret of the OIDC application set on the IdP
   *
   * @generated from field: string client_secret = 3;
   */
  clientSecret = "";

  /**
   * issuer_url is the URL of the IdP issuer
   *
   * @generated from field: string issuer_url = 4;
   */
  issuerUrl = "";

  /**
   * email_domain is the domain that is allowed to sign in to the organization
   *
   * @generated from field: string email_domain = 5;
   */
  emailDomain = "";

  constructor(data?: PartialMessage<CreateSSOConfigurationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.CreateSSOConfigurationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "organization_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "client_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "client_secret", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "issuer_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "email_domain", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateSSOConfigurationRequest {
    return new CreateSSOConfigurationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateSSOConfigurationRequest {
    return new CreateSSOConfigurationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateSSOConfigurationRequest {
    return new CreateSSOConfigurationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateSSOConfigurationRequest | PlainMessage<CreateSSOConfigurationRequest> | undefined, b: CreateSSOConfigurationRequest | PlainMessage<CreateSSOConfigurationRequest> | undefined): boolean {
    return proto3.util.equals(CreateSSOConfigurationRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.CreateSSOConfigurationResponse
 */
export class CreateSSOConfigurationResponse extends Message<CreateSSOConfigurationResponse> {
  /**
   * sso_configuration is the created SSO configuration
   *
   * @generated from field: gitpod.v1.SSOConfiguration sso_configuration = 1;
   */
  ssoConfiguration?: SSOConfiguration;

  constructor(data?: PartialMessage<CreateSSOConfigurationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.CreateSSOConfigurationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "sso_configuration", kind: "message", T: SSOConfiguration },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateSSOConfigurationResponse {
    return new CreateSSOConfigurationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateSSOConfigurationResponse {
    return new CreateSSOConfigurationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateSSOConfigurationResponse {
    return new CreateSSOConfigurationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateSSOConfigurationResponse | PlainMessage<CreateSSOConfigurationResponse> | undefined, b: CreateSSOConfigurationResponse | PlainMessage<CreateSSOConfigurationResponse> | undefined): boolean {
    return proto3.util.equals(CreateSSOConfigurationResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.SSOConfiguration
 */
export class SSOConfiguration extends Message<SSOConfiguration> {
  /**
   * id is the unique identifier of the SSO configuration
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string organization_id = 2;
   */
  organizationId = "";

  /**
   * client_id is the client ID of the OIDC application set on the IdP
   *
   * @generated from field: string client_id = 3;
   */
  clientId = "";

  /**
   * issuer_url is the URL of the IdP issuer
   *
   * @generated from field: string issuer_url = 4;
   */
  issuerUrl = "";

  /**
   * state is the state of the SSO configuration
   *
   * @generated from field: gitpod.v1.SSOConfigurationState state = 5;
   */
  state = SSOConfigurationState.SSO_CONFIGURATION_STATE_UNSPECIFIED;

  /**
   * claims are key/value pairs that defines a mapping of claims issued by the IdP.
   *
   * @generated from field: map<string, string> claims = 6;
   */
  claims: { [key: string]: string } = {};

  /**
   * @generated from field: string email_domain = 7;
   */
  emailDomain = "";

  constructor(data?: PartialMessage<SSOConfiguration>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.SSOConfiguration";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "organization_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "client_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "issuer_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "state", kind: "enum", T: proto3.getEnumType(SSOConfigurationState) },
    { no: 6, name: "claims", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 7, name: "email_domain", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SSOConfiguration {
    return new SSOConfiguration().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SSOConfiguration {
    return new SSOConfiguration().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SSOConfiguration {
    return new SSOConfiguration().fromJsonString(jsonString, options);
  }

  static equals(a: SSOConfiguration | PlainMessage<SSOConfiguration> | undefined, b: SSOConfiguration | PlainMessage<SSOConfiguration> | undefined): boolean {
    return proto3.util.equals(SSOConfiguration, a, b);
  }
}

/**
 * @generated from message gitpod.v1.GetSSOConfigurationRequest
 */
export class GetSSOConfigurationRequest extends Message<GetSSOConfigurationRequest> {
  /**
   * sso_configuration_id is the ID of the SSO configuration to get
   *
   * @generated from field: string sso_configuration_id = 1;
   */
  ssoConfigurationId = "";

  constructor(data?: PartialMessage<GetSSOConfigurationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.GetSSOConfigurationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "sso_configuration_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSSOConfigurationRequest {
    return new GetSSOConfigurationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSSOConfigurationRequest {
    return new GetSSOConfigurationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSSOConfigurationRequest {
    return new GetSSOConfigurationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetSSOConfigurationRequest | PlainMessage<GetSSOConfigurationRequest> | undefined, b: GetSSOConfigurationRequest | PlainMessage<GetSSOConfigurationRequest> | undefined): boolean {
    return proto3.util.equals(GetSSOConfigurationRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.GetSSOConfigurationResponse
 */
export class GetSSOConfigurationResponse extends Message<GetSSOConfigurationResponse> {
  /**
   * sso_configuration is the SSO configuration identified by the ID
   *
   * @generated from field: gitpod.v1.SSOConfiguration sso_configuration = 1;
   */
  ssoConfiguration?: SSOConfiguration;

  constructor(data?: PartialMessage<GetSSOConfigurationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.GetSSOConfigurationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "sso_configuration", kind: "message", T: SSOConfiguration },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSSOConfigurationResponse {
    return new GetSSOConfigurationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSSOConfigurationResponse {
    return new GetSSOConfigurationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSSOConfigurationResponse {
    return new GetSSOConfigurationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetSSOConfigurationResponse | PlainMessage<GetSSOConfigurationResponse> | undefined, b: GetSSOConfigurationResponse | PlainMessage<GetSSOConfigurationResponse> | undefined): boolean {
    return proto3.util.equals(GetSSOConfigurationResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ListSSOConfigurationsRequest
 */
export class ListSSOConfigurationsRequest extends Message<ListSSOConfigurationsRequest> {
  /**
   * @generated from field: gitpod.v1.PaginationRequest pagination = 1;
   */
  pagination?: PaginationRequest;

  /**
   * organization_id is the ID of the organization to list SSO configurations for.
   *
   * @generated from field: string organization_id = 2;
   */
  organizationId = "";

  constructor(data?: PartialMessage<ListSSOConfigurationsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListSSOConfigurationsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pagination", kind: "message", T: PaginationRequest },
    { no: 2, name: "organization_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListSSOConfigurationsRequest {
    return new ListSSOConfigurationsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListSSOConfigurationsRequest {
    return new ListSSOConfigurationsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListSSOConfigurationsRequest {
    return new ListSSOConfigurationsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListSSOConfigurationsRequest | PlainMessage<ListSSOConfigurationsRequest> | undefined, b: ListSSOConfigurationsRequest | PlainMessage<ListSSOConfigurationsRequest> | undefined): boolean {
    return proto3.util.equals(ListSSOConfigurationsRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ListSSOConfigurationsResponse
 */
export class ListSSOConfigurationsResponse extends Message<ListSSOConfigurationsResponse> {
  /**
   * @generated from field: gitpod.v1.PaginationResponse pagination = 1;
   */
  pagination?: PaginationResponse;

  /**
   * sso_configurations are the SSO configurations for the organization
   *
   * @generated from field: repeated gitpod.v1.SSOConfiguration sso_configurations = 2;
   */
  ssoConfigurations: SSOConfiguration[] = [];

  constructor(data?: PartialMessage<ListSSOConfigurationsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListSSOConfigurationsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pagination", kind: "message", T: PaginationResponse },
    { no: 2, name: "sso_configurations", kind: "message", T: SSOConfiguration, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListSSOConfigurationsResponse {
    return new ListSSOConfigurationsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListSSOConfigurationsResponse {
    return new ListSSOConfigurationsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListSSOConfigurationsResponse {
    return new ListSSOConfigurationsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListSSOConfigurationsResponse | PlainMessage<ListSSOConfigurationsResponse> | undefined, b: ListSSOConfigurationsResponse | PlainMessage<ListSSOConfigurationsResponse> | undefined): boolean {
    return proto3.util.equals(ListSSOConfigurationsResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.UpdateSSOConfigurationRequest
 */
export class UpdateSSOConfigurationRequest extends Message<UpdateSSOConfigurationRequest> {
  /**
   * sso_configuration_id is the ID of the SSO configuration to update
   *
   * @generated from field: string sso_configuration_id = 1;
   */
  ssoConfigurationId = "";

  /**
   * client_id is the client ID of the SSO provider
   *
   * @generated from field: optional string client_id = 2;
   */
  clientId?: string;

  /**
   * client_secret is the client secret of the SSO provider
   *
   * @generated from field: optional string client_secret = 3;
   */
  clientSecret?: string;

  /**
   * issuer_url is the URL of the IdP issuer
   *
   * @generated from field: optional string issuer_url = 4;
   */
  issuerUrl?: string;

  /**
   * state is the state of the SSO configuration
   *
   * @generated from field: optional gitpod.v1.SSOConfigurationState state = 5;
   */
  state?: SSOConfigurationState;

  /**
   * claims are key/value pairs that defines a mapping of claims issued by the IdP.
   *
   * @generated from field: map<string, string> claims = 6;
   */
  claims: { [key: string]: string } = {};

  /**
   * @generated from field: optional string email_domain = 7;
   */
  emailDomain?: string;

  constructor(data?: PartialMessage<UpdateSSOConfigurationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.UpdateSSOConfigurationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "sso_configuration_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "client_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "client_secret", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "issuer_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "state", kind: "enum", T: proto3.getEnumType(SSOConfigurationState), opt: true },
    { no: 6, name: "claims", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 7, name: "email_domain", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateSSOConfigurationRequest {
    return new UpdateSSOConfigurationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateSSOConfigurationRequest {
    return new UpdateSSOConfigurationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateSSOConfigurationRequest {
    return new UpdateSSOConfigurationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateSSOConfigurationRequest | PlainMessage<UpdateSSOConfigurationRequest> | undefined, b: UpdateSSOConfigurationRequest | PlainMessage<UpdateSSOConfigurationRequest> | undefined): boolean {
    return proto3.util.equals(UpdateSSOConfigurationRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.UpdateSSOConfigurationResponse
 */
export class UpdateSSOConfigurationResponse extends Message<UpdateSSOConfigurationResponse> {
  constructor(data?: PartialMessage<UpdateSSOConfigurationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.UpdateSSOConfigurationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateSSOConfigurationResponse {
    return new UpdateSSOConfigurationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateSSOConfigurationResponse {
    return new UpdateSSOConfigurationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateSSOConfigurationResponse {
    return new UpdateSSOConfigurationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateSSOConfigurationResponse | PlainMessage<UpdateSSOConfigurationResponse> | undefined, b: UpdateSSOConfigurationResponse | PlainMessage<UpdateSSOConfigurationResponse> | undefined): boolean {
    return proto3.util.equals(UpdateSSOConfigurationResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.DeleteSSOConfigurationRequest
 */
export class DeleteSSOConfigurationRequest extends Message<DeleteSSOConfigurationRequest> {
  /**
   * @generated from field: string sso_configuration_id = 1;
   */
  ssoConfigurationId = "";

  constructor(data?: PartialMessage<DeleteSSOConfigurationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.DeleteSSOConfigurationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "sso_configuration_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteSSOConfigurationRequest {
    return new DeleteSSOConfigurationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteSSOConfigurationRequest {
    return new DeleteSSOConfigurationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteSSOConfigurationRequest {
    return new DeleteSSOConfigurationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteSSOConfigurationRequest | PlainMessage<DeleteSSOConfigurationRequest> | undefined, b: DeleteSSOConfigurationRequest | PlainMessage<DeleteSSOConfigurationRequest> | undefined): boolean {
    return proto3.util.equals(DeleteSSOConfigurationRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.DeleteSSOConfigurationResponse
 */
export class DeleteSSOConfigurationResponse extends Message<DeleteSSOConfigurationResponse> {
  constructor(data?: PartialMessage<DeleteSSOConfigurationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.DeleteSSOConfigurationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteSSOConfigurationResponse {
    return new DeleteSSOConfigurationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteSSOConfigurationResponse {
    return new DeleteSSOConfigurationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteSSOConfigurationResponse {
    return new DeleteSSOConfigurationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteSSOConfigurationResponse | PlainMessage<DeleteSSOConfigurationResponse> | undefined, b: DeleteSSOConfigurationResponse | PlainMessage<DeleteSSOConfigurationResponse> | undefined): boolean {
    return proto3.util.equals(DeleteSSOConfigurationResponse, a, b);
  }
}

