import { IconArrowLeft } from "@/assets/icons/geist/IconArrowLeft";
import { Button } from "@/components/flexkit/Button";
import { cn, type PropsWithClassName } from "@/components/podkit/lib/cn";
import { useCallback, type FC } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export const BackButton: FC<PropsWithClassName<{ showLabel?: boolean }>> = ({ className, showLabel }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const { environmentId } = useParams();

    const isTaskRunsPage = location.pathname.includes("/tasks");
    const isTaskRunLogPage = location.pathname.includes("/task/");
    const isServiceLogsPage = location.pathname.includes("/services/");
    const isEnvironmentLogsPage = location.pathname.endsWith(`${environmentId}/logs`);

    const onClick = useCallback(() => {
        if (!environmentId) {
            return;
        }
        if (!isTaskRunsPage && !isServiceLogsPage && !isEnvironmentLogsPage && !isTaskRunLogPage) {
            return;
        }

        if (isTaskRunLogPage) {
            navigate(`/details/${environmentId}/tasks`);
            return;
        }

        navigate(`/details/${environmentId}`);
    }, [environmentId, isEnvironmentLogsPage, isServiceLogsPage, isTaskRunLogPage, isTaskRunsPage, navigate]);

    return (
        <Button
            disabled={!environmentId}
            variant={"ghost"}
            className={cn("border-transparent pl-2 text-lg font-bold", className)}
            LeadingIcon={IconArrowLeft}
            onClick={onClick}
            data-track-label="true"
        >
            {(showLabel ?? true) && <span>Back</span>}
        </Button>
    );
};
