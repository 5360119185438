import { useGetAccount } from "@/queries/account-queries";
import { useContext, useEffect } from "react";
import { useAuthenticatedUser } from "@/queries/user-queries";
import { SegmentContext } from "@/contexts/SegmentContext";
import { BuildVersion } from "@/utils/version";
import type { MuxPlayerRefAttributes } from "@mux/mux-player-react";

export const useSegment = (): SegmentContext => useContext(SegmentContext);

export const useSegmentIdentify = () => {
    const segment = useSegment();
    const { data: account } = useGetAccount();

    useEffect(() => {
        if (!segment) {
            console.debug("segment not available");
            return;
        }

        if (!account) {
            console.debug("Account not available");
            return;
        }
        segment
            .identify(account.id, {
                name: account.name,
                email: account.email,
                avatar: account.avatarUrl,
            })
            .catch(console.error);
    }, [account, segment]);
};

export const useResetSegment = () => {
    const segment = useSegment();

    return () => {
        if (!segment) {
            console.debug("segment not available");
            return;
        }

        segment.reset().catch(console.error);
    };
};

function isMuxPlayerElement(element: Element): element is MuxPlayerRefAttributes {
    return element.tagName.toLowerCase() === "mux-player";
}

type NavigatorUA = {
    readonly userAgentData?: NavigatorUAData;
} & Navigator;
type NavigatorUAData = {
    getHighEntropyValues(hints: string[]): Promise<UADataValues>;
};
type UADataValues = {
    readonly architecture?: string;
};
function isNavigatorUA(n: Navigator): n is NavigatorUA {
    return "userAgentData" in n;
}

let architecture: string | undefined;
void (async () => {
    try {
        if (isNavigatorUA(navigator) && navigator.userAgentData?.getHighEntropyValues) {
            const userData = await navigator.userAgentData.getHighEntropyValues(["architecture"]);
            if (userData.architecture) {
                architecture = userData.architecture;
            }
        }
    } catch (error) {
        console.error("Error fetching architecture:", error);
    }
})();

export const useSegmentTrack = () => {
    const segment = useSegment();
    const { data: user } = useAuthenticatedUser();
    const { data: account } = useGetAccount();

    return (eventName: string, properties?: Record<string, unknown>) => {
        if (!segment) {
            console.debug("segment not available");
            return;
        }

        const props = properties || {};
        if (user) {
            props.user_id = user.id;
            props.auth_principal = "user";
            props.auth_id = user.id;
            props.account_id = account?.id;
            props.organization_id = user.organizationId;
        }
        if (window.isWebview) {
            props.client = "vscode";
        } else if (window.ipcRenderer) {
            props.client = "desktop";
        } else {
            props.client = "web";
        }
        props.dashboardVersion = BuildVersion;
        if (architecture) {
            props.architecture = architecture;
        }

        segment.track(eventName, props).catch(console.error);
    };
};

const findLocation = (target: HTMLElement) => {
    const element = target.closest(`[data-track-location]`);
    return element?.getAttribute("data-track-location") || undefined;
};

export const useButtonOrAnchorTracking = () => {
    const track = useSegmentTrack();

    useEffect(() => {
        const trackButtonOrAnchor = (
            target: HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | MuxPlayerRefAttributes,
        ) => {
            const trackingMsg: ButtonOrAnchorTrackingProps = {
                path: window.location.pathname,
                location: findLocation(target),
                label: target.ariaLabel || target.textContent || undefined,
            };
            const trackLabel = target.dataset.trackLabel;
            if (!target.dataset.trackLabel) {
                trackingMsg.label = "not-tracked";
            } else if (trackLabel !== "true") {
                trackingMsg.label = trackLabel;
            }

            if (target instanceof HTMLButtonElement || target instanceof HTMLDivElement) {
                //retrieve href if parent is an anchor element
                if (target.parentElement instanceof HTMLAnchorElement) {
                    const anchor = target.parentElement;
                    trackingMsg.destination = anchor.href;
                }
            }

            if (target instanceof HTMLAnchorElement) {
                const anchor = target;
                trackingMsg.destination = anchor.href;
            }

            track("dashboard_clicked", trackingMsg);
        };

        const handleButtonOrAnchorTracking = (props: MouseEvent) => {
            const root = document.getElementById("root");
            let curr = props.target as HTMLElement;

            while (!(curr instanceof Document) && curr !== root) {
                if (
                    curr instanceof HTMLButtonElement ||
                    curr instanceof HTMLAnchorElement ||
                    (curr instanceof HTMLDivElement && curr.onclick) ||
                    isMuxPlayerElement(curr)
                ) {
                    trackButtonOrAnchor(curr);
                    break; //finding first ancestor is sufficient
                }
                curr = curr.parentNode as HTMLElement;
            }
        };

        window.addEventListener("click", handleButtonOrAnchorTracking, true);
        return () => window.removeEventListener("click", handleButtonOrAnchorTracking, true);
    });
};

type ButtonOrAnchorTrackingProps = {
    path: string;
    location?: string;
    label: string | undefined;
    destination?: string;
};

export const TrackLocations = {
    Sidebar: "sidebar",
    SidebarOrgSwitcher: "sidebar_org_switcher",
    SidebarEnvironmentList: "sidebar_environment_list",
    SidebarCreateEnvironmentModal: "sidebar_create_environment_modal",
    NewPersonalAccessTokenModal: "new_personal_access_token_modal",
    DeletePersonalAccessTokenModal: "delete_personal_access_token_modal",
    DeleteOrganizationModal: "delete_organization_modal",
    InventoryStopEnvironmentModal: "inventory_stop_environment_modal",
    InventoryDeleteEnvironmentModal: "inventory_delete_environment_modal",
    ProjectsPageCreateEnvironmentModal: "projects_page_create_environment_modal",
    ProjectDeleteModal: "project_delete_modal",
    EditProjectModal: "edit_project_modal",
    CreateProjectModal: "create_project_modal",
    FlexUpgradeModal: "flex_upgrade_modal",
    CreateEnvironmentSCMAuthenticationModal: "create_environment_scm_authentication_modal",
    EnvironmentSCMAuthenticationModal: "environment_scm_authentication_modal",
    EnvironmentSSHFormPopover: "environment_ssh_form_popover",
    EnvironmentOpenPortPoppover: "environment_open_port_poppover",
    EnvironmentOnboarding: "environment_onboarding",
    EnvironmentOnboardingDevcontainer: "environment_onboarding_devcontainer",
    EnvironmentOnboardingAutomations: "environment_onboarding_automations",
    EnvironmentOnboardingCommitFiles: "environment_onboarding_commit_files",
    EnvironmentOnboardingShare: "environment_onboarding_share",
    EnvironmentOnboardingDevContainerTemplateModal: "environment_onboarding_dev_container_template_modal",
    EnvironmentOnboardingAutomationsTemplateModal: "environment_onboarding_automations_template_modal",
    EnvironmentOnboardingCreateProjectFromEnvironmentModal:
        "environment_onboarding_create_project_from_environment_modal",
    DeleteSecretModal: "delete_secret_modal",
    NewSecretModal: "new_secret_modal",
    EditSecretModal: "edit_secret_modal",
    DeleteRunnerModal: "delete_runner_modal",
    ForceDeleteRunnerModal: "force_delete_runner_modal",
    NewRunnerModal: "new_runner_modal",
    RenameRunnerModal: "rename_runner_modal",
    OrganizationMemberSuspendModal: "organization_member_suspend_modal",
    OrganizationMemberChangeRoleModal: "organization_member_change_role_modal",
    OrganizationMemberLeaveModal: "organization_member_leave_modal",
    OnboardingHowGitpodWorksTab: "onboarding_how_gitpod_works_tab",
    OnboardingRunnerSelectTab: "onboarding_runner_select_tab",
    OnboardingRunnerSelectProviderTab: "onboarding_runner_select_provider_tab",
    OnboardingRunnerConfigureTab: "onboarding_runner_configure_tab",
    OnboardingRunnerCreateRunnerTabStep: "onboarding_runner_create_runner_tab_step",
    OnboardingRunnerConfigureCloudFormationStackTabStep: "onboarding_runner_configure_cloud_formation_stack_tab_step",
    OnboardingRunnerConfigureSCMTabStep: "onboarding_runner_configure_scm_tab_step",
    OnboardingRunnerAddSCMProviderModal: "onboarding_runner_add_scm_provider_modal",
    OnboardingRunnerEditSCMProviderModal: "onboarding_runner_edit_scm_provider_modal",
    OnboardingRunnerDeleteSCMProviderModal: "onboarding_runner_delete_scm_provider_modal",
    OnboardingRunnerConfigureEnvironmentClassesTabStep: "onboarding_runner_configure_environment_classes_tab_step",
    OnboardingRunnerAddEnvironmentClassModal: "onboarding_runner_add_environment_class_modal",
    OnboardingRunnerEditEnvironmentClassModal: "onboarding_runner_edit_environment_class_modal",
    OnboardingAutomateTab: "onboarding_automate_tab",
    OnboardingAutomateSCMAuthenticationModal: "onboarding_automate_scm_authentication_modal",
    OnboardingShareTab: "onboarding_share_tab",
    DeleteEnvironmentModal: "delete_environment_modal",
    ForceDeleteEnvironmentModal: "force_delete_environment_modal",
    AddSCMProviderModal: "add_scm_provider_modal",
    EditSCMProviderModal: "edit_scm_provider_modal",
    DeleteSCMProviderModal: "delete_scm_provider_modal",
    AddEnvironmentClassModal: "add_environment_class_modal",
    EditEnvironmentClassModal: "edit_environment_class_modal",
    AnnouncementBannerPopover: "announcement_banner_popover",
    NudgeDownloadAppPopover: "nudge_download_app_popover",
    GitAuthenticationTokenDeleteModal: "git_authentication_token_delete_modal",
} as const;
export type TrackLocation = (typeof TrackLocations)[keyof typeof TrackLocations];
