import { IconClose } from "@/assets/icons/geist/IconClose";
import { IconCopy } from "@/assets/icons/geist/IconCopy";
import { IconDot } from "@/assets/icons/geist/IconDot";
import { IconPlaceholder } from "@/assets/icons/geist/IconPlaceholder";
import { EnvironmentDetailsEmptySection } from "@/components/environments/EnvironmentDetailsEmptySection";
import { OpenPortPopover } from "@/components/environments/OpenPortForm";
import { Button } from "@/components/flexkit/Button";
import { cn } from "@/components/podkit/lib/cn";
import { useToast } from "@/components/podkit/toasts/use-toast";
import { Heading2 } from "@/components/podkit/typography/Headings";
import { Text } from "@/components/podkit/typography/Text";
import { useEnvironment, useUpdateEnvironment } from "@/queries/environment-queries";
import { canOpen } from "@/routes/environments/phase";
import { formatError } from "@/utils/errors";
import { type PlainMessage } from "@bufbuild/protobuf";
import {
    AdmissionLevel,
    EnvironmentSpec,
    EnvironmentSpec_EnvironmentPort,
    UpdateEnvironmentRequest,
} from "gitpod-next-api/gitpod/v1/environment_pb";
import { useCallback, useMemo, useRef, type FC } from "react";

type Port = PlainMessage<EnvironmentSpec_EnvironmentPort> & { url?: string };

export const EnvironmentPorts: FC<{ environmentId: string }> = ({ environmentId }) => {
    const anchor = useRef<HTMLButtonElement>(null);
    const { data: environment, isLoading: isLoadingEnvironment } = useEnvironment(environmentId);

    const ports = useMemo(() => {
        if (!environment) {
            return [];
        }
        const result: Port[] = (environment?.spec?.ports || []).map((spec) => {
            const status = (environment?.status?.environmentUrls?.ports || []).find((p) => p.port === spec.port);
            return {
                ...spec,
                url: status?.url,
            };
        });
        return result;
    }, [environment]);

    const hide = useMemo(() => {
        // Once we have ports we always want to show the section
        if (ports && ports.length > 0) {
            return false;
        }

        // Until we have the environment we don't know if we should show the empty state, so default to not showing it
        // in order to avoid flickering
        if (isLoadingEnvironment) {
            return true;
        }

        // If the environment is not created from a project then we show the onboarding section rather than
        // the empty state for ports
        if (!environment?.metadata?.projectId) {
            return true;
        }

        return false;
    }, [isLoadingEnvironment, environment, ports]);

    if (hide) {
        return null;
    }

    const disabled = !environment || !canOpen(environment);

    // Open Ports functionality is moved to the action bar (which is now visible even in VS Code)
    // Ports will be moved to the modal in NEXT-1854
    if (ports.length === 0) {
        return null;
    }

    return (
        <div>
            <div className="flex items-center justify-between py-4 align-middle" translate="no">
                <Heading2 className="text-xl font-bold text-content-primary">Public Ports</Heading2>
                <OpenPortPopover
                    environmentId={environmentId}
                    button={
                        <Button
                            ref={anchor}
                            variant="secondary"
                            disabled={disabled}
                            className={ports?.length ? "" : "opacity-0"}
                            data-track-label="true"
                        >
                            Open Port
                        </Button>
                    }
                    anchor={anchor}
                />
            </div>
            <PortsTable ports={ports} environmentId={environmentId} disabled={disabled} />
        </div>
    );
};

const PortsTable: FC<{ ports: Port[]; environmentId: string; disabled?: boolean }> = ({
    ports,
    environmentId,
    disabled,
}) => {
    const anchor = useRef<HTMLButtonElement>(null);
    if (ports.length === 0) {
        return (
            <EnvironmentDetailsEmptySection
                icon={<IconPlaceholder kind="port" />}
                title="Open a port to share an environment"
                text="When you open a port, it will be available on the open internet. Be careful when sharing these."
                docsUrl="https://www.gitpod.io/docs/flex/integrations/ports"
                action={
                    <OpenPortPopover
                        environmentId={environmentId}
                        button={
                            <Button ref={anchor} variant="secondary" disabled={disabled} data-track-label="true">
                                Open Port
                            </Button>
                        }
                        anchor={anchor}
                    />
                }
            />
        );
    }

    const threeCols = ports.length > 2;
    const twoCols = ports.length > 1;

    return (
        <div
            className={cn("grid grid-cols-1 gap-4", twoCols ? "lg:grid-cols-2" : "", threeCols ? "xl:grid-cols-3" : "")}
        >
            {ports.map((port, i) => (
                <PortCard
                    key={`${port.name}-${port.port}-${i}`}
                    port={port}
                    environmentId={environmentId}
                    disabled={disabled}
                />
            ))}
        </div>
    );
};

const PortCard: FC<{ port: Port; environmentId: string; disabled?: boolean }> = ({ port, environmentId, disabled }) => {
    const updateEnvironment = useUpdateEnvironment();
    const { toast } = useToast();
    const onCopyUrl = useCallback(async () => {
        if (!port.url) {
            return;
        }
        try {
            await navigator.clipboard.writeText(port.url);
            toast({
                title: "URL copied to clipboard",
                description: port.url,
            });
        } catch (error) {
            toast({
                title: "Failed to copy URL to your clipboard",
                description: formatError(error),
            });
        }
    }, [port.url, toast]);

    const handleDelete = useCallback(async () => {
        if (!port.url) {
            return;
        }
        try {
            await updateEnvironment.mutateAsync({
                req: new UpdateEnvironmentRequest({
                    environmentId,
                    spec: new EnvironmentSpec({
                        ports: [
                            new EnvironmentSpec_EnvironmentPort({
                                port: port.port,
                                admission: AdmissionLevel.UNSPECIFIED,
                                name: port.name,
                            }),
                        ],
                    }),
                }),
            });
            toast({
                title: `Port "${port.name}" deleted`,
            });
        } catch (error) {
            toast({
                title: "Failed to delete port",
                description: formatError(error),
            });
        }
    }, [environmentId, port.name, port.port, port.url, toast, updateEnvironment]);

    const isActive = !!port.url;

    return (
        <div className="group flex h-16 items-center gap-2 truncate rounded-xl border-[0.5px] border-border-base bg-surface-glass px-4 py-2.5 pl-3 hover:shadow">
            <div className="flex grow flex-row items-start gap-2 truncate">
                <div className="flex size-6 items-start">
                    <IconDot size={"lg"} className={isActive ? "text-content-green" : "text-content-tertiary"} />
                </div>
                <div className="flex grow flex-col truncate">
                    <Text className="truncate text-base font-bold text-content-primary">{port.name}</Text>
                    <Text className="truncate text-sm text-content-secondary">{port.port}</Text>
                </div>
            </div>
            <div className="hidden flex-row items-center gap-2 group-hover:flex">
                <Button
                    variant="secondary"
                    disabled={disabled || !port.url}
                    onClick={async (e) => {
                        e.stopPropagation();
                        await onCopyUrl();
                    }}
                    LeadingIcon={IconCopy}
                    size="sm"
                    data-track-label="true"
                >
                    Copy URL
                </Button>
                <Button
                    variant="link"
                    disabled={disabled}
                    onClick={async (e) => {
                        e.stopPropagation();
                        await handleDelete();
                    }}
                    LeadingIcon={IconClose}
                    size="sm"
                    data-track-label="true"
                />
            </div>
        </div>
    );
};
