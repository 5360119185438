import { ipcEvent, ipcNotification, ipcRequest, type IPCService } from "./ipc";

export function getScmProviderName(scmId?: string) {
    switch (scmId) {
        case "github":
            return "GitHub";
        case "gitlab":
            return "GitLab";
        case "bitbucket":
            return "Bitbucket";
        default:
            return "Source Control";
    }
}

export type LocalRunnerInstallationStep = {
    name: string;
    start: boolean;
    done: boolean;
    messages: string[];
    error?: string;
};
export type LocalRunnerInstallationState = {
    steps: Record<number, LocalRunnerInstallationStep>;
    exitCode?: number;
    needsElevatedPermissions?: boolean;
    userDidNotGrantPermissions?: boolean;
};

export type LocalRunnerPhase =
    | "installing"
    | "signing"
    | "starting"
    | "running"
    | "disconnected"
    | "shutdown"
    | "failed";

export type LocalRunnerFailure = {
    message: string;
    details?: string;
};

export type LocalRunnerStatus = {
    phase: LocalRunnerPhase;
    failure?: LocalRunnerFailure;
    installation?: LocalRunnerInstallationState;
    currentRunnerId?: string;
};
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace LocalRunnerStatus {
    export function needsRunnerScreen(status: LocalRunnerStatus | undefined): boolean {
        return !!status && (status.phase === "installing" || status.phase === "failed");
    }
}

export type CreateRunnerParam = {
    runnerName: string;
    userId: string;
};

export type CreateRunnerResult = {
    runnerId: string;
    token: string;
    endpoint: string;
};

export type CreateRunnerTokenParam = {
    runnerId: string;
    userId: string;
};

export type CreateRunnerTokenResult = {
    token: string;
};

export type DidChangeUserEvent = {
    userId: string | undefined;
};

export type DeleteRunnerParam = {
    runnerId: string;
    userId: string;
    force?: boolean;
}

export const localRunnerRendererType = {
    type: "LocalRunnerRendererService",
    methods: {
        createRunner: ipcRequest<CreateRunnerParam, CreateRunnerResult>(),
        createRunnerToken: ipcRequest<CreateRunnerTokenParam, CreateRunnerTokenResult>(),
        onDidChangeUser: ipcEvent<DidChangeUserEvent>(),
        deleteRunner: ipcRequest<DeleteRunnerParam, void>(),
    },
};
export type LocalRunnerRendererService = IPCService<typeof localRunnerRendererType>;

export const localRunnerMainType = {
    type: "LocalRunnerMainService",
    methods: {
        onRunnerStatus: ipcEvent<LocalRunnerStatus>(),
        run: ipcNotification<{ elevatePermissions?: boolean }>(),
    },
};
export type LocalRunnerMainService = IPCService<typeof localRunnerMainType>;

export const appMainType = {
    type: "AppMainService",
    methods: {
        quit: ipcNotification<void>(),
        signin: ipcRequest<{ host: string }, string>(),
        collectLogsAndDiagnostics: ipcNotification<void>(),
    },
};
export type AppMainService = IPCService<typeof appMainType>;
