import { ErrorMessage } from "@/components/ErrorMessage";
import { EnvironmentLogs } from "@/components/EnvironmentLogs";
import { useDocumentTitle } from "@/hooks/use-document-title";
import { useEnvironment } from "@/queries/environment-queries";
import { Text } from "@/components/podkit/typography/Text";
import { useMemo, type FC } from "react";
import { useParams } from "react-router-dom";
import { Code } from "@connectrpc/connect";
import { EnvironmentDetailsHeader } from "@/components/environments/EnvironmentDetailsHeader";
import { cn } from "@/components/podkit/lib/cn";
import { BackButton } from "@/components/environments/BackButton";

export const EnvironmentLogsPage: FC = () => {
    const { environmentId } = useParams();
    useDocumentTitle("Environment - Details");

    const { data: environment, error } = useEnvironment(environmentId);

    const logsUrl = useMemo(() => {
        if (!environment?.status?.environmentUrls?.logs) {
            return;
        }
        // We're adding the status machine session to the URL to trigger a refresh when a new machine session starts
        // The macOS runner resets the log stream, so this ensures we clear our logs as well.
        return `${environment?.status?.environmentUrls?.logs}#${environment?.status?.machine?.session}`;
    }, [environment?.status?.environmentUrls?.logs, environment?.status?.machine?.session]);

    if (error) {
        return (
            <div className="flex size-full flex-col items-center justify-center">
                <ErrorMessage
                    error={error}
                    messages={{
                        [Code.InvalidArgument]: "Invalid environment ID",
                    }}
                />
                <Text className="text-sm text-content-negative">Environment ID: {environmentId}</Text>
            </div>
        );
    }

    return (
        <div className={cn("flex size-full flex-col", "p-2")}>
            {environmentId && (
                <div className="flex flex-col gap-4">
                    <EnvironmentDetailsHeader showActionBar={false} />
                    <div className="flex justify-between pb-4">
                        <BackButton />
                    </div>
                </div>
            )}
            <EnvironmentLogs
                environment={environment}
                logsUrl={logsUrl}
                // We're checking for timestamps here as a way to filter out log lines that supervisor is not
                // intentionally emitting. This allows us to clean up the log output even for legacy runners
                filterLogLinesWithoutTimestamps={true}
            />
        </div>
    );
};
