import { Button } from "@/components/flexkit/Button";
import { Heading1 } from "@/components/podkit/typography/Headings";
import { QueryErrorResetBoundary, useQueryErrorResetBoundary } from "@tanstack/react-query";
import { useCallback, useEffect, useRef, type FC, type PropsWithChildren } from "react";
import { ErrorBoundary, type FallbackProps } from "react-error-boundary";
import { FrownIcon } from "lucide-react";
import { useSegmentTrack } from "@/hooks/use-segment";
import { ConnectError } from "@connectrpc/connect";
import { useIntercomTrack, useNewIntercomMessage } from "@/hooks/use-intercom";

export const ExceptionErrorBoundary: FC<PropsWithChildren> = ({ children }) => {
    return (
        <QueryErrorResetBoundary>
            {({ reset }) => (
                <ErrorBoundary onReset={reset} FallbackComponent={ExceptionHandler}>
                    {children}
                </ErrorBoundary>
            )}
        </QueryErrorResetBoundary>
    );
};

export const ExceptionHandler: FC<FallbackProps> = ({ resetErrorBoundary, error }) => {
    const { reset: resetQueryError } = useQueryErrorResetBoundary();

    const trackSegment = useSegmentTrack();
    const trackIntercom = useIntercomTrack();
    const track = useCallback(
        (eventName: string, properties?: Record<string, string | undefined>) => {
            trackSegment(eventName, properties);
            trackIntercom(eventName, properties);
        },
        [trackSegment, trackIntercom],
    );

    // For deduplication of events.
    const calledOnce = useRef(false);

    useEffect(() => {
        if (!track || !error) {
            return; // ignore if no error to track
        }
        if (calledOnce.current) {
            return;
        }
        calledOnce.current = true;
        if (error instanceof ConnectError) {
            track("dashboard_error", {
                errorMessage: error?.message,
                errorCode: String(error?.code),
                stack: error?.stack,
            });
        } else if (error instanceof Error) {
            track("dashboard_error", { errorName: error.name, errorMessage: error.message, stack: error.stack });
        } else {
            track("dashboard_error", { errorName: "unknown" });
        }
        console.error("Caught error", error);
    }, [track, error]);

    const doRefresh = useCallback(() => {
        resetQueryError();
        resetErrorBoundary();
    }, [resetErrorBoundary, resetQueryError]);

    const showNewIntercomMessage = useNewIntercomMessage();

    const doTellUsMore = useCallback(() => {
        showNewIntercomMessage("Hi! I ran into a problem and wanted to share some details to help improve Gitpod.");
    }, [showNewIntercomMessage]);

    if (error instanceof Error && error.message.includes("Failed to fetch dynamically imported module")) {
        window.location.reload();
    }

    return (
        <div data-testid="exception-handler" className="fixed flex h-full w-full justify-center">
            <div className="flex w-96 flex-col gap-4 self-center">
                <Heading1 className="text-center">Something went wrong</Heading1>
                <div className="flex items-center justify-center">
                    <FrownIcon className="size-16" />
                </div>
                <div>
                    Uh oh. We ran into a hiccup and cannot load this page. Our team has been alerted of the issue. You
                    can try refreshing the page.
                </div>
                <div className="flex flex-row gap-2">
                    <Button type="button" variant="brand" onClick={doRefresh} className="max-w-1/2 flex-grow">
                        Refresh Page
                    </Button>

                    <Button type="button" variant="secondary" onClick={doTellUsMore} className="max-w-1/2 flex-grow">
                        Tell Us More
                    </Button>
                </div>
            </div>
        </div>
    );
};
