import { useGitpodAPI } from "@/hooks/use-gitpod-api";
import { keyWithPrincipal } from "@/queries/principal-key";
import { useAuthenticatedUser } from "@/queries/user-queries";
import { toPlainMessage } from "@bufbuild/protobuf";
import { type QueryClient, useMutation, useQueries, useQuery, useQueryClient } from "@tanstack/react-query";
import {
    CreateEnvironmentClassRequest,
    CreateHostAuthenticationTokenRequest,
    CreateSCMIntegrationRequest,
    DeleteSCMIntegrationRequest,
    EnvironmentClass,
    EnvironmentClassValidationResult,
    type FieldValue,
    GetEnvironmentClassRequest,
    GetRunnerConfigurationSchemaRequest,
    type HostAuthenticationToken,
    HostAuthenticationTokenSource,
    ListEnvironmentClassesRequest,
    ListHostAuthenticationTokensRequest,
    ListSCMIntegrationsRequest,
    SCMIntegration,
    SCMIntegrationValidationResult,
    UpdateEnvironmentClassRequest,
    UpdateSCMIntegrationRequest,
    ValidateRunnerConfigurationRequest,
    ValidateRunnerConfigurationRequest_ValidateSCMIntegration,
} from "gitpod-next-api/gitpod/v1/runner_configuration_pb";

export const runnerConfigurationQueryKey = {
    list: () => keyWithPrincipal(["hostAuthenticationTokens", "list"]),
    listRunnerSCMIntegrations: (runnerId?: string) => keyWithPrincipal(["listRunnerSCMIntegrations", runnerId]),
    listRunnerEnvironmentClasses: (runnerId?: string) => keyWithPrincipal(["listRunnerEnvironmentClasses", runnerId]),
    getEnvironmentClass: (environmentClassId?: string) => keyWithPrincipal(["getEnvironmentClass", environmentClassId]),
    getRunnerConfigurationSchema: (runnerId: string) => keyWithPrincipal(["getRunnerConfigurationSchema", runnerId]),
};

export const useCreateHostAuthenticationTokenPAT = () => {
    const api = useGitpodAPI();
    const { data: user } = useAuthenticatedUser();

    return useMutation({
        mutationFn: async (options: { pat: string; runnerId: string; scmHost: string }) => {
            if (!user) {
                throw new Error("User not authenticated");
            }

            const { token } = await api.runnerConfigurationService.createHostAuthenticationToken(
                new CreateHostAuthenticationTokenRequest({
                    userId: user.id,
                    runnerId: options.runnerId,
                    host: options.scmHost,
                    token: options.pat,
                    source: HostAuthenticationTokenSource.PAT,
                }),
            );

            return token;
        },
    });
};

// Creates a host authentication token for a runner from a personal access token,
// verifies if the token can be used to access a repository, and returns the token.
// If the token does not have the required permissions, it is deleted and an error is thrown.
export const useCreateAndVerifyHostAuthenticationTokenPAT = () => {
    const api = useGitpodAPI();
    const { data: user } = useAuthenticatedUser();

    return useMutation({
        mutationFn: async (options: { pat: string; runnerId: string; scmHost: string; repoUrl: string }) => {
            if (!user) {
                throw new Error("User not authenticated");
            }

            const { token } = await api.runnerConfigurationService.createHostAuthenticationToken(
                new CreateHostAuthenticationTokenRequest({
                    userId: user.id,
                    runnerId: options.runnerId,
                    host: options.scmHost,
                    token: options.pat,
                    source: HostAuthenticationTokenSource.PAT,
                }),
            );

            // Parse context URL to check if the token is valid and has access to the repo.
            try {
                await api.runnerService.parseContextURL({
                    contextUrl: options.repoUrl,
                    runnerId: options.runnerId,
                });
            } catch (error) {
                // Delete the token if it's invalid.
                if (token) {
                    await api.runnerConfigurationService.deleteHostAuthenticationToken({
                        id: token.id,
                    });
                }

                throw error;
            }

            return token;
        },
    });
};

export const useDeleteHostAuthenticationTokenForHost = () => {
    const api = useGitpodAPI();
    const { data: user } = useAuthenticatedUser();

    return useMutation({
        mutationFn: async (options: { runnerId: string; scmHost: string }) => {
            if (!user) {
                throw new Error("User not authenticated");
            }

            const { tokens } = await api.runnerConfigurationService.listHostAuthenticationTokens(
                new ListHostAuthenticationTokensRequest({
                    filter: {
                        userId: user.id,
                        runnerId: options.runnerId,
                    },
                }),
            );

            const token = tokens.find((token) => token.host === options.scmHost);
            if (!token) {
                // Already deleted
                return;
            }

            await api.runnerConfigurationService.deleteHostAuthenticationToken({
                id: token.id,
            });

            return;
        },
    });
};

export const useDeleteHostAuthenticationToken = () => {
    const api = useGitpodAPI();
    const client = useQueryClient();
    const { data: user } = useAuthenticatedUser();
    return useMutation({
        mutationFn: async (options: { tokenId: string }) => {
            if (!user) {
                throw new Error("User not authenticated");
            }
            await api.runnerConfigurationService.deleteHostAuthenticationToken({
                id: options.tokenId,
            });
            optimisticDeleteHostAuthenticationToken(client, options.tokenId);
        },
    });
};

export const useListHostAuthenticationTokens = () => {
    const api = useGitpodAPI();
    const { data: user } = useAuthenticatedUser();
    return useQuery({
        queryKey: runnerConfigurationQueryKey.list(),
        queryFn: async () => {
            if (!user) {
                throw new Error("User not authenticated");
            }
            const { tokens, pagination } = await api.runnerConfigurationService.listHostAuthenticationTokens(
                new ListHostAuthenticationTokensRequest({
                    filter: {
                        userId: user.id,
                    },
                }),
            );

            return {
                tokens: tokens.map((token) => toPlainMessage(token)),
                pagination,
            };
        },
        enabled: !!user,
        staleTime: 500,
    });
};

export const useRunnerConfigurationSchema = (runnerId: string | undefined) => {
    const api = useGitpodAPI();
    const { data: user } = useAuthenticatedUser();
    return useQuery({
        queryKey: runnerConfigurationQueryKey.getRunnerConfigurationSchema(runnerId || "no-runner"),
        queryFn: async () => {
            if (!user) {
                throw new Error("User not authenticated");
            }
            const { schema } = await api.runnerConfigurationService.getRunnerConfigurationSchema(
                new GetRunnerConfigurationSchemaRequest({
                    runnerId: runnerId,
                }),
            );

            return schema;
        },
        enabled: !!user && !!runnerId,
        staleTime: 500,
    });
};

export type IntegrationConfiguration = {
    scmId: string;
    host?: string;
    pat?: boolean;
    oauth?: { clientId: string; clientSecret?: string; encryptedClientSecret?: Uint8Array };
};

export const useCreateOrUpdateSCMIntegration = (runnerId: string, integrationId: string | undefined) => {
    const api = useGitpodAPI();
    const client = useQueryClient();
    const { data: user } = useAuthenticatedUser();

    return useMutation({
        mutationFn: async (configuration: IntegrationConfiguration) => {
            if (!user) {
                throw new Error("User not authenticated");
            }

            const { result } = await api.runnerConfigurationService.validateRunnerConfiguration(
                new ValidateRunnerConfigurationRequest({
                    runnerId,
                    config: {
                        case: "scmIntegration",
                        value: new ValidateRunnerConfigurationRequest_ValidateSCMIntegration({
                            host: configuration.host,
                            pat: configuration.pat,
                            scmId: configuration.scmId,
                            oauthClientId: configuration.oauth?.clientId,
                            oauthClientSecret: configuration.oauth?.clientSecret
                                ? {
                                      case: "oauthPlaintextClientSecret",
                                      value: configuration.oauth?.clientSecret,
                                  }
                                : undefined,
                        }),
                    },
                }),
            );

            if (result.case === "scmIntegration" && !result.value.valid) {
                return result.value;
            }

            if (!integrationId) {
                const { id: newId } = await api.runnerConfigurationService.createSCMIntegration(
                    new CreateSCMIntegrationRequest({
                        runnerId,
                        host: configuration.host,
                        pat: configuration.pat,
                        scmId: configuration.scmId,
                        oauthClientId: configuration.oauth?.clientId,
                        oauthPlaintextClientSecret: configuration.oauth?.clientSecret,
                    }),
                );
                addSCMIntegrationToCache(
                    client,
                    runnerId,
                    new SCMIntegration({
                        id: newId,
                        ...configuration,
                    }),
                );
                return new SCMIntegrationValidationResult({ valid: true });
            }

            await api.runnerConfigurationService.updateSCMIntegration(
                new UpdateSCMIntegrationRequest({
                    id: integrationId,
                    pat: configuration.pat,
                    oauthClientId: configuration.oauth?.clientId,
                    oauthPlaintextClientSecret: configuration.oauth?.clientSecret
                        ? configuration.oauth?.clientSecret
                        : undefined,
                }),
            );
            setSCMIntegrationInCache(
                client,
                runnerId,
                new SCMIntegration({
                    id: integrationId,
                    ...configuration,
                }),
            );
            return new SCMIntegrationValidationResult({ valid: true });
        },
    });
};

export const useValidateEnvironmentClass = () => {
    const api = useGitpodAPI();
    const { data: user } = useAuthenticatedUser();

    return useMutation({
        mutationFn: async (environmentClass: EnvironmentClass) => {
            if (!user) {
                throw new Error("User not authenticated");
            }

            const { result } = await api.runnerConfigurationService.validateRunnerConfiguration(
                new ValidateRunnerConfigurationRequest({
                    runnerId: environmentClass.runnerId,
                    config: {
                        case: "environmentClass",
                        value: environmentClass,
                    },
                }),
            );

            if (result.case === "environmentClass" && !result.value.valid) {
                return result.value;
            }

            return new EnvironmentClassValidationResult({ valid: true });
        },
    });
};

export const useCreateEnvironmentClass = (runnerId: string) => {
    const api = useGitpodAPI();
    const client = useQueryClient();
    const { data: user } = useAuthenticatedUser();

    return useMutation({
        mutationFn: async (values: { displayName: string; description: string; configuration: FieldValue[] }) => {
            if (!user) {
                throw new Error("User not authenticated");
            }

            const { id } = await api.runnerConfigurationService.createEnvironmentClass(
                new CreateEnvironmentClassRequest({
                    runnerId: runnerId,
                    ...values,
                }),
            );

            addEnvironmentClassToCache(
                client,
                new EnvironmentClass({
                    id,
                    runnerId,
                    enabled: true,
                    ...values,
                }),
            );
        },
    });
};

export const useUpdateEnvironmentClass = (environmentClass: EnvironmentClass) => {
    const api = useGitpodAPI();
    const client = useQueryClient();
    const { data: user } = useAuthenticatedUser();

    return useMutation({
        mutationFn: async (values: { displayName: string; description: string }) => {
            if (!user) {
                throw new Error("User not authenticated");
            }

            const updated = new EnvironmentClass({
                ...environmentClass,
                displayName: values.displayName,
                description: values.description,
            });

            const { result } = await api.runnerConfigurationService.validateRunnerConfiguration(
                new ValidateRunnerConfigurationRequest({
                    runnerId: environmentClass.runnerId,
                    config: {
                        case: "environmentClass",
                        value: updated,
                    },
                }),
            );

            if (result.case === "environmentClass" && !result.value.valid) {
                return result.value;
            }

            await api.runnerConfigurationService.updateEnvironmentClass(
                new UpdateEnvironmentClassRequest({
                    environmentClassId: environmentClass.id,
                    displayName: updated.displayName,
                    description: updated.description,
                }),
            );

            setEnvironmentClassInCache(client, updated);
            return new EnvironmentClassValidationResult({ valid: true });
        },
    });
};

export const useSetEnvironmentClassEnabled = (environmentClass: EnvironmentClass) => {
    const api = useGitpodAPI();
    const client = useQueryClient();
    const { data: user } = useAuthenticatedUser();

    return useMutation({
        mutationFn: async (options: { enabled: boolean }) => {
            if (!user) {
                throw new Error("User not authenticated");
            }

            const updated = new EnvironmentClass({
                ...environmentClass,
                enabled: options.enabled,
            });

            await api.runnerConfigurationService.updateEnvironmentClass(
                new UpdateEnvironmentClassRequest({
                    environmentClassId: environmentClass.id,
                    displayName: updated.displayName,
                    description: updated.description,
                    enabled: updated.enabled,
                }),
            );

            setEnvironmentClassInCache(client, updated);
        },
    });
};

export const useDeleteSCMIntegration = (runnerId: string) => {
    const api = useGitpodAPI();
    const client = useQueryClient();
    const { data: user } = useAuthenticatedUser();

    return useMutation({
        mutationFn: async (integrationId: string) => {
            if (!user) {
                throw new Error("User not authenticated");
            }
            await api.runnerConfigurationService.deleteSCMIntegration(
                new DeleteSCMIntegrationRequest({ id: integrationId }),
            );
            removeSCMIntegrationFromCache(client, runnerId, integrationId);
        },
    });
};

export const useListRunnerSCMIntegrations = (runnerId?: string) => {
    const api = useGitpodAPI();
    const { data: user } = useAuthenticatedUser();
    return useQuery({
        queryKey: runnerConfigurationQueryKey.listRunnerSCMIntegrations(runnerId),
        queryFn: async () => {
            if (!user) {
                throw new Error("User not authenticated");
            }
            if (!runnerId) {
                return [];
            }
            const { integrations } = await api.runnerConfigurationService.listSCMIntegrations(
                new ListSCMIntegrationsRequest({
                    filter: {
                        runnerIds: [runnerId],
                    },
                }),
            );

            return integrations;
        },
        enabled: !!user && !!runnerId,
        staleTime: 500,
    });
};

export const useListAllRunnerSCMIntegrations = (runnerIds: string[]) => {
    const api = useGitpodAPI();
    const queries = useQueries({
        queries: runnerIds.map((runnerId) => ({
            queryKey: ['runnerSCMIntegrations', runnerId],
            queryFn: async () => {
                const response = await api.runnerConfigurationService.listSCMIntegrations(
                    new ListSCMIntegrationsRequest({
                        filter: { runnerIds: [runnerId] },
                    })
                );
                return response.integrations;
            },
            enabled: !!runnerId,
            staleTime: 500,
        })),
    });

    const isLoading = queries.some((query) => query.isLoading || query.isFetching);
    const data = queries.reduce<SCMIntegration[]>((acc, query) => {
        if (query.data) {
            acc.push(...query.data);
        }
        return acc;
    }, []);

    return {
        data,
        isLoading,
    };
};

export const useListRunnerEnvironmentClasses = (runnerId?: string) => {
    const api = useGitpodAPI();
    const { data: user } = useAuthenticatedUser();
    return useQuery({
        queryKey: runnerConfigurationQueryKey.listRunnerEnvironmentClasses(runnerId),
        queryFn: async () => {
            if (!user) {
                throw new Error("User not authenticated");
            }
            if (!runnerId) {
                return [];
            }
            const { environmentClasses } = await api.runnerConfigurationService.listEnvironmentClasses(
                new ListEnvironmentClassesRequest({ filter: { runnerIds: [runnerId] } }),
            );

            return environmentClasses;
        },
        enabled: !!user && !!runnerId,
        staleTime: 500,
    });
};

export const useGetEnvironmentClass = (environmentClassId?: string) => {
    const api = useGitpodAPI();
    const { data: user } = useAuthenticatedUser();
    return useQuery({
        queryKey: runnerConfigurationQueryKey.getEnvironmentClass(environmentClassId),
        queryFn: async () => {
            if (!user) {
                throw new Error("User not authenticated");
            }
            if (!environmentClassId) {
                return;
            }

            const { environmentClass } = await api.runnerConfigurationService.getEnvironmentClass(
                new GetEnvironmentClassRequest({
                    environmentClassId: environmentClassId,
                }),
            );

            return environmentClass;
        },
        enabled: !!user && !!environmentClassId,
        staleTime: 500,
    });
};

function setSCMIntegrationInCache(client: QueryClient, runnerId: string, integration: SCMIntegration) {
    client.setQueryData(runnerConfigurationQueryKey.listRunnerSCMIntegrations(runnerId), (old: SCMIntegration[]) => {
        return old.map((i) => {
            if (i.id === integration.id) {
                return integration;
            }
            return i;
        });
    });
}

function removeSCMIntegrationFromCache(client: QueryClient, runnerId: string, integrationId: string) {
    client.setQueryData(runnerConfigurationQueryKey.listRunnerSCMIntegrations(runnerId), (old: SCMIntegration[]) =>
        old.filter((i) => i.id !== integrationId),
    );
}

function addSCMIntegrationToCache(client: QueryClient, runnerId: string, integration: SCMIntegration) {
    client.setQueryData(runnerConfigurationQueryKey.listRunnerSCMIntegrations(runnerId), (old: SCMIntegration[]) => {
        return [integration, ...old];
    });
}

function setEnvironmentClassInCache(client: QueryClient, environmentClass: EnvironmentClass) {
    client.setQueryData(
        runnerConfigurationQueryKey.listRunnerEnvironmentClasses(environmentClass.runnerId),
        (old: EnvironmentClass[]) => {
            return old.map((ec) => {
                if (ec.id === environmentClass.id) {
                    return environmentClass;
                }
                return ec;
            });
        },
    );
}

function addEnvironmentClassToCache(client: QueryClient, environmentClass: EnvironmentClass) {
    client.setQueryData(
        runnerConfigurationQueryKey.listRunnerEnvironmentClasses(environmentClass.runnerId),
        (old: EnvironmentClass[]) => {
            return [environmentClass, ...old];
        },
    );
}

function optimisticDeleteHostAuthenticationToken(client: QueryClient, tokenId: string) {
    client.setQueryData(runnerConfigurationQueryKey.list(), (old: { tokens: HostAuthenticationToken[] }) => {
        return {
            tokens: old.tokens.filter((t) => t.id !== tokenId),
        };
    });
}
