import { WithPopover } from "@/components/environments/WithPopover";
import { Button } from "@/components/flexkit/Button";
import { Input } from "@/components/podkit/forms/Input";
import { Label } from "@/components/podkit/forms/Label";
import { useToast } from "@/components/podkit/toasts/use-toast";
import { Heading2 } from "@/components/podkit/typography/Headings";
import { Text } from "@/components/podkit/typography/Text";
import { TrackLocations } from "@/hooks/use-segment";
import { useUpdateEnvironment } from "@/queries/environment-queries";
import { formatError } from "@/utils/errors";
import {
    AdmissionLevel,
    EnvironmentSpec,
    EnvironmentSpec_EnvironmentPort,
    UpdateEnvironmentRequest,
} from "gitpod-next-api/gitpod/v1/environment_pb";
import { useCallback, useId, useState, type FC, type FormEvent, type ReactElement } from "react";

type OpenPortFormProps = {
    environmentId: string;
    onClose?: () => void;
};
const OpenPortForm: FC<OpenPortFormProps> = ({ environmentId, onClose }) => {
    const updateEnvironment = useUpdateEnvironment();
    const { toast } = useToast();

    const [name, setName] = useState<string>();
    const [port, setPort] = useState<string>();
    const inputNameID = useId();
    const inputPortID = useId();

    const handleSubmit = useCallback(
        async (event: FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            if (!port || !environmentId) {
                return;
            }

            try {
                await updateEnvironment.mutateAsync({
                    req: new UpdateEnvironmentRequest({
                        environmentId,
                        spec: new EnvironmentSpec({
                            ports: [
                                new EnvironmentSpec_EnvironmentPort({
                                    name: name || "",
                                    port: Number(port),
                                    admission: AdmissionLevel.EVERYONE,
                                }),
                            ],
                        }),
                    }),
                });

                // close on success only
                onClose?.();
            } catch (error) {
                toast({
                    title: "Failed to open port.",
                    description: formatError(error),
                });
            }
        },
        [port, environmentId, updateEnvironment, name, onClose, toast],
    );

    return (
        <form onSubmit={handleSubmit} className="p-5">
            <div className="flex w-[500px] flex-col gap-7">
                <div>
                    <Heading2>Open a public port</Heading2>
                    <Text muted className="text-base">
                        Public ports can be shared with other people
                    </Text>
                </div>
                <div className="flex flex-col gap-2">
                    <div className="space-y-1">
                        <Label htmlFor={inputNameID}>Name</Label>
                        <Input
                            id={inputNameID}
                            type="text"
                            name="name"
                            value={name || ""}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                    <div className="space-y-1">
                        <Label htmlFor={inputPortID}>Port</Label>
                        <Input
                            id={inputPortID}
                            type="number"
                            name="port"
                            value={port || ""}
                            onChange={(e) => setPort(e.target.value)}
                        />
                    </div>
                </div>
                <div className="flex flex-row justify-end gap-2">
                    <Button type="button" variant="secondary" onClick={onClose} data-track-label="true">
                        Cancel
                    </Button>
                    <Button
                        loading={updateEnvironment.isPending}
                        autoFocus={true}
                        aria-label="Open Port"
                        type="submit"
                        disabled={!port}
                        variant="primary"
                        data-track-label="true"
                    >
                        Open Port
                    </Button>
                </div>
            </div>
        </form>
    );
};

export type OpenPortPopoverProps = {
    button: ReactElement;
    anchor?: React.RefObject<HTMLElement>;
    environmentId: string;
};
export const OpenPortPopover: FC<OpenPortPopoverProps> = ({ anchor, button, environmentId }) => {
    return (
        <WithPopover anchor={anchor} button={button} data-track-location={TrackLocations.EnvironmentOpenPortPoppover}>
            <OpenPortForm environmentId={environmentId} />
        </WithPopover>
    );
};
