import { cn } from "@/components/podkit/lib/cn";
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent } from "./DropDown";
import type React from "react";
import { type FC, type PropsWithChildren } from "react";
import { Button } from "@/components/flexkit/Button";
import { IconElipseHorizontal } from "@/assets/icons/geist/IconEllipseHorizontal";

export const DropdownActions: FC<
    {
        button?: React.ReactNode;
        triggerTestId?: string;
        open?: boolean;
        onOpenChange?: (open: boolean) => void;
    } & PropsWithChildren
> = ({ button, children, triggerTestId, open, onOpenChange }) => {
    return (
        <DropdownMenu open={open} onOpenChange={onOpenChange}>
            {/* Todo: finally move the styles out of index.css */}
            <DropdownMenuTrigger
                asChild
                className="flex rounded-md bg-transparent p-1 hover:bg-surface-tertiary"
                data-testid={triggerTestId}
            >
                {button ? (
                    button
                ) : (
                    <Button variant="ghost" className="border-0">
                        <IconElipseHorizontal
                            size="base"
                            className={cn("size-6 rounded-md p-0.5 text-content-primary")}
                        />
                    </Button>
                )}
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="focus-visible:ring-0">
                {children}
            </DropdownMenuContent>
        </DropdownMenu>
    );
};
